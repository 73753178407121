import { z } from 'zod';

export interface Props {
  positionBriefId?: number;
  positionBriefName: string;
}

export const BDPNameModificationSchema = z.object({
  BDPName: z.string().min(1),
});
