const pluralSigns = ['s', 'x'];

export const pluralFormat = (value: number, unit: string) =>
  [
    value,
    ...unit
      .split(' ')
      .map(u =>
        value > 1 && !pluralSigns.includes(u.charAt(u.length - 1).toLowerCase()) ? `${u}s` : u
      ),
  ].join(' ');
