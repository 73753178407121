import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { CompetitorsService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

import useFetchCompetitors from './useFetchCompetitors';

const useDeleteCompetitor = (
  positionBriefId: string,
  extraOptions?: UseMutationOptions<unknown, unknown, number>
) => {
  const queryClient = useQueryClient();
  const { ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.deleteCompetitor, positionBriefId],
    async (id: number) => {
      return CompetitorsService.competitorsControllerDeleteCompetitor({ id });
    },
    {
      onMutate: id => {
        queryClient.setQueryData(
          [QueryKeys.fetchCompetitors, positionBriefId],
          (oldData?: ReturnType<typeof useFetchCompetitors>['data']) => {
            return oldData?.filter(competitor => competitor.id !== id) ?? [];
          }
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.fetchCompetitors);
      },
      ...rest,
    }
  );
};

export default useDeleteCompetitor;
