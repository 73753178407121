import ReactGA from 'react-ga4';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { getAnalyticsUserInfo, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  PatchPositionBriefBody,
  PositionBrief,
  PositionBriefsService,
  PositionBriefsWithCount,
} from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdatePositionBrief = (
  extraOptions?: UseMutationOptions<PositionBrief, unknown, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.updatePositionBrief],
    async ({ id, modification }: { id: number; modification: PatchPositionBriefBody }) => {
      if (id === undefined) {
        return Promise.reject('This BDP does not have an id');
      }
      const updatedPositionBrief = await PositionBriefsService.positionBriefsControllerUpdatePositionBrief(
        {
          id,
          body: modification,
        }
      );

      return updatedPositionBrief;
    },
    {
      onSuccess: (...args) => {
        const [_data, variables, _context] = args;
        ReactGA.event('Modification de bdp', {
          ...userInfo,
          brandCode: currentBrand?.brandCodeApiHeader,
        });
        queryClient.setQueriesData(
          { queryKey: QueryKeys.fetchPositionBriefs, exact: false },
          (oldData: { pages: PositionBriefsWithCount[] } | undefined) => {
            return {
              pages:
                oldData?.pages.map(page => {
                  const brief = page.positionBriefs.find(brief => brief.id === variables.id);
                  if (brief) {
                    const briefIndex = page.positionBriefs.indexOf(brief);
                    const newPositionBriefs = [...page.positionBriefs];
                    newPositionBriefs[briefIndex] = {
                      ...newPositionBriefs[briefIndex],
                    };
                    return { count: page.count, positionBriefs: newPositionBriefs };
                  } else {
                    return page;
                  }
                }) ?? [],
            };
          }
        );
        queryClient.invalidateQueries(QueryKeys.fetchPositionBriefDetails);
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
