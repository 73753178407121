import {
  Button,
  Modal,
  WithLightTitle,
  FetchButton,
  AddButton,
  PopupActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  BDPHeaderVariables,
  PlusCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  TextInput,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useRef } from 'react';
import { useController } from 'react-hook-form';

import useCreateVariable from 'src/Hooks/Variables/useCreateVariable';
import { VariableType } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { variableTypeValues } from 'src/Utils/variableTypeValues';

import VariableTypeSelection from './TypeSelection';
import styles from './VariableModal.module.scss';
import { VariableSchema } from './VariableModal.schema';
import { Props } from './VariableModal.types';

const VariableModal = ({ positionBrief }: Props) => {
  const ref = useRef<PopupActions>(null);
  const createVariableMutation = useCreateVariable();
  const variablesFetchStatus = toFetchStatus(createVariableMutation);

  const { control, watch, handleSubmit, reset } = useFormWithZodResolver({
    schema: VariableSchema,
    defaultValues: {
      type: null,
      name: '',
      description: '',
    },
  });

  const name = watch('name');
  const description = watch('description');

  const variableTypeZodController = useController({
    name: 'type',
    control: control,
  });

  const variableModalHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        createVariableMutation.mutate({
          positionBrief: { id: positionBrief },
          variable: {
            name: values.name,
            type:
              VariableType[
                variableTypeValues.find(
                  bonusFrequency => bonusFrequency.id === variableTypeZodController.field.value
                )?.key as keyof typeof VariableType
              ],
            description: values.description,
          },
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSubmit, positionBrief, createVariableMutation]
  );

  const onClose = () => {
    reset();
  };
  return (
    <Modal
      ref={ref}
      trigger={
        <AddButton
          className={styles.addButton}
          icon={<PlusCircle className={styles.buttonIcon} />}
          text="ajouter une variable"
        />
      }
      onClose={onClose}
      title="nouvelle variable"
      icon={<BDPHeaderVariables />}
      iconClassName={styles.icon}
      nested
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            reset();
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={() => ref.current?.close()}>annuler</Button.Secondary>,
        <FetchButton
          disabled={name.length === 0 || variableTypeZodController.field.value === null}
          onClick={variableModalHandleSubmit()}
          fetchStatus={variablesFetchStatus}
          title="valider"
          onSuccess={() => ref.current?.close()}
        />,
      ]}
    >
      <div>
        <VariableTypeSelection name="type" control={control} />
      </div>
      <div className={styles.variableName}>
        <WithLightTitle title="nom de la variable">
          <>
            <TextInput name="name" control={control} />
          </>
        </WithLightTitle>
      </div>
      <div className={styles.descriptionContainer}>
        <TextAreaWithLightTitle
          title="décrivez quelques détails concernant cette variable"
          name="description"
          counterClassName={styles.counter}
          textAreaClassName={styles.textArea}
          placeholder="en quelques mots..."
          maxLength={255}
          valueLength={description.length}
          control={control}
        />
      </div>
    </Modal>
  );
};

export default VariableModal;
