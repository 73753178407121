import * as z from 'zod';

export const SigningModalSchema = z.object({
  randstadSigningPerson: z
    .object({
      personName: z.string().optional(),
      id: z.string().optional(),
      moment: z.date().optional(),
      signed: z.boolean().optional(),
    })
    .optional(),
  clientSigningPerson: z
    .object({
      personName: z.string().optional(),
      id: z.string().optional(),
      moment: z.date().optional(),
      signed: z.boolean().optional(),
    })
    .optional(),
  randstadConsultantSignature: z.string().optional(),
  clientSignature: z.string().optional(),
});

export type SigningModalSchemaType = z.infer<typeof SigningModalSchema>;
