import { UseMutationOptions, useMutation } from 'react-query';

import { QualificationsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchQualifications = (
  extraOptions?: UseMutationOptions<{ id: string; label: string }[], unknown, string>
) => {
  const societyCode = '001';
  return useMutation(
    [MutationKeys.fetchQualifications],
    async (keyword: string) => {
      if (keyword === '') {
        return [];
      }
      const qualifications = await QualificationsService.qualificationsControllerGetQualifications({
        keyword,
        societyCode,
      });
      return qualifications.map(qualification => {
        return { id: qualification.id, label: qualification.label };
      });
    },
    { ...extraOptions }
  );
};
