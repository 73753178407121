import moment from 'moment';
import React from 'react';

import styles from './ListCard.module.scss';
import { Props } from './ListCard.types';

export const ListCard = ({
  qualification,
  name,
  updatedAt,
  onClick,
  rightActions,
  service,
  serviceAddress,
}: Props) => (
  <div className={styles.container} onClick={onClick}>
    <div className={styles.firstPart}>
      <p className={styles.date}>{`${moment(updatedAt).format('LL')} à ${moment(updatedAt).format(
        'LT'
      )}`}</p>
      <p className={styles.service}>{service}</p>
      <p className={styles.serviceAddress}>{serviceAddress}</p>
    </div>

    <div className={styles.secondPart}>
      <p className={styles.name}>nom du BDP : {name}</p>
      <p className={styles.qualification}>qualification : {qualification}</p>
    </div>
    <div className={styles.thirdPart}>{rightActions}</div>
  </div>
);

export default ListCard;
