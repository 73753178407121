import { UseQueryOptions, useQuery } from 'react-query';

import { DocumentsService, Document } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyDocuments = (
  companyId: string,
  extraOptions?: UseQueryOptions<Document[], unknown, Document[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCompanyDocuments, companyId],
    async () => {
      const documents = await DocumentsService.documentsControllerGetDocumentsByCompanyId({
        companyId,
      });
      return documents;
    },
    {
      ...extraOptions,
    }
  );
};
