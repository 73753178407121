import { PopupMenu, ThreeDotsButton } from '@randstad-lean-mobile-factory/react-components-core';
import { Pencil, Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import useDeleteBonus from 'src/Hooks/Bonuses/useDeleteBonus';

import BonusModal from '../BonusModal/BonusModal.component';

import styles from './BonusActions.module.scss';
import { Props } from './BonusActions.types';

const BonusActions = ({ bonus }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const deleteBonusMutation = useDeleteBonus(positionBriefId);

  return (
    <div>
      <PopupMenu
        position="bottom right"
        width={260}
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          setIsOpen(false);
        }}
        offsetX={-16}
        trigger={<ThreeDotsButton triggered={isOpen} className={styles.trigger} />}
      >
        <PopupMenu.Item
          icon={<Trashcan />}
          text="supprimer"
          onClick={() => {
            deleteBonusMutation.mutate(bonus.id);
          }}
        />
        <PopupMenu.Item
          icon={<Pencil />}
          text="modifier"
          onClick={() => setIsBonusModalOpen(true)}
        />
      </PopupMenu>
      <BonusModal bonus={bonus} isOpen={isBonusModalOpen} setIsOpen={setIsBonusModalOpen} />
    </div>
  );
};

export default BonusActions;
