import { useQuery, UseQueryOptions } from 'react-query';

import { Competitor, CompetitorsService } from 'src/Services/API';

import { QueryKeys } from '../types';

const useFetchCompetitors = (
  positionBriefId: number,
  extraOptions?: UseQueryOptions<unknown, unknown, Competitor[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCompetitors, positionBriefId.toString()],
    () => {
      return CompetitorsService.competitorsControllerFetchCompetitors({ positionBriefId });
    },
    {
      ...extraOptions,
    }
  );
};

export default useFetchCompetitors;
