import { z } from 'zod';

import { CompetitorStatus } from 'src/Services/API';

export const CompetitorSchema = z
  .object({
    inputValue: z.string(),
    usedValues: z.array(z.string()),
    competitorList: z.array(
      z.object({
        name: z.string(),
        status: z.nativeEnum(CompetitorStatus),
      })
    ),
  })
  .refine(
    ({ inputValue, usedValues, competitorList }) => {
      const competitorNames = competitorList.map(competitor => competitor.name);
      return !usedValues.includes(inputValue) && !competitorNames.includes(inputValue);
    },
    {
      message: 'Vous avez déjà ajouté ce concurrent',
      path: ['inputValue'],
    }
  );
