import { SelectionIconButton } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './SelectionIconButtonsForm.module.scss';
import { Props } from './SelectionIconButtonsForm.types';

const SelectionIconButtonsForm = ({
  title,
  isLoading,
  loadingItems,
  isError,
  isSuccess,
  error,
  selectIconButtonClassName,
  selectIconButtonLoaderClassName,
  data,
  displaySelected = true,
  female,
  isBriefSigned,
  setIsEditAfterSigningModalOpen,
}: Props) => {
  const selected =
    displaySelected && isSuccess && data
      ? pluralFormat(
          data.filter(({ selected }) => selected).length,
          female ? 'sélectionnée' : 'sélectionné'
        )
      : undefined;

  return (
    <>
      <div className={styles.gridTitleContainer}>
        <h3 className={styles.subtitle}>{title}</h3>
        <p className={styles.selectedNumber}>{selected}</p>
      </div>
      <div className={styles.gridContainer}>
        {isLoading &&
          new Array(loadingItems).fill(undefined).map((_, index) => {
            return (
              <SelectionIconButton.Loader key={index} className={selectIconButtonLoaderClassName} />
            );
          })}

        {isError && <ErrorMessage message={error} />}

        {isSuccess && (
          <>
            {data?.map(iconButtonProps => {
              return (
                <div
                  key="iconButtonProp"
                  onMouseDown={() => {
                    if (isBriefSigned) {
                      setIsEditAfterSigningModalOpen(true);
                    }
                  }}
                >
                  <SelectionIconButton {...iconButtonProps} className={selectIconButtonClassName} />
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default SelectionIconButtonsForm;
