import { EnumStudyLevelLevel, StudyLevel } from 'src/Services/API';

export const STUDY_LEVEL_MAPPING_TO_STRING = {
  CAP_BEP: 'cap/bep',
  BAC: 'bac',
  BAC_PLUS_2: 'bac+2',
  BAC_PLUS_3: 'bac+3',
  BAC_PLUS_5: 'bac+5',
  ENGINEER: 'ingénieur',
  PHD: 'docteur',
};

export const findStudyLevelFromLevel = (
  searchedLevel: EnumStudyLevelLevel,
  studyLevelsList: StudyLevel[]
) => {
  return studyLevelsList.find(studyLevel => studyLevel.level === searchedLevel);
};
