import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import UserPreferencesState, { DISPLAY_MODE } from './Types';

export const initialState: UserPreferencesState = {
  cardDisplayMode: DISPLAY_MODE.GRID,
};

const { reducer, actions } = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    setCardDisplayMode: (state, action: PayloadAction<DISPLAY_MODE>) => {
      state.cardDisplayMode = action.payload;
    },
  },
});

export const userPreferencesReducer = reducer;
export const userPreferencesActions = actions;
