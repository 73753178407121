import { Button, Modal, PopupActions } from '@randstad-lean-mobile-factory/react-components-core';
import { BDPDeleteWarning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import styles from './OrganizationChartWarningModal.module.scss';
import { Props } from './OrganizationChartWarningModal.types';

const OrganizationChartWarningModal = ({ open, onClose, onDelete }: Props) => {
  const innerRef = React.useRef<PopupActions>(null);
  return (
    <Modal
      open={open}
      title="supprimer"
      icon={<BDPDeleteWarning />}
      ref={innerRef}
      onClose={onClose}
      footerActionsRight={[
        <Button.Secondary
          text="annuler"
          onClick={() => {
            onClose();
          }}
        />,
        <Button.Primary
          className={styles.fetchButton}
          text="supprimer l'organigramme"
          onClick={() => {
            onDelete();
            onClose();
          }}
        />,
      ]}
    >
      <div className={styles.text}>
        l'organigramme sera définitivement supprimé, confirmez-vous l'action ?
      </div>
    </Modal>
  );
};

export default OrganizationChartWarningModal;
