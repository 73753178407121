import { z } from 'zod';

export const ContextSchema = z
  .object({
    contractType: z.number().optional(),
    contractStatus: z.number().optional(),
    trialPeriodDuration: z.string().optional(),
    cddDuration: z.string().nullable().optional(),
    tenurable: z.string(),
    positionMotiveId: z.number().optional(),
    recruitmentReason: z.string().max(4000),
    recruitmentOpenPositionDuration: z.string().optional(),
    difficulties: z.string(),
    openPositionDurationUnit: z.string().nullable(),
  })
  .refine(
    ({ contractType, trialPeriodDuration }) => {
      if (
        contractType === 4 &&
        (parseInt(trialPeriodDuration ?? '') > 1 || parseInt(trialPeriodDuration ?? '') < 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
    {
      message: `Doit être entre 0 et 1`,
      path: ['trialPeriodDuration'],
    }
  )
  .refine(
    ({ contractType, trialPeriodDuration }) => {
      if (
        contractType === 5 &&
        (parseInt(trialPeriodDuration ?? '') > 8 || parseInt(trialPeriodDuration ?? '') < 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
    {
      message: `Doit être entre 0 et 8`,
      path: ['trialPeriodDuration'],
    }
  )
  .refine(
    ({ contractType, cddDuration }) => {
      if (
        contractType === 4 &&
        cddDuration !== undefined &&
        cddDuration !== null &&
        cddDuration.length > 0 &&
        (isNaN(parseInt(cddDuration ?? '')) ||
          parseInt(cddDuration ?? '') > 72 ||
          parseInt(cddDuration ?? '') < 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
    {
      message: `Doit être entre 0 et 72 mois`,
      path: ['cddDuration'],
    }
  );
