import {
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Medal, Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import { ExperienceSelection } from 'src/Containers/Profile/Curriculum/ExperienceSelection/index';
import { FormationSelection } from 'src/Containers/Profile/Curriculum/FormationSelection';
import { useFetchExperiences } from 'src/Hooks/Experiences/useFetchExperiences';
import { useRemoveExperience } from 'src/Hooks/Experiences/useRemoveExperience';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { useFetchLanguages } from 'src/Hooks/Repositories/useFetchLanguages';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { sanitizeString } from 'src/Utils/sanitizeString';

import styles from './Curriculum.module.scss';
import { CurriculumSchema } from './Curriculum.schema';
import { FormationContext } from './FormationSelection/FormationContext';
import LanguagePicker from './LanguagePicker';

const Curriculum = () => {
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const id = parseInt(positionBriefId);
  const positionBriefQuery = useFetchPositionBriefDetails(id);
  const positionBriefFetchStatus = toFetchStatus(positionBriefQuery);
  const updateMutation = useUpdatePositionBrief();
  const fetchExperiences = useFetchExperiences(id);
  const removeExperienceMutation = useRemoveExperience();
  const languagesQuery = useFetchLanguages();

  const {
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { isDirty, isSubmitted },
  } = useFormWithZodResolver({
    schema: CurriculumSchema,
    defaultValues: {
      studyLevels: positionBriefQuery.data?.profileDetails?.studyLevels ?? [],
      diplomas: positionBriefQuery.data?.profileDetails?.diplomas ?? '',
      medicalSpecializationsIds:
        positionBriefQuery.data?.profileDetails?.medicalSpecializationsIds ?? [],
      languageIds:
        positionBriefQuery.data?.profileDetails?.languages?.map(language =>
          language.id.toString()
        ) ?? [],
      languagesNotes: positionBriefQuery.data?.profileDetails?.languagesNotes ?? '',
    },
  });

  const curriculumHandleSubmit = () =>
    handleSubmit(values => {
      updateMutation.mutate({
        id,
        modification: {
          changes: {
            ...values,
            profileDetails: {
              studyLevels: values.studyLevels,
              diplomas: sanitizeString(values.diplomas),
              medicalSpecializationsIds: values.medicalSpecializationsIds,
              languages: languagesQuery.data?.filter(language =>
                values.languageIds.includes(language.id.toString())
              ),
              languagesNotes: sanitizeString(values.languagesNotes),
            },
          },
        },
      });
    });
  const languageController = useController({ control, name: 'languageIds' });
  const languagesNotes = watch('languagesNotes');

  const removeExperience = useCallback((id: number) => removeExperienceMutation.mutate(id), [
    removeExperienceMutation,
  ]);

  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  const isBriefSigned = useMemo(() => {
    return positionBriefQuery.data?.signatures !== null;
  }, [positionBriefQuery.data]);

  const diplomas = watch('diplomas');
  useEffect(() => {
    if (isDirty || isSubmitted) {
      curriculumHandleSubmit()();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageController.field.value]);

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue('diplomas', positionBriefQuery.data?.profileDetails?.diplomas ?? '');
      setValue('languagesNotes', positionBriefQuery.data?.profileDetails?.languagesNotes ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diplomas, languagesNotes]);
  return (
    <div className={styles.container}>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <FormationContext.Provider
            value={{
              control: control,
              watch: watch,
              setValue: setValue,
              curriculumHandleSubmit: curriculumHandleSubmit,
            }}
          >
            <FormationSelection
              diplomas={diplomas}
              control={control}
              curriculumHandleSubmit={curriculumHandleSubmit}
              setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
              isBriefSigned={isBriefSigned}
              setValue={setValue}
            />
          </FormationContext.Provider>

          <div className={styles.separator} />
          <div className={styles.title}>expériences</div>
          <WithLightTitle title="liste de vos expériences">
            {fetchExperiences.data?.length !== 0 && (
              <>
                <div className={styles.innerTopSeparator} />

                {fetchExperiences.data?.map(experience => {
                  return (
                    <>
                      <HorizontalTextIconCard
                        key={experience.id}
                        label={experience.name}
                        subLabel={
                          experience.value === 5
                            ? '5 à 10 ans'
                            : experience.value === 10
                            ? '10 à 15 ans'
                            : experience.value === 13
                            ? 'plus de 15 ans'
                            : pluralFormat(experience.value, 'an')
                        }
                        leftIcon={<Medal />}
                        rightIcon={
                          <Trashcan
                            onClick={() => {
                              if (!isBriefSigned) {
                                removeExperience(experience.id);
                              } else {
                                setIsEditAfterSigningModalOpen(true);
                              }
                            }}
                          />
                        }
                      />
                      <div className={styles.innerBottomSeparator} />
                    </>
                  );
                })}
              </>
            )}
          </WithLightTitle>
          <div
            onMouseDown={() => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              }
            }}
          >
            <ExperienceSelection positionBriefId={id} />
          </div>
          <div className={styles.title}>langues</div>
          <div className={styles.LanguagePickerContainer}>
            <LanguagePicker
              globalFetchStatus={positionBriefFetchStatus}
              selectedLanguages={
                languagesQuery.data
                  ?.filter(language =>
                    languageController.field.value.includes(language.id.toString() ?? '')
                  )
                  .map(languageItem => {
                    return {
                      id: languageItem.id.toString() ?? '',
                      label: languageItem.label ?? '',
                    };
                  }) ?? []
              }
              languagesQuery={languagesQuery}
              languagesController={languageController}
              isBriefSigned={isBriefSigned}
              setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
            />
          </div>
          <TextAreaWithLightTitle
            title="vos notes sur les langues"
            name="languagesNotes"
            counterClassName={styles.charCountLabel}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={languagesNotes.length}
            control={control}
            onBlurCapture={curriculumHandleSubmit()}
          />
          <EditAfterSigningModal
            isOpen={isEditAfterSigningModalOpen}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="20rem" width="100%" uniqueKey="curriculumLoader">
          <rect x="0%" y="5" rx="4" ry="4" width="30%" height="15" />
          <rect x="0%" y="35" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="55" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="30%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="30%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="135" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="155" rx="4" ry="4" width="20%" height="15" />
        </ContentLoader>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération du cursus du profil" />
      )}
    </div>
  );
};

export default Curriculum;
