import { useMutation, useQueryClient } from 'react-query';

import { CreateRecruitmentStepBody, RecruitmentStepsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useCreateRecruitmentStep = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.createRecruitmentStep],
    async (recruitmentStep: CreateRecruitmentStepBody) => {
      const createdRecruitmentSteps = await RecruitmentStepsService.recruitmentStepsControllerCreateRecruitmentStep(
        {
          body: recruitmentStep,
        }
      );

      return createdRecruitmentSteps;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchRecruitmentSteps');
      },
    }
  );
};
