import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { PositionBriefsService, PositionBriefsWithCount } from 'src/Services/API';

import { QueryKeys } from '../types';

type PositionBriefFilter = Partial<
  Omit<
    // We use required because as any is autogenerated in arg typing, resulting in weak typing
    Required<
      Parameters<typeof PositionBriefsService.positionBriefsControllerFetchPositionBriefs>
    >[0],
    'agencyId' | 'brandCode'
  >
>;

const DEFAULT_EDP_PER_PAGE = 20;

export const useFetchPositionBriefs = (
  filters: PositionBriefFilter,
  extraOptions?: UseInfiniteQueryOptions<
    PositionBriefsWithCount,
    unknown,
    PositionBriefsWithCount,
    PositionBriefsWithCount,
    (string | PositionBriefFilter | undefined)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgencyId = useSelector(getCurrentAgency);
  return useInfiniteQuery(
    [QueryKeys.fetchPositionBriefs, filters, currentAgencyId, currentBrand?.brandCodeApiHeader],
    async ({ pageParam = 0 }) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      if (!currentAgencyId) {
        return Promise.reject('No current agency');
      }
      const positionBriefs = PositionBriefsService.positionBriefsControllerFetchPositionBriefs({
        offset: pageParam * DEFAULT_EDP_PER_PAGE,
        limit: DEFAULT_EDP_PER_PAGE,
        agencyId: currentAgencyId ?? '',
        brandCode: currentBrand.brandCodeApiHeader,
        ...filters,
      });
      return positionBriefs;
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.count < DEFAULT_EDP_PER_PAGE ? undefined : allPages.length,
      enabled: currentBrand !== undefined,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
