import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { AgenciesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchAgencyConsultants = () => {
  const brandCode = useSelector(getCurrentBrand)?.brandCodeApiHeader ?? '';
  const agencyId = useSelector(getCurrentAgency) ?? '';
  return useQuery(
    [QueryKeys.fetchAgencyConsultants, agencyId, brandCode],
    () => {
      if (!agencyId) {
        throw new Error('no agency id');
      }
      return AgenciesService.agenciesControllerGetAgencyConsultants({ agencyId, brandCode });
    },
    {
      enabled: !!agencyId,
      refetchOnWindowFocus: false,
    }
  );
};
