import { Animation } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React from 'react';
import { Redirect, Route, Router, Switch, useHistory, useParams } from 'react-router-dom';

import { useFetchQualificationById } from 'src/Hooks/Qualifications/useFetchQualificationById';

import { navigation } from '../PositionBriefModification.types';

import styles from './Sidebar.module.scss';
import { Props } from './Sidebar.types';

const Sidebar = ({ qualificationId, name }: Props) => {
  const history = useHistory();
  const params = useParams<{
    secondSection: string;
  }>();
  const { data: qualification } = useFetchQualificationById([qualificationId ?? '']);
  return (
    <div className={styles.container}>
      <Animation.Unroll visible={qualification !== undefined}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>vous êtes sur le brief de poste :</p>
          <p className={styles.name}>{name?.toLowerCase() ?? ''}</p>
        </div>
      </Animation.Unroll>
      <div className={styles.linkContainer}>
        <Router history={history}>
          <Switch>
            {Object.values(navigation).map(node => (
              <Route key={node.name} path={`${node.path}(.*)`}>
                <Route exact path={node.path}>
                  <Redirect to={node.default} />
                </Route>

                {node.routes.map((route, idx) => {
                  const isCurrentRoute = route.linkTo.includes(params.secondSection);
                  return (
                    <div
                      key={route.name}
                      className={classnames(styles.link, {
                        [styles.active]: isCurrentRoute,
                      })}
                      onClick={() => {
                        history.push(route.linkTo);
                      }}
                    >
                      <div className={styles.navNumber}>{`${idx + 1}.`}</div>
                      {`${route.name}`}
                    </div>
                  );
                })}
              </Route>
            ))}
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default Sidebar;
