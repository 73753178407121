import { Bonus } from 'src/Services/API';

export interface Props {
  positionBrief?: number;
  bonus?: Bonus;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface BonusFrequencyTypes {
  key: string;
  label: string;
}

export interface CreateMutationParams {
  positionBrief: number;
  values: {
    reference?: string | null | undefined;
    name: string;
    hasAmount: boolean;
    amount: string | null;
    frequency: string | null;
    description: string;
    bonusType: string | null;
  };
}

export interface UpdateMutationParams extends Omit<CreateMutationParams, 'positionBrief'> {
  bonusId: number;
}
export enum BONUS_TYPES {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}
