import { z } from 'zod';

import { EnumReportingFrequencyUnit } from 'src/Services/API';

export const ReportingsValidationSchema = z.object({
  name: z.string().nonempty(),
  numberPerPeriod: z.number(),
  frequencyUnit: z.nativeEnum(EnumReportingFrequencyUnit),
  modalitiesDescription: z.string(),
});

export const SkillsSchema = z.object({
  reportings: z.array(ReportingsValidationSchema),
  technicalSkills: z.string(),
  behavioralSkills: z.string(),
});
