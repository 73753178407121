import { z } from 'zod';

import {
  EnumPositionBriefPositionSalaryAttendanceFrequency,
  EnumPositionBriefPositionSalaryIncentiveFrequency,
  EnumSalaryBasis,
  EnumSalaryFormat,
  EnumTimePackage,
  EnumWorkingHourDays,
} from 'src/Services/API';

const enum EnumPositionInformationLimitedVariables {
  AMOUNT = 'AMOUNT',
  MONTH_COUNT = 'MONTH_COUNT',
  TIME_DAYS_PER_YEAR = 'TIME_DAYS_PER_YEAR',
  TIME_HOURS_PER_WEEK = 'TIME_HOURS_PER_WEEK',
  TIME_MINUTES = 'TIME_MINUTES',
  TIME_RTT_COUNT = 'TIME_RTT_COUNT',
  REMOTE_DAYS_COUNT = 'REMOTE_DAYS_COUNT',
  MOVING_DAYS_COUNT_WEEK = 'MOVING_DAYS_COUNT_WEEK',
  MOVING_DAYS_COUNT_MONTH = 'MOVING_DAYS_COUNT_MONTH',
  MOVING_DAYS_COUNT_YEAR = 'MOVING_DAYS_COUNT_YEAR',
}

const limitedVariableToLimits = {
  AMOUNT: { min: 0, max: 999999 },
  MONTH_COUNT: { min: 0, max: 14 },
  TIME_DAYS_PER_YEAR: { min: 0, max: 365 },
  TIME_HOURS_PER_WEEK: { min: 0, max: 100 },
  TIME_MINUTES: { min: 0, max: 59 },
  TIME_RTT_COUNT: { min: 0, max: 50 },
  REMOTE_DAYS_COUNT: { min: 0, max: 7 },
  MOVING_DAYS_COUNT_WEEK: { min: 0, max: 7 },
  MOVING_DAYS_COUNT_MONTH: { min: 0, max: 30 },
  MOVING_DAYS_COUNT_YEAR: { min: 0, max: 365 },
};

const refineStringToInt = (variable: EnumPositionInformationLimitedVariables) => (str: string) => {
  return (
    str.length === 0 ||
    (!isNaN(parseFloat(str ?? '')) &&
      parseFloat(str ?? '') >= limitedVariableToLimits[variable].min &&
      parseFloat(str ?? '') <= limitedVariableToLimits[variable].max)
  );
};

const getErrorMessage = (variable: EnumPositionInformationLimitedVariables) => {
  return `doit être entre ${limitedVariableToLimits[variable].min} et ${limitedVariableToLimits[variable].max}`;
};

export const positionInformationSchema = z
  .object({
    // Section Information
    services: z.string(),
    confidentialityClause: z.string(),
    mobilityClause: z.string(),
    managerId: z.string().optional(),
    // Section Mobilité
    remoteAccepted: z.string(),
    remoteDays: z
      .string()
      .refine(
        refineStringToInt(EnumPositionInformationLimitedVariables.REMOTE_DAYS_COUNT),
        getErrorMessage(EnumPositionInformationLimitedVariables.REMOTE_DAYS_COUNT)
      )
      .nullable()
      .optional(),
    remoteFrequencyValue: z.string().nullable(),
    remoteFrequencyUnit: z.string().nullish(),
    hasBusinessTrips: z.string(),
    address: z
      .object({
        address: z.string().optional(),
        cityName: z.string().optional(),
        zipCode: z.string().optional(),
        inseeCode: z.string().optional(),
      })
      .nullable(),
    mobilityAreas: z.array(z.string()).nullable(),
    nationalMobility: z.string(),
    timePackage: z.nativeEnum(EnumTimePackage).optional(),
    timeDaysPerYear: z
      .string()
      .refine(
        refineStringToInt(EnumPositionInformationLimitedVariables.TIME_DAYS_PER_YEAR),
        getErrorMessage(EnumPositionInformationLimitedVariables.TIME_DAYS_PER_YEAR)
      )
      .nullable()
      .optional(),
    timeHoursPerWeek: z
      .object({
        hours: z
          .number()
          .min(
            limitedVariableToLimits[EnumPositionInformationLimitedVariables.TIME_HOURS_PER_WEEK].min
          )
          .max(
            limitedVariableToLimits[EnumPositionInformationLimitedVariables.TIME_HOURS_PER_WEEK].max
          )
          .nullable(),
        minutes: z
          .number()
          .min(limitedVariableToLimits[EnumPositionInformationLimitedVariables.TIME_MINUTES].min)
          .max(limitedVariableToLimits[EnumPositionInformationLimitedVariables.TIME_MINUTES].max)
          .nullable(),
      })
      .nullable()
      .optional(),
    timeWorkingHours: z.array(
      z.object({
        startTime: z.string().nullable(),
        endTime: z.string().nullable(),
        days: z.array(z.nativeEnum(EnumWorkingHourDays)),
      })
    ),
    timeRttCount: z
      .string()
      .refine(
        refineStringToInt(EnumPositionInformationLimitedVariables.TIME_RTT_COUNT),
        getErrorMessage(EnumPositionInformationLimitedVariables.TIME_RTT_COUNT)
      )
      .nullable()
      .optional(),
    timeOnCallDuty: z.string(),
    timeOnCallDutyDetails: z.string(),
    salaryBasis: z.nativeEnum(EnumSalaryBasis).nullable().optional(),
    salaryFormat: z.nativeEnum(EnumSalaryFormat).nullable().optional(),
    minSalaryBasis: z
      .string()
      .refine(
        refineStringToInt(EnumPositionInformationLimitedVariables.AMOUNT),
        getErrorMessage(EnumPositionInformationLimitedVariables.AMOUNT)
      )
      .nullable()
      .optional(),
    maxSalaryBasis: z
      .string()
      .refine(
        refineStringToInt(EnumPositionInformationLimitedVariables.AMOUNT),
        getErrorMessage(EnumPositionInformationLimitedVariables.AMOUNT)
      )
      .nullable()
      .optional(),
    salaryMonthCount: z
      .string()
      .refine(
        refineStringToInt(EnumPositionInformationLimitedVariables.MONTH_COUNT),
        getErrorMessage(EnumPositionInformationLimitedVariables.MONTH_COUNT)
      )
      .nullable()
      .optional(),
    restaurantTickets: z.string(),
    positionAssets: z.string(),
    advantages: z.array(
      z.object({
        key: z.string(),
        value: z.boolean(),
      })
    ),
    travelFee1: z.boolean().optional(),
    travelFee2: z.boolean().optional(),
    healthCare: z.string(),
    incentiveBonus: z.string(),
    incentiveFrequency: z.nativeEnum(EnumPositionBriefPositionSalaryIncentiveFrequency).optional(),
    attendanceBonus: z.string(),
    attendanceFrequency: z
      .nativeEnum(EnumPositionBriefPositionSalaryAttendanceFrequency)
      .optional(),
  })
  .refine(
    ({ remoteFrequencyUnit, remoteFrequencyValue }) => {
      return !(
        remoteFrequencyUnit === 'WEEK' &&
        (remoteFrequencyValue === null ||
          parseInt(remoteFrequencyValue) > 7 ||
          parseInt(remoteFrequencyValue) < 0)
      );
    },
    {
      message: `${getErrorMessage(EnumPositionInformationLimitedVariables.MOVING_DAYS_COUNT_WEEK)}`,
      path: ['remoteFrequencyValue'],
    }
  )
  .refine(
    ({ remoteFrequencyUnit, remoteFrequencyValue }) => {
      return !(
        remoteFrequencyUnit === 'MONTH' &&
        (remoteFrequencyValue === null ||
          parseInt(remoteFrequencyValue) > 30 ||
          parseInt(remoteFrequencyValue) < 0)
      );
    },
    {
      message: `${getErrorMessage(
        EnumPositionInformationLimitedVariables.MOVING_DAYS_COUNT_MONTH
      )}`,
      path: ['remoteFrequencyValue'],
    }
  )
  .refine(
    ({ remoteFrequencyUnit, remoteFrequencyValue }) => {
      return !(
        remoteFrequencyUnit === 'YEAR' &&
        (remoteFrequencyValue === null ||
          parseInt(remoteFrequencyValue) > 365 ||
          parseInt(remoteFrequencyValue) < 0)
      );
    },
    {
      message: `${getErrorMessage(EnumPositionInformationLimitedVariables.MOVING_DAYS_COUNT_YEAR)}`,
      path: ['remoteFrequencyValue'],
    }
  )
  .refine(
    ({ incentiveBonus }) => {
      return !isNaN(+incentiveBonus);
    },
    {
      message: 'Doit être numérique',
      path: ['incentiveBonus'],
    }
  )
  .refine(
    ({ attendanceBonus }) => {
      return !isNaN(+attendanceBonus);
    },
    {
      message: 'Doit être numérique',
      path: ['attendanceBonus'],
    }
  )
  .refine(
    ({ healthCare }) => {
      return !isNaN(+healthCare);
    },
    {
      message: 'Doit être numérique',
      path: ['healthCare'],
    }
  );
