import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { PositionBriefsService, PositionBriefsWithCount } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useDeletePositionBrief = (
  id?: number,
  extraOptions?: UseMutationOptions<void, unknown, void>
) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.deletePositionBrief],
    async () => {
      if (id === undefined) {
        return Promise.reject('This BDP does not have an id');
      }
      const createdPositionStudy = await PositionBriefsService.positionBriefsControllerDeletePositionBrief(
        { id }
      );

      return createdPositionStudy;
    },
    {
      onSuccess: (...args) => {
        queryClient.setQueriesData(
          { queryKey: QueryKeys.fetchPositionBriefs, exact: false },
          (oldData: { pages: PositionBriefsWithCount[] } | undefined) => {
            return {
              pages:
                oldData?.pages.map(page => {
                  return {
                    count: page.count - 1,
                    positionBriefs: page.positionBriefs.filter(
                      positionBrief => positionBrief.id !== id
                    ),
                  };
                }) ?? [],
            };
          }
        );
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
