import { UseQueryOptions, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import {
  CompanySearchResult,
  CompaniesService,
  OSMConsultantModelDtoConsultantAgencyByCompany,
} from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanies = (
  keyword: string,
  extraOptions?: UseQueryOptions<
    CompanySearchResult[],
    unknown,
    CompanySearchResult[],
    (string | OSMConsultantModelDtoConsultantAgencyByCompany | undefined)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  return useQuery(
    [QueryKeys.fetchCompanies, keyword, currentAgency, currentBrand],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentAgency) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return CompaniesService.companiesControllerGetCompanies({
        body: {
          keyword,
          brandCode: currentBrand.brandCodeApiHeader,
          agencies: [currentAgency],
        },
      });
    },
    { enabled: keyword !== '', ...extraOptions }
  );
};
