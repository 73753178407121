import { Animation, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  TextArea,
  TripleChoiceButton,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { useFetchRisks, usePreFetchRisks } from 'src/Hooks/Repositories/useFetchRisks';
import { YesNoUnknownEnum } from 'src/Utils/yesNoUnknownEnum';
import { databaseToZodData, zodToDatabaseData } from 'src/Utils/ZodDatabaseTransformer';

import styles from './Risks.module.scss';
import { risksSchema } from './Risks.schema';
import { Props } from './Risks.types';
import RiskSelection from './RiskSelection';

const Risks = ({
  positionBriefId,
  positionBrief,
  isBriefSigned,
  setIsEditAfterSigningModalOpen,
}: Props) => {
  usePreFetchRisks();

  const updateMutation = useUpdatePositionBrief();

  const initialRisks = positionBrief?.environmentAndMissionsDetails?.risks;

  const risksQuery = useFetchRisks();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty, isSubmitted },
    setValue,
  } = useFormWithZodResolver({
    schema: risksSchema,
    defaultValues: {
      hasRisks: databaseToZodData(positionBrief?.environmentAndMissionsDetails?.hasRisks),
      risks: initialRisks ?? [],
      otherRisks: positionBrief?.environmentAndMissionsDetails?.otherRisks ?? '',
    },
  });

  const { field } = useController({
    name: 'risks',
    control,
  });

  const hasRisks = watch('hasRisks');
  const otherRisks = watch('otherRisks');

  const risksHandleSubmit = () =>
    handleSubmit(values => {
      const hasRisksValue = zodToDatabaseData(values.hasRisks);
      if (!isBriefSigned) {
        updateMutation.mutate({
          id: positionBriefId,
          modification: {
            changes: { environmentAndMissionsDetails: { ...values, hasRisks: hasRisksValue } },
            _clear: {
              environmentAndMissionsDetails: {
                hasRisks: hasRisksValue === undefined,
              },
            },
          },
        });
      }
    });

  useEffect(() => {
    if (isDirty || isSubmitted) {
      risksHandleSubmit()();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRisks, field.value, otherRisks]);

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue(
        'hasRisks',
        databaseToZodData(positionBrief?.environmentAndMissionsDetails?.hasRisks)
      );
      setValue('otherRisks', positionBrief?.environmentAndMissionsDetails?.otherRisks ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRisks, otherRisks]);

  return (
    <div className={styles.container}>
      <h3 className={styles.subtitle}>y a t-il des risques liés au poste ?</h3>
      <div
        className={
          hasRisks !== YesNoUnknownEnum.Yes ? styles.ifSelectionNoOrUnkown : styles.ifSelectionYes
        }
      >
        <TripleChoiceButton name="hasRisks" control={control} />
      </div>

      <Animation.Unroll visible={hasRisks === YesNoUnknownEnum.Yes}>
        <RiskSelection
          title="sélectionnez parmi ces risques potentiels"
          risksQuery={risksQuery}
          risksField={field}
          control={control}
          setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          isBriefSigned={isBriefSigned}
        />

        <WithLightTitle
          title="ou précisez d'autres risques"
          rightTitleComponent={
            <span className={styles.charCountLabel}>{otherRisks?.length ?? 0}/255 caractères</span>
          }
        >
          <div>
            <TextArea
              name="otherRisks"
              control={control}
              className={styles.textArea}
              maxLength={3999}
            />
          </div>
        </WithLightTitle>
      </Animation.Unroll>
    </div>
  );
};

export default Risks;
