import Advantage from '../Company/Advantage';
import CompanyInformation from '../Company/CompanyInformation/index';
import Organization from '../Company/Organization';
import Strategy from '../Company/Strategy';
import Context from '../Position/Context/index';
import EnvironmentAndMissions from '../Position/EnvironmentAndMissions/EnvironmentAndMissions.component';
import PositionInformation from '../Position/PositionInformation';
import Curriculum from '../Profile/Curriculum';
import Skills from '../Profile/Skills';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props {}

interface PBriefNavigationNode {
  name: string;
  default: string;
  path: string;
  routes: { name: string; linkTo: string; component?: () => JSX.Element; path: string }[];
}

type PBriefNav = { [key: string]: PBriefNavigationNode };

const baseRoute = '/company/:companyId/brief/:positionBriefId';

export const navigation: PBriefNav = {
  company: {
    name: 'entreprise',
    default: './company/information',
    path: `${baseRoute}/company`,
    routes: [
      {
        name: 'informations générales',
        linkTo: './information',
        path: `${baseRoute}/company/information`,
        component: CompanyInformation,
      },
      {
        name: 'atouts client',
        linkTo: './assets',
        path: `${baseRoute}/company/assets`,
        component: Advantage,
      },
      {
        name: 'organisation',
        linkTo: './organization',
        path: `${baseRoute}/company/organization`,
        component: Organization,
      },
      {
        name: 'stratégie',
        linkTo: './strategy',
        path: `${baseRoute}/company/strategy`,
        component: Strategy,
      },
    ],
  },
  position: {
    name: 'poste',
    default: './position/context',
    path: `${baseRoute}/position`,
    routes: [
      {
        name: 'contexte',
        linkTo: './context',
        path: `${baseRoute}/position/context`,
        component: Context,
      },
      {
        name: 'environnement et missions',
        linkTo: './environment',
        path: `${baseRoute}/position/environment`,
        component: EnvironmentAndMissions,
      },
      {
        name: 'informations générales',
        linkTo: './information',
        path: `${baseRoute}/position/information`,
        component: PositionInformation,
      },
    ],
  },
  profile: {
    name: 'profil',
    default: './profile/curriculum',
    path: `${baseRoute}/profile`,
    routes: [
      {
        name: 'formations et diplômes',
        linkTo: './curriculum',
        path: `${baseRoute}/profile/curriculum`,
        component: Curriculum,
      },
      {
        name: 'compétences',
        linkTo: './skills',
        path: `${baseRoute}/profile/skills`,
        component: Skills,
      },
    ],
  },
};
