import { useQuery } from 'react-query';

import { ContractStatusesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchContractStatuses = () => {
  return useQuery(
    [QueryKeys.fetchContractStatuses],
    async () => {
      return ContractStatusesService.contractStatusesControllerGetContractStatuses();
    },
    { staleTime: 10 * 60 * 1000, cacheTime: 10 * 60 * 1000 }
  );
};
