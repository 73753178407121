import {
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BinocularFull, Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import ReportingModal from 'src/Containers/Modals/ReportingModal';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { FETCH_STATUS } from 'src/Redux/Types';
import { Reporting } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { frequencyUnitEnumToKeyValue } from 'src/Utils/frequencyUnitEnum';
import { sanitizeString } from 'src/Utils/sanitizeString';

import styles from './Skills.module.scss';
import { SkillsSchema } from './Skills.schema';

const Skills = () => {
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const id = parseInt(positionBriefId);
  const positionBriefQuery = useFetchPositionBriefDetails(id);
  const positionBriefFetchStatus = toFetchStatus(positionBriefQuery);
  const updateMutation = useUpdatePositionBrief();

  const skillsZodResolver = useFormWithZodResolver({
    schema: SkillsSchema,
    defaultValues: {
      technicalSkills: positionBriefQuery.data?.profileDetails?.technicalSkills ?? '',
      behavioralSkills: positionBriefQuery.data?.profileDetails?.behavioralSkills ?? '',
      reportings: positionBriefQuery.data?.profileDetails?.reportings ?? [],
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = skillsZodResolver;
  const technicalSkills = watch('technicalSkills');
  const behavioralSkills = watch('behavioralSkills');

  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  const isBriefSigned = useMemo(() => {
    return positionBriefQuery.data?.signatures !== null;
  }, [positionBriefQuery.data]);

  const skillsHandleSubmit = () =>
    handleSubmit(values => {
      updateMutation.mutate({
        id,
        modification: {
          changes: {
            ...values,
            profileDetails: {
              technicalSkills: sanitizeString(values.technicalSkills),
              behavioralSkills: sanitizeString(values.behavioralSkills),
              reportings: values.reportings,
            },
          },
        },
      });
    });

  const removeReporting = (index: number) => {
    const newReportings = [...skillsZodResolver.getValues().reportings];
    newReportings.splice(index, 1);
    skillsZodResolver.setValue('reportings', newReportings);
    skillsHandleSubmit()();
  };

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue('technicalSkills', positionBriefQuery.data?.profileDetails?.technicalSkills ?? '');
      setValue('behavioralSkills', positionBriefQuery.data?.profileDetails?.behavioralSkills ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technicalSkills, behavioralSkills]);

  return (
    <div className={styles.container}>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.title}>compétences techniques et comportementales</div>
          <TextAreaWithLightTitle
            title="décrivez les compétences techniques souhaitées pour le poste"
            name="technicalSkills"
            counterClassName={styles.charCountLabel}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={technicalSkills.length}
            control={control}
            onBlurCapture={skillsHandleSubmit()}
          />
          <TextAreaWithLightTitle
            title="décrivez les compétences comportementales souhaitées pour le poste"
            name="behavioralSkills"
            counterClassName={styles.charCountLabel}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={behavioralSkills.length}
            control={control}
            onBlurCapture={skillsHandleSubmit()}
          />
          <div className={styles.autosuggestContainer}></div>
          <div className={styles.separator}></div>
          <div className={styles.title}>reporting</div>
          <WithLightTitle
            title={`liste de vos reportings ${
              skillsZodResolver.getValues().reportings.length === 0
                ? ' (1 reporting minimum requis)'
                : ''
            }`}
          >
            {skillsZodResolver.getValues().reportings.map((reporting: Reporting, index) => (
              <div key={reporting.name}>
                {index === 0 && <div className={styles.reportingCardsSeparator} />}
                <HorizontalTextIconCard
                  containerClassName={styles.reportingCard}
                  leftIcon={<BinocularFull style={{ width: '48px' }} />}
                  label={reporting.name}
                  subLabel={`${reporting.numberPerPeriod} fois par ${
                    frequencyUnitEnumToKeyValue(reporting.frequencyUnit).value
                  }${
                    reporting.modalitiesDescription.length > 0
                      ? ', ' + reporting.modalitiesDescription
                      : ''
                  }`}
                  rightIcon={<Trashcan />}
                  onRightIconClick={() => {
                    if (isBriefSigned) {
                      setIsEditAfterSigningModalOpen(true);
                    } else {
                      removeReporting(index);
                    }
                  }}
                />
                <div className={styles.reportingCardsSeparator} />
              </div>
            ))}
          </WithLightTitle>
          <div
            onMouseDown={() => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              }
            }}
          >
            <ReportingModal
              otherReportingNames={skillsZodResolver
                .getValues()
                .reportings.map(reporting => reporting.name)}
              addReporting={(newReporting: Reporting) => {
                const updatedReportings = [
                  ...skillsZodResolver.getValues().reportings,
                  newReporting,
                ];
                skillsZodResolver.setValue('reportings', updatedReportings);
                skillsHandleSubmit()();
              }}
            />
          </div>
          <EditAfterSigningModal
            isOpen={isEditAfterSigningModalOpen}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="20rem" width="100%" uniqueKey="skillsLoader">
          <rect x="0%" y="5" rx="4" ry="4" width="30%" height="15" />
          <rect x="0%" y="35" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="55" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="30%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="30%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="135" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="155" rx="4" ry="4" width="20%" height="15" />
        </ContentLoader>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des compétences du profil" />
      )}
    </div>
  );
};

export default Skills;
