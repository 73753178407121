import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { useFetchCompanyDetails } from 'src/Hooks/Companies';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';

import styles from './BreadCrumb.module.scss';

const BreadCrumb = () => {
  const history = useHistory();
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const { data } = useFetchPositionBriefDetails(parseInt(positionBriefId));
  const companyId = data?.companyId;
  const { data: companyData } = useFetchCompanyDetails(companyId ?? '');

  const items = [
    {
      title: 'accueil',
      onClick: () => {
        history.push('/');
      },
    },
  ];

  if (companyData) {
    const { companyName } = companyData;
    items.push({
      title: `${companyName}`,
      onClick: () => {
        history.push({
          pathname: `/company/${companyId}`,
          state: {
            routeParameters: {
              companyId: companyId,
              companyName: companyName,
            },
          },
        });
      },
    });
  }

  return (
    <div className={styles.container}>
      {items.map(({ title, onClick }, index) => {
        return [
          <button key={title} className={styles.button} onClick={onClick}>
            <p>{title}</p>
          </button>,
          index === items.length - 1 ? null : (
            <p key={`separator-${index}`} className={styles.separator}>
              /
            </p>
          ),
        ];
      })}
    </div>
  );
};

export default BreadCrumb;
