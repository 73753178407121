import {
  Button,
  FetchButton,
  Modal,
  Checkbox,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BDPExportHeader } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';

import { navigation } from 'src/Containers/PositionBriefModification/PositionBriefModification.types';
import { useGenerateBDPPdf } from 'src/Hooks/PositionBriefs/useGeneratePDF';
import { toFetchStatus } from 'src/Services/Async';
import { onFileOpen } from 'src/Utils/onFileOpen';

import { ModalsContext } from '../ModalsContext/ModalsContext';
import { ValidElement } from '../SigningModal/SigningModal.types';

import styles from './ExportModal.module.scss';
import { ExportModalSchema } from './ExportModal.schema';
import { Props } from './ExportModal.types';

const ExportModal = ({ isOpen }: Props) => {
  const modalsContext = useContext(ModalsContext);
  const exportMutation = useGenerateBDPPdf();
  const onClose = () => {
    modalsContext.setIsExportModalOpen(false);
    setValue('sectionsToExport', {
      company: {
        all: false,
        information: false,
        assets: false,
        organization: false,
        strategy: false,
      },
      position: {
        all: false,
        information: false,
        context: false,
        environment: false,
      },
      profile: {
        all: false,
        curriculum: false,
        skills: false,
      },
      conclusion: {
        all: false,
        signatures: false,
      },
    });
  };
  const { control, handleSubmit, reset, setValue } = useFormWithZodResolver({
    schema: ExportModalSchema,
    defaultValues: {
      sectionsToExport: {
        company: {
          all: false,
          organization: false,
          information: false,
          assets: false,
          strategy: false,
        },
        position: {
          all: false,
          information: false,
          context: false,
          environment: false,
        },
        profile: {
          all: false,
          curriculum: false,
          skills: false,
        },
        conclusion: {
          all: false,
          signatures: false,
        },
      },
    },
  });

  const sectionsToExport = useController({ name: 'sectionsToExport', control });
  const notCompanyAll = !sectionsToExport.field.value.company.all;
  const notPositionAll = !sectionsToExport.field.value.position.all;
  const notProfileAll = !sectionsToExport.field.value.profile.all;
  const notConclusionAll = !sectionsToExport.field.value.conclusion.all;
  const allChecked = !notCompanyAll && !notPositionAll && !notProfileAll && !notConclusionAll;

  const exportHandleSubmit = () =>
    handleSubmit(values => {
      exportMutation.mutate(values.sectionsToExport, {
        onSuccess: buffer => {
          onFileOpen(buffer, 'application/pdf');
        },
      });
    });

  useEffect(() => {
    if (
      sectionsToExport.field.value.company.information &&
      sectionsToExport.field.value.company.assets &&
      sectionsToExport.field.value.company.organization &&
      sectionsToExport.field.value.company.strategy
    ) {
      setValue('sectionsToExport', {
        ...sectionsToExport.field.value,
        company: {
          ...sectionsToExport.field.value.company,
          all: true,
        },
      });
    } else {
      setValue('sectionsToExport', {
        ...sectionsToExport.field.value,
        company: {
          ...sectionsToExport.field.value.company,
          all: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectionsToExport.field.value.company.information,
    sectionsToExport.field.value.company.assets,
    sectionsToExport.field.value.company.organization,
    sectionsToExport.field.value.company.strategy,
  ]);

  useEffect(() => {
    if (
      sectionsToExport.field.value.position.information &&
      sectionsToExport.field.value.position.context &&
      sectionsToExport.field.value.position.environment
    ) {
      setValue('sectionsToExport', {
        ...sectionsToExport.field.value,
        position: {
          ...sectionsToExport.field.value.position,
          all: true,
        },
      });
    } else {
      setValue('sectionsToExport', {
        ...sectionsToExport.field.value,
        position: {
          ...sectionsToExport.field.value.position,
          all: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectionsToExport.field.value.position.information,
    sectionsToExport.field.value.position.context,
    sectionsToExport.field.value.position.environment,
  ]);

  useEffect(() => {
    if (
      sectionsToExport.field.value.profile.curriculum &&
      sectionsToExport.field.value.profile.skills
    ) {
      setValue('sectionsToExport', {
        ...sectionsToExport.field.value,
        profile: {
          ...sectionsToExport.field.value.profile,
          all: true,
        },
      });
    } else {
      setValue('sectionsToExport', {
        ...sectionsToExport.field.value,
        profile: {
          ...sectionsToExport.field.value.profile,
          all: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectionsToExport.field.value.profile.curriculum,
    sectionsToExport.field.value.profile.skills,
  ]);

  return (
    <Modal
      title="export du brief de poste"
      subtitle="sélectionnez la ou les parties que vous souhaitez exporter"
      onClose={onClose}
      icon={
        <div className={styles.modalIcon}>
          <BDPExportHeader />
        </div>
      }
      open={isOpen}
      footerActionsLeft={[
        <Button.Tertiary key="reset-button" onClick={() => reset()}>
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary key="close-button" onClick={onClose}>
          fermer
        </Button.Secondary>,
        <FetchButton
          key="validate-button"
          title="valider"
          onClick={exportHandleSubmit()}
          fetchStatus={toFetchStatus(exportMutation)}
          onSuccess={() => modalsContext.setIsExportModalOpen(false)}
        />,
      ]}
    >
      <div className={styles.allCheckbox}>
        <Checkbox
          label={
            allChecked ? 'désélectionner toutes les rubriques' : 'sélectionner toutes les rubriques'
          }
          checked={allChecked}
          halfChecked={
            sectionsToExport.field.value.company.information ||
            sectionsToExport.field.value.company.assets ||
            sectionsToExport.field.value.company.organization ||
            sectionsToExport.field.value.company.strategy ||
            sectionsToExport.field.value.position.information ||
            sectionsToExport.field.value.position.context ||
            sectionsToExport.field.value.position.environment ||
            sectionsToExport.field.value.profile.curriculum ||
            sectionsToExport.field.value.profile.skills ||
            sectionsToExport.field.value.conclusion.signatures
          }
          onChange={() =>
            setValue('sectionsToExport', {
              position: {
                information: !allChecked,
                context: !allChecked,
                environment: !allChecked,
                all: !allChecked,
              },
              company: {
                information: !allChecked,
                assets: !allChecked,
                organization: !allChecked,
                strategy: !allChecked,
                all: !allChecked,
              },
              profile: {
                curriculum: !allChecked,
                skills: !allChecked,
                all: !allChecked,
              },
              conclusion: {
                all: !allChecked,
                signatures: !allChecked,
              },
            })
          }
        />
      </div>
      <div className={styles.rubricsContainer}>
        {Object.keys(navigation).map(element => {
          return (
            <WithLightTitle
              key={`light-title-${navigation[element].name}`}
              title={`rubrique « ${navigation[element].name} »`}
            >
              <div className={styles.checkbox}>
                <Checkbox
                  checked={
                    sectionsToExport.field.value[
                      element as keyof typeof sectionsToExport.field.value
                    ].all
                  }
                  halfChecked={
                    (element === 'company' &&
                      ['information', 'assets', 'organization', 'strategy'].some(
                        key =>
                          sectionsToExport.field.value.company[
                            key as keyof typeof sectionsToExport.field.value.company
                          ]
                      )) ||
                    (element === 'position' &&
                      ['information', 'context', 'environment'].some(
                        key =>
                          sectionsToExport.field.value.position[
                            key as keyof typeof sectionsToExport.field.value.position
                          ]
                      )) ||
                    (element === 'profile' &&
                      ['curriculum', 'skills'].some(
                        key =>
                          sectionsToExport.field.value.profile[
                            key as keyof typeof sectionsToExport.field.value.profile
                          ]
                      ))
                  }
                  onChange={() => {
                    const elementsMap = {
                      company: ['information', 'assets', 'organization', 'strategy', 'all'],
                      position: ['information', 'context', 'environment', 'all'],
                      profile: ['curriculum', 'skills', 'all'],
                    };

                    const notAllMap = {
                      company: notCompanyAll,
                      position: notPositionAll,
                      profile: notProfileAll,
                    };

                    if (elementsMap[element as keyof typeof elementsMap]) {
                      const updates = elementsMap[element as keyof typeof elementsMap].reduce(
                        (acc, key) => ({
                          ...acc,
                          [key]: notAllMap[element as keyof typeof notAllMap],
                        }),
                        {}
                      );

                      setValue('sectionsToExport', {
                        ...sectionsToExport.field.value,
                        [element]: updates,
                      });
                    }
                  }}
                />
                <div>tout sélectionner</div>
              </div>
              <div className={styles.checkboxes}>
                {navigation[element as keyof typeof navigation].routes.map(value => {
                  const elementKey = element as ValidElement;

                  const pathKey = value.path
                    .split('/')
                    .pop() as keyof typeof sectionsToExport.field.value[ValidElement];

                  return (
                    <div key={`div-${navigation[element].name}`} className={styles.checkbox}>
                      <Checkbox
                        checked={sectionsToExport.field.value[elementKey][pathKey]}
                        onChange={() => {
                          if (elementKey) {
                            const updatedValue = !sectionsToExport.field.value[elementKey][pathKey];
                            setValue('sectionsToExport', {
                              ...sectionsToExport.field.value,
                              [elementKey]: {
                                ...sectionsToExport.field.value[elementKey],
                                [pathKey]: updatedValue,
                              },
                            });
                          }
                        }}
                      />
                      <div>{value.name.toLowerCase()}</div>
                    </div>
                  );
                })}
              </div>
            </WithLightTitle>
          );
        })}
        <WithLightTitle title="conclusion">
          <div className={styles.checkbox}>
            <Checkbox
              checked={sectionsToExport.field.value.conclusion.signatures}
              halfChecked={sectionsToExport.field.value.conclusion.signatures}
              onChange={() =>
                setValue('sectionsToExport', {
                  ...sectionsToExport.field.value,
                  conclusion: {
                    ...sectionsToExport.field.value.conclusion,
                    signatures: !sectionsToExport.field.value.conclusion.signatures,
                  },
                })
              }
            />
            <div>signatures</div>
          </div>
        </WithLightTitle>
      </div>
    </Modal>
  );
};

export default ExportModal;
