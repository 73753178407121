import { AutoSuggestWithList } from '@randstad-lean-mobile-factory/react-components-core';
import {
  DefaultIcon,
  FlagArabia,
  FlagChina,
  FlagGermany,
  FlagItaly,
  FlagPortugal,
  FlagRussia,
  FlagSpain,
  FlagUK,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import { SelectionIconButtonsForm } from 'src/Components/SelectionIconButtonsForm';
import { useFetchLanguages } from 'src/Hooks/Repositories/useFetchLanguages';
import { FETCH_STATUS } from 'src/Redux/Types';
import { addOrRemoveString } from 'src/Utils/addOrRemoveString';

import styles from './LanguagePicker.module.scss';
import { Props } from './LanguagePicker.types';

const LanguagePicker = ({
  selectedLanguages,
  languagesQuery,
  languagesController,
  isBriefSigned,
  setIsEditAfterSigningModalOpen,
}: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data } = useFetchLanguages();
  const formattedData = data?.map(language => {
    return { id: language.id.toString() ?? '', label: language.label ?? '' };
  });
  const researchedLanguages =
    formattedData?.filter(
      language =>
        language.label?.toLowerCase().includes(keyword.toLowerCase()) &&
        !selectedLanguages.map(languageItem => languageItem.id).includes(language.id.toString())
    ) ?? [];
  const iconMapping: { [id: number]: JSX.Element } = {
    1: <FlagUK />,
    2: <FlagSpain />,
    3: <FlagGermany />,
    4: <FlagPortugal />,
    5: <FlagItaly />,
    6: <FlagArabia />,
    7: <FlagRussia />,
    8: <FlagChina />,
  };

  return (
    <>
      <SelectionIconButtonsForm
        title="les langues les plus demandées"
        isLoading={languagesQuery.isLoading}
        loadingItems={3}
        isError={languagesQuery.isError}
        error="Une erreur est survenue lors de la récupération du référentiel des risques"
        isSuccess={languagesQuery.isSuccess}
        selectIconButtonClassName={styles.languageCard}
        selectIconButtonLoaderClassName={styles.languageCardLoader}
        data={
          languagesQuery.data
            ?.filter(
              language =>
                language.label === 'anglais' ||
                language.label === 'espagnol' ||
                language.label === 'allemand'
            )
            .map(language => ({
              key: language.id.toString(),
              label: language.label,
              icon: iconMapping[language.id] ?? <DefaultIcon />,
              selected:
                languagesController.field.value.find(
                  selected => selected === language.id.toString()
                ) !== undefined,
              onClick: () => {
                languagesController.field.onChange(
                  addOrRemoveString(languagesController.field.value, language.id.toString())
                );
              },
            })) ?? []
        }
        female={true}
        isBriefSigned={isBriefSigned}
        setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
      />
      <div
        className={classnames(styles.languagePickerContainer, {
          [styles.borderBottom]: selectedLanguages.length > 0,
        })}
        onMouseDown={() => {
          if (isBriefSigned) {
            setIsEditAfterSigningModalOpen(true);
          }
        }}
      >
        <AutoSuggestWithList
          globalFetchStatus={FETCH_STATUS.FULFILLED}
          values={selectedLanguages}
          results={researchedLanguages.filter(
            language => language.id !== '1' && language.id !== '2' && language.id !== '3'
          )}
          dataExtractor={language => ({
            key: language.id,
            label: language.label,
            icon: iconMapping[parseInt(language.id)],
          })}
          singularTitle="langue ajoutée"
          autosuggestTitle="autres langues"
          title="langues ajoutées"
          autosuggestFetchStatus={FETCH_STATUS.FULFILLED}
          emptyText=""
          onSearch={(word: string) => {
            setKeyword(word);
          }}
          onChange={languages => {
            if (!isBriefSigned) {
              languagesController.field.onChange(languages.map(language => language.id));
            }
            setKeyword('');
          }}
        />
      </div>
    </>
  );
};

export default LanguagePicker;
