import {
  BadgeDropdown,
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextInput,
  useFormWithZodResolver,
  TripleChoiceButton,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import SelectActivitySector from 'src/Components/SelectActivitySector';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import { useFetchCompanyDetails } from 'src/Hooks/Companies';
import useDeleteCompetitor from 'src/Hooks/Competitors/useDeleteCompetitor';
import useFetchCompetitors from 'src/Hooks/Competitors/useFetchCompetitors';
import useUpdateCompetitor from 'src/Hooks/Competitors/useUpdateCompetitor';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { FETCH_STATUS } from 'src/Redux/Types';
import { Competitor, CompetitorStatus } from 'src/Services/API';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/Async';
import { sortCompetitors, statusColorsMap } from 'src/Services/Competitor/competitorStatus';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { YesNoUnknownEnum } from 'src/Utils/yesNoUnknownEnum';
import { databaseToZodData } from 'src/Utils/ZodDatabaseTransformer';

import styles from './CompanyInformation.module.scss';
import { companyInformationSchema } from './CompanyInformation.schema';
import CompetitorModal from './CompetitorModal';

const CompanyInformation = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const fetchCompanyQuery = useFetchCompanyDetails(companyId);
  const fetchPositionBriefDetails = useFetchPositionBriefDetails(parseInt(positionBriefId));
  const positionBriefFetchStatus = toFetchStatus(fetchPositionBriefDetails);
  const fetchCompetitors = useFetchCompetitors(parseInt(positionBriefId));
  const sortedCompetitors = sortCompetitors(fetchCompetitors.data ?? []);
  const fetchStatus = mergeSeveralFetchStatus([
    toFetchStatus(fetchCompanyQuery),
    positionBriefFetchStatus,
    toFetchStatus(fetchCompetitors),
  ]);
  const updateMutation = useUpdatePositionBrief();
  const deleteCompetitorMutation = useDeleteCompetitor(positionBriefId);
  const updateCompetitorMutation = useUpdateCompetitor(positionBriefId);
  const { control, watch, handleSubmit, setValue, formState } = useFormWithZodResolver({
    schema: companyInformationSchema,
    defaultValues: {
      hasGroupName: databaseToZodData(
        !!fetchPositionBriefDetails.data?.companyDetails?.groupName &&
          fetchPositionBriefDetails.data?.companyDetails?.groupName.length > 0
          ? true
          : undefined
      ),
      groupName: fetchPositionBriefDetails.data?.companyDetails?.groupName ?? '',
      activitySectorId: fetchPositionBriefDetails.data?.companyDetails?.activitySector?.id,
    },
  });
  const hasGroupName = watch('hasGroupName');
  const activitySectorId = watch('activitySectorId');
  const groupName = watch('groupName');

  const { field } = useController({ control, name: 'activitySectorId' });
  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  const isBriefSigned = useMemo(() => {
    return fetchPositionBriefDetails.data?.signatures !== null;
  }, [fetchPositionBriefDetails.data]);

  const companyInformationHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        if (!isBriefSigned)
          updateMutation.mutate({
            id: parseInt(positionBriefId),
            modification: {
              changes: {
                companyDetails: {
                  groupName: values.groupName,
                  activitySector: values.activitySectorId
                    ? {
                        id: values.activitySectorId,
                      }
                    : undefined,
                },
              },
              _clear: {
                companyDetails: {
                  activitySector: !values.activitySectorId,
                },
              },
            },
          });
      }),
    [handleSubmit, positionBriefId, updateMutation, isBriefSigned]
  );

  const submit = companyInformationHandleSubmit();

  useEffect(() => {
    if (formState.isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySectorId]);

  useEffect(() => {
    if (formState.isDirty) {
      if (hasGroupName !== YesNoUnknownEnum.Yes) {
        setValue('groupName', '');
      }
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGroupName]);

  useEffect(() => {
    if (isBriefSigned && formState.isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue(
        'hasGroupName',
        databaseToZodData(
          !!fetchPositionBriefDetails.data?.companyDetails?.groupName &&
            fetchPositionBriefDetails.data?.companyDetails?.groupName.length > 0
            ? true
            : undefined
        )
      );
      setValue('groupName', fetchPositionBriefDetails.data?.companyDetails?.groupName ?? '');
      setValue(
        'activitySectorId',
        fetchPositionBriefDetails.data?.companyDetails?.activitySector?.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGroupName, groupName, activitySectorId]);

  return (
    <div>
      <h2 className={styles.title}>à propos de la société</h2>
      {fetchStatus === FETCH_STATUS.FULFILLED && (
        <div className={styles.container}>
          <WithLightTitle className={styles.informationContainer} title="nom">
            {fetchCompanyQuery.data?.companyName}
          </WithLightTitle>
          <div className={styles.siretSectorContainer}>
            <WithLightTitle className={styles.informationContainer} title="siret">
              {fetchCompanyQuery.data?.siret}
            </WithLightTitle>
            <WithLightTitle className={styles.informationContainer} title="forme juridique">
              {fetchCompanyQuery.data?.companySector?.label}
            </WithLightTitle>
          </div>
          <WithLightTitle className={styles.informationContainer} title="convention collective">
            {fetchCompanyQuery.data?.collectiveAgreement?.label ?? 'non renseigné'}
          </WithLightTitle>
          <WithLightTitle
            className={styles.tripleChoiceContainer}
            title="cette société fait-elle partie d'un groupe ?"
          >
            <TripleChoiceButton name="hasGroupName" control={control} />
          </WithLightTitle>
          {hasGroupName === YesNoUnknownEnum.Yes && (
            <WithLightTitle title="nom du groupe" className={styles.beforeTitle}>
              <TextInput
                onBlurCapture={companyInformationHandleSubmit()}
                name="groupName"
                control={control}
              />
            </WithLightTitle>
          )}
          <WithLightTitle className={styles.informationContainer} title="effectif">
            {pluralFormat(fetchCompanyQuery.data?.workforce ?? 0, 'personnes')}
          </WithLightTitle>
          <div className={styles.separator} />
          <h2 className={styles.title}>positionnement</h2>
          {fetchCompetitors.data && fetchCompetitors.data.length > 0 && (
            <WithLightTitle
              className={styles.informationContainer}
              title={pluralFormat(fetchCompetitors.data.length, 'concurrent ajouté')}
            >
              {sortedCompetitors.map((competitor: Competitor) => (
                <HorizontalTextIconCard
                  containerClassName={styles.competitorCard}
                  key={competitor.id}
                  label={competitor.name}
                  rightComponent={
                    <BadgeDropdown
                      items={Object.values(CompetitorStatus)}
                      selectedItem={competitor.status}
                      onSelectItem={(item: CompetitorStatus) => {
                        if (!isBriefSigned) {
                          updateCompetitorMutation.mutate({
                            id: competitor.id,
                            status: item,
                          });
                        } else {
                          setIsEditAfterSigningModalOpen(true);
                        }
                      }}
                      keyValueExtractor={(status: CompetitorStatus) => ({
                        key: status,
                        value: status.toLowerCase(),
                        className: statusColorsMap[status],
                      })}
                    />
                  }
                  rightIcon={
                    <Trashcan
                      onClick={() => {
                        if (!isBriefSigned) {
                          deleteCompetitorMutation.mutate(competitor.id);
                        } else {
                          setIsEditAfterSigningModalOpen(true);
                        }
                      }}
                    />
                  }
                />
              ))}
            </WithLightTitle>
          )}
          {fetchCompetitors.data && (
            <div
              onMouseDown={() => {
                if (isBriefSigned) {
                  setIsEditAfterSigningModalOpen(true);
                }
              }}
            >
              <CompetitorModal
                positionBriefId={parseInt(positionBriefId)}
                competitorNames={fetchCompetitors.data.map(competitor => competitor.name)}
              />
            </div>
          )}
          <WithLightTitle
            className={styles.informationContainer}
            title="De quel secteur d'activité fait partie la société ?"
          >
            <SelectActivitySector
              value={field.value?.toString()}
              onChange={(_: unknown, change: string) => {
                field.onChange(change ? parseInt(change, 10) : null);
              }}
            />
          </WithLightTitle>
          {Object.keys(formState.errors).length !== 0 && (
            <div className={styles.ErrorMessage}>
              il y a des erreurs ou des champs non remplis dans le formulaire
            </div>
          )}
          <EditAfterSigningModal
            isOpen={isEditAfterSigningModalOpen}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
        </div>
      )}
      {fetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="20rem" width="100%" uniqueKey="informationLoader">
          <rect x="0%" y="5" rx="4" ry="4" width="30%" height="15" />
          <rect x="0%" y="35" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="55" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="30%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="30%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="135" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="155" rx="4" ry="4" width="20%" height="15" />
        </ContentLoader>
      )}
      {fetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des informations de la société" />
      )}
    </div>
  );
};

export default CompanyInformation;
