import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Router, Switch, useHistory, useLocation } from 'react-router-dom';

import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrand,
} from 'src/Redux/Perimeter/Selectors';
import history from 'src/Utils/history';

import PrivateRoute from './Components/PrivateRoute';
import MainPage from './Containers/MainPage/MainPage.component';
import ExportBriefsModal from './Containers/Modals/ExportBriefsModal';
import ExportModal from './Containers/Modals/ExportModal';
import MissionSearchModal from './Containers/Modals/MissionSearchModal';
import PerimeterSelectionModal from './Containers/Modals/PerimeterSelectionModal';
import useInitializeAnalytics from './Hooks/Analytics/useInitializeAnalytics';
import { setupAPI } from './Services/API/APIConfigurator';
import { security } from './Services/API/Interceptors';
import { LocationState, ModalRoute } from './Services/Routing';
import DebugTools from './Utils/DebugTools';

const modalRouteMap: ModalRoute[] = [
  { route: 'perimeter-selection', component: PerimeterSelectionModal },
  { route: 'export', component: ExportBriefsModal },
  { route: 'recruiting-missions', component: MissionSearchModal },
];

function ModalSwitch() {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const history = useHistory();

  if (
    !background &&
    modalRouteMap.some(
      modalRoute => modalRoute.route === location.pathname && !modalRoute.backgroundIsOptional
    )
  ) {
    history.push('/');
  }

  return (
    <DebugTools>
      <>
        <Switch location={background || location}>
          <PrivateRoute key={1100} path="/*/export" component={ExportModal} />
          <PrivateRoute key={1000} path="/" component={MainPage} />
        </Switch>

        {modalRouteMap.map(modalInfo => (
          <PrivateRoute
            key={modalInfo.route}
            path={`(.*)/${modalInfo.route}`}
            children={<modalInfo.component modalParameters={locationState?.modalParameters} />}
          />
        ))}

        <ToastContainer position="bottom-right" />
      </>
    </DebugTools>
  );
}

function App() {
  useInitializeAnalytics();
  const { getAccessTokenSilently, logout, isAuthenticated } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLogout(logout);
  useEffect(() => setupAPI(), []);

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);

  useEffect(() => {
    if (isAuthenticated) {
      ReactGA.event('Login', {
        ...userInfo,
        brandCode: currentBrand?.brandCodeApiHeader,
      });
    }
  }, [currentAgency, currentBrand?.brandCodeApiHeader, isAuthenticated, userInfo]);

  return (
    <div className="App">
      <Router history={history}>
        <ModalSwitch />
      </Router>
    </div>
  );
}

export default App;
