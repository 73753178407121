import { useMutation } from 'react-query';

import { RepositoriesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchCityList = () => {
  return useMutation([MutationKeys.fetchCities], async (keyword: string) => {
    if (keyword) {
      return RepositoriesService.repositoriesControllerGetCities({
        keyword,
      });
    }
    return [];
  });
};
