import {
  OrganizationChart,
  DragDropList,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { OrganizationChartItem } from '@randstad-lean-mobile-factory/react-components-core/dist/OrganizationChart/OrganizationChart.types';
import { CircleMoins, Tools } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import OrganizationChartModal from 'src/Containers/Modals/OrganizationChartModal';
import OrganizationChartWarningModal from 'src/Containers/Modals/OrganizationChartWarningModal';
import RecruitmentStepModal from 'src/Containers/Modals/RecruitmentStepModal';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { useFetchCompanyDetails } from 'src/Hooks/Companies/useFetchCompanyDetails';
import { useFetchCompanyDocuments } from 'src/Hooks/Documents/useFetchCompanyDocuments';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { useFetchRecruitmentSteps } from 'src/Hooks/RecruitmentSteps/useFetchRecruitmentSteps';
import { useUpdateRecruitmentSteps } from 'src/Hooks/RecruitmentSteps/useUpdateRecruitmentSteps';
import { FETCH_STATUS } from 'src/Redux/Types';
import { CompanyHierarchy, RecruitmentStepParams } from 'src/Services/API';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/Async';
import { pluralFormat } from 'src/Utils/pluralFormat';

import Documents from './Documents/Documents.component';
import KeyPersonsPicker from './KeyPersonsPicker/KeyPersonsPicker.component';
import styles from './Organization.module.scss';
import { organisationSchema } from './Organization.schema';
import { RecruitmentStepWithoutPositionBrief } from './Organization.types';

const Organization = () => {
  const { positionBriefId, companyId } = useParams<{
    positionBriefId: string;
    companyId: string;
  }>();
  const id = parseInt(positionBriefId);
  const companyQuery = useFetchCompanyDetails(companyId);
  const positionBriefQuery = useFetchPositionBriefDetails(id);
  const rawDocumentsQuery = useFetchCompanyDocuments(positionBriefQuery?.data?.companyId ?? '');
  const companyContactsQuery = useFetchCompanyContacts(positionBriefQuery?.data?.companyId ?? '');
  const companyContactsFetchStatus = toFetchStatus(companyContactsQuery);
  const globalFetchStatus = mergeSeveralFetchStatus([
    toFetchStatus(positionBriefQuery),
    toFetchStatus(rawDocumentsQuery),
    toFetchStatus(companyQuery),
    companyContactsFetchStatus,
  ]);
  const fetchRecruitmentSteps = useFetchRecruitmentSteps(id);
  const {
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: organisationSchema,
    defaultValues: {
      hierarchy: positionBriefQuery.data?.companyDetails?.hierarchy ?? [],
      recruitmentProcess: positionBriefQuery.data?.companyDetails?.recruitmentProcess ?? '',
      recruitmentSteps:
        fetchRecruitmentSteps.data?.sort(
          (m1: RecruitmentStepParams, m2: RecruitmentStepParams) => m1.stepRank - m2.stepRank
        ) ?? [],
    },
  });

  const hierarchy = watch('hierarchy');
  const recruitmentProcess = watch('recruitmentProcess');
  const recruitmentSteps = useController({ control, name: 'recruitmentSteps' });
  const updateMutation = useUpdatePositionBrief();
  const updateRecruitmentSteps = useUpdateRecruitmentSteps();

  const companyOrganizationHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          id,
          modification: {
            changes: {
              companyDetails: {
                hierarchy: values.hierarchy,
                recruitmentProcess: values.recruitmentProcess,
              },
            },
          },
        });
        updateRecruitmentSteps.mutate(
          values.recruitmentSteps?.map(recruitmentStep => ({
            id: recruitmentStep.id,
            stepRank: recruitmentStep.stepRank,
          })) ?? []
        );
      }),
    [handleSubmit, id, updateMutation, updateRecruitmentSteps]
  );

  const updateHierarchy = (newHierarchy: CompanyHierarchy[]) => {
    setValue('hierarchy', newHierarchy);
    companyOrganizationHandleSubmit()();
  };

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  const isBriefSigned = useMemo(() => {
    return positionBriefQuery.data?.signatures !== null;
  }, [positionBriefQuery.data]);

  useEffect(() => {
    setValue('recruitmentSteps', fetchRecruitmentSteps.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRecruitmentSteps.data]);

  const updateNeedsOrder = (result: DropResult) => {
    if (isBriefSigned || !result.destination) {
      return;
    }
    const items: { id: number; name: string }[] = Array.from(recruitmentSteps.field.value ?? []);
    const [updatedResult] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, updatedResult);
    recruitmentSteps.field.onChange(items);
    companyOrganizationHandleSubmit()();
  };

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue(
        'recruitmentProcess',
        positionBriefQuery.data?.companyDetails?.recruitmentProcess ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruitmentProcess]);

  return (
    <div>
      {globalFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <OrganizationChartWarningModal
            open={isWarningModalOpen}
            onClose={() => setIsWarningModalOpen(false)}
            onDelete={() => updateHierarchy([])}
          />
          <h2
            className={styles.keyPersonsTitle}
            onMouseDown={() => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              }
            }}
          >
            personnes clés dans le processus de recrutement
          </h2>
          <KeyPersonsPicker
            briefId={id}
            companyContacts={companyContactsQuery?.data ?? []}
            fetchStatus={companyContactsFetchStatus ?? FETCH_STATUS.PENDING}
            isBriefSigned={isBriefSigned}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
          <div className={styles.separator} />
          <h2>processus de recrutement</h2>
          <div>
            <WithLightTitle
              title={`${
                fetchRecruitmentSteps?.data !== undefined &&
                fetchRecruitmentSteps?.data !== null &&
                fetchRecruitmentSteps?.data?.length > 0
                  ? pluralFormat(fetchRecruitmentSteps?.data?.length, 'étape')
                  : 'aucune étape de recrutement'
              } actuellement`}
              className={styles.recruitmentStepsStyle}
            >
              <DragDropList
                draggableItemClassname={styles.containerDrag}
                droppableId="recruitmentStep"
                items={
                  recruitmentSteps.field.value?.map(
                    (value: RecruitmentStepWithoutPositionBrief, index: number) => {
                      return {
                        id: index + value.name,
                        item: (
                          <div
                            onMouseDown={() => {
                              if (isBriefSigned) {
                                setIsEditAfterSigningModalOpen(true);
                              }
                            }}
                          >
                            <RecruitmentStepModal
                              positionBrief={parseInt(positionBriefId)}
                              creation={false}
                              recruitmentStep={value}
                              index={index}
                            />
                          </div>
                        ),
                      };
                    }
                  ) ?? []
                }
                onDragEnd={updateNeedsOrder}
              />
            </WithLightTitle>
          </div>
          <div
            onMouseDown={() => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              }
            }}
          >
            <RecruitmentStepModal positionBrief={parseInt(positionBriefId)} creation={true} />
          </div>
          <TextAreaWithLightTitle
            title="autres informations concernant le processus de recrutement"
            name="recruitmentProcess"
            counterClassName={styles.counter}
            textAreaClassName={styles.textArea}
            placeholder="ex: délai du recrutement, les pièces justificatives à avoir"
            maxLength={4000}
            valueLength={recruitmentProcess.length}
            control={control}
            onBlurCapture={companyOrganizationHandleSubmit()}
          />
          <div className={styles.separator} />
          <h2>organigramme</h2>
          <div
            onMouseDown={() => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              }
            }}
          >
            <OrganizationChartModal
              data={hierarchy}
              onValidate={(data: CompanyHierarchy[]) => updateHierarchy(data)}
              trigger={
                hierarchy.length === 0 ? (
                  <div className={styles.modalTrigger}>
                    <Tools className={styles.addHierarchyIcon} />
                    <div
                      className={styles.addHierarchy}
                      onMouseDown={() => {
                        if (isBriefSigned) {
                          setIsEditAfterSigningModalOpen(true);
                        }
                      }}
                    >
                      produire l'organigramme
                    </div>
                  </div>
                ) : (
                  <div className={styles.modifyTrigger}>modifier</div>
                )
              }
            />
          </div>
          {hierarchy.length > 0 && (
            <>
              <div className={styles.chartContainer}>
                <OrganizationChart
                  isDisplayMode
                  data={[...hierarchy] as OrganizationChartItem[]}
                  titlePlaceholder="nom et prénom"
                  subtitlePlaceholder="rôle fonction de la personne"
                  updateData={(data: OrganizationChartItem[]) =>
                    updateHierarchy(data as CompanyHierarchy[])
                  }
                />
              </div>
              <div
                className={styles.modalTrigger}
                onClick={() => setIsWarningModalOpen(true)}
                onMouseDown={() => {
                  if (isBriefSigned) {
                    setIsEditAfterSigningModalOpen(true);
                  }
                }}
              >
                <CircleMoins className={styles.addHierarchyIcon} />
                <div className={styles.addHierarchy}>supprimer l'organigramme</div>
              </div>
            </>
          )}
          <Documents
            companyId={positionBriefQuery?.data?.companyId ?? ''}
            rawDocuments={rawDocumentsQuery}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
            isBriefSigned={isBriefSigned}
          />
          <EditAfterSigningModal
            isOpen={isEditAfterSigningModalOpen}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
        </>
      )}

      {globalFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération de l'organisation de la société" />
      )}
    </div>
  );
};

export default Organization;
