import { useMutation } from 'react-query';

import { DocumentsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useUploadDocument = () => {
  return useMutation(
    [MutationKeys.uploadDocument],
    async (params: { companyId: string; file: File }) => {
      const uploadedDocument = await DocumentsService.documentsControllerUploadDocument({
        companyId: params.companyId,
        file: params.file,
      });
      return uploadedDocument;
    },
    {}
  );
};
