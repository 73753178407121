import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { Competitor, CompetitorsService, CreateCompetitorsBody } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

const useCreateCompetitor = (
  extraOptions?: UseMutationOptions<Competitor[], unknown, CreateCompetitorsBody>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.createCompetitor],
    async (body: CreateCompetitorsBody) => {
      return CompetitorsService.competitorsControllerCreateCompetitors({ body });
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries(QueryKeys.fetchCompetitors);
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};

export default useCreateCompetitor;
