import {
  ComboBox,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFetchCityList } from 'src/Hooks/Cities';
import { useFetchCompanyWorkingLocations } from 'src/Hooks/Companies';
import { City, CompanyAddress } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import styles from './AddressSelection.module.scss';
import { Props } from './AddressSelection.types';
import CompanyWorkingLocationPicker from './CompanyWorkingLocationPicker';

const AddressSelection = ({
  companyId,
  selectedAddress,
  setSelectedAddress,
  isBriefSigned,
  setIsEditAfterSigningModalOpen,
}: Props) => {
  const companyWorkingLocations = useFetchCompanyWorkingLocations(companyId ?? '');
  const cityListMutation = useFetchCityList();
  const throttledFetchCityList = useMemo(() => throttle(cityListMutation.mutate, 500), [
    cityListMutation.mutate,
  ]);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const updateSelectedCity = (address: CompanyAddress) => {
    address.cityName &&
      cityListMutation.mutate(address.cityName, {
        onSuccess: cities => {
          const matchingCity = cities?.find(
            city => city.name === address.cityName && city.zipCode === address.zipCode
          );
          matchingCity && setSelectedCity(matchingCity);
        },
      });
  };

  const callbackSelectedCity = useCallback(
    () =>
      cityListMutation.mutate(selectedAddress?.cityName ?? '', {
        onSuccess: cities => {
          const matchingCity = cities?.find(
            city =>
              city.name === selectedAddress?.cityName && city.zipCode === selectedAddress?.zipCode
          );
          if (matchingCity) {
            setSelectedCity(matchingCity);
          }
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAddress]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => callbackSelectedCity(), [selectedAddress]);

  return (
    <div>
      <div
        className={styles.container}
        onMouseDown={() => {
          if (isBriefSigned) {
            setIsEditAfterSigningModalOpen(true);
          }
        }}
      >
        <CompanyWorkingLocationPicker
          companyWorkingLocations={
            companyWorkingLocations?.data?.filter(item => item.cityName !== undefined) ?? []
          }
          selectedCompanyWorkingLocation={selectedAddress}
          setSelectedCompanyWorkingLocation={(companyAddress?: CompanyAddress) => {
            if (companyAddress !== undefined) {
              updateSelectedCity(companyAddress);
              setSelectedAddress({
                address: companyAddress.address ?? '',
                zipCode: companyAddress.zipCode ?? '',
                cityName: companyAddress.cityName ?? '',
                inseeCode: companyAddress.inseeCode ?? '',
              });
            }
          }}
        />
      </div>

      <WithLightTitle
        title="si l'adresse n'est pas dans la liste précédente, remplir les deux champs suivants : numéro et voie"
        className={styles.fieldContainer}
      >
        <div
          onMouseDown={() => {
            if (isBriefSigned) {
              setIsEditAfterSigningModalOpen(true);
            }
          }}
        >
          <TextInput
            value={selectedAddress?.address}
            onChange={event => {
              const newStreet = (event.target as HTMLInputElement).value;
              setSelectedAddress({
                address: newStreet,
                zipCode: selectedAddress?.zipCode,
                cityName: selectedAddress?.cityName,
                inseeCode: selectedAddress?.inseeCode,
              });
            }}
            placeholder="numéro et voie"
          />
        </div>
      </WithLightTitle>
      <WithLightTitle title="ville" className={styles.fieldContainer}>
        <div
          onMouseDown={() => {
            if (isBriefSigned) {
              setIsEditAfterSigningModalOpen(true);
            }
          }}
        >
          <ComboBox
            id="city"
            placeholder="nom de la ville, au moins 1 caractère"
            value={selectedCity}
            keyValueExtractor={(searchResult: City) => {
              return {
                key: searchResult.uId ?? '',
                value:
                  `${searchResult.name ?? ''} ${
                    searchResult.zipCode ? '(' + searchResult.zipCode + ')' : ''
                  }` ?? '',
              };
            }}
            minLengthToSearch={1}
            onSearch={(keyword: string) => {
              throttledFetchCityList(keyword.split(' (')[0]);
            }}
            searchResults={cityListMutation.data ?? []}
            onChange={item => {
              setSelectedCity(item);
              setSelectedAddress({
                address: selectedAddress?.address,
                zipCode: item?.zipCode ?? '',
                cityName: item?.name ?? '',
                inseeCode: item?.insee ?? '',
              });
            }}
            fetchStatus={toFetchStatus(cityListMutation)}
          />
        </div>
      </WithLightTitle>
    </div>
  );
};

export default AddressSelection;
