import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { Bonus, BonusesService, CreateBonusBody } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

const useCreateBonus = (extraOptions?: UseMutationOptions<Bonus, unknown, CreateBonusBody>) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.createBonus],
    async (body: CreateBonusBody) => {
      return BonusesService.bonusesControllerCreateBonus({ body });
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries(QueryKeys.fetchBonuses);
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};

export default useCreateBonus;
