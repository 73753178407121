import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {
  Button,
  FetchButton,
  Modal,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Experience, PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useRef, useState } from 'react';

import { ExperienceSelectionSchema } from 'src/Containers/Profile/Curriculum/ExperienceSelection/ExperienceSelection.schema';
import { useCreateExperience } from 'src/Hooks/Experiences/useCreateExperience';
import { toFetchStatus } from 'src/Services/Async/index';

import styles from './ExperienceSelection.module.scss';
import { Props } from './types';

const marks: { value: number; label: string }[] = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 7, label: 'de 5 à 10' },
  { value: 10, label: 'de 10 à 15' },
  { value: 13, label: 'plus de 15' },
];

const valuetext = (value: number) => {
  return marks.find(mark => mark.value === value)?.label ?? '';
};
export const ExperienceSelectionComponent = ({ positionBriefId }: Props) => {
  const ref = useRef<PopupActions>(null);

  const createExperience = useCreateExperience();
  const experienceCreationFetchStatus = toFetchStatus(createExperience);

  const [experienceValue, setExperienceValue] = useState<number>(0);

  const { control, setValue, handleSubmit, watch } = useFormWithZodResolver({
    schema: ExperienceSelectionSchema,
    defaultValues: {
      experience: '',
    },
  });

  const experienceResolver = watch('experience');

  const reset = useCallback(() => {
    setValue('experience', '');
    setExperienceValue(0);
  }, [setValue]);

  const onSave = useCallback(
    () =>
      handleSubmit(data => {
        createExperience.mutate({
          name: data.experience,
          positionBriefId,
          value: experienceValue === 7 ? 5 : experienceValue, // Value must match EDP ones
        });
      }),
    [createExperience, experienceValue, handleSubmit, positionBriefId]
  );

  return (
    <Modal
      ref={ref}
      trigger={
        <Button.Tertiary className={styles.container}>
          <PlusCircle />
          <div className={styles.spacing}>ajouter une expérience</div>
        </Button.Tertiary>
      }
      title={`nouvelle expérience`}
      onClose={() => ref.current?.close()}
      icon={<Experience />}
      iconClassName={styles.modalIcon}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            reset();
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={() => ref.current?.close()}>annuler</Button.Secondary>,
        <FetchButton
          onClick={onSave()}
          fetchStatus={experienceCreationFetchStatus}
          title="valider"
          onSuccess={() => {
            reset();
            ref.current?.close();
          }}
          disabled={!experienceResolver.length}
        />,
      ]}
    >
      <>
        <WithLightTitle title={"nom de l'expérience"} className={styles.spaceBottom}>
          <TextInput name="experience" placeholder="ex: suite office" control={control} />
        </WithLightTitle>
        <WithLightTitle title={"durée de l'exercice en années"}>
          <Box sx={{ width: '100%' }}>
            <Slider
              min={0}
              max={14} // Since the title is centered on the value, I had to extend the slider to be able to see the label completely
              aria-label="Restricted values"
              defaultValue={0}
              value={experienceValue}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              onChange={event => setExperienceValue(event.target?.value ?? 0)}
              getAriaValueText={valuetext}
              step={null}
              valueLabelDisplay="off"
              marks={marks}
            />
          </Box>
        </WithLightTitle>
      </>
    </Modal>
  );
};
