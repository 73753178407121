import styles from 'src/Containers/Company/CompanyInformation/CompanyInformation.module.scss';
import { CompetitorParams, CompetitorStatus } from 'src/Services/API';

export const statusColorsMap: Record<CompetitorStatus, string> = {
  [CompetitorStatus.LEADER]: styles.icedBlue,
  [CompetitorStatus.MINEUR]: styles.flashyGreen,
  [CompetitorStatus.OUTSIDER]: styles.yellowFull,
};

const competitorSortMapping = {
  [CompetitorStatus.LEADER]: 1,
  [CompetitorStatus.OUTSIDER]: 0,
  [CompetitorStatus.MINEUR]: -1,
};

export const sortCompetitors = <T extends CompetitorParams>(competitors: T[]) => {
  return competitors.sort((competitor1, competitor2) => {
    return competitorSortMapping[competitor2.status] - competitorSortMapping[competitor1.status];
  });
};
