import { YesNoUnknownEnum } from './yesNoUnknownEnum';

export const zodToDatabaseData = (data?: string) => {
  if (data === YesNoUnknownEnum.Yes) {
    return true;
  } else if (data === YesNoUnknownEnum.No) {
    return false;
  } else if (data === YesNoUnknownEnum.Unknown) {
    return undefined;
  }
};

export const databaseToZodData = (data?: boolean) => {
  if (data === true) {
    return YesNoUnknownEnum.Yes;
  } else if (data === false) {
    return YesNoUnknownEnum.No;
  } else {
    return YesNoUnknownEnum.Unknown;
  }
};
