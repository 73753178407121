import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { Variable, VariablesService, CreateVariableBody } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

const useCreateVariable = (
  extraOptions?: UseMutationOptions<Variable, unknown, CreateVariableBody>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.createVariable],
    async (body: CreateVariableBody) => {
      return VariablesService.variablesControllerCreateVariable({ body });
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries(QueryKeys.fetchVariables);
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};

export default useCreateVariable;
