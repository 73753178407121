import {
  ExclusiveSelectionGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { EnumSalaryFormat } from 'src/Services/API';

import { Props } from './SalaryFormatSelection.types';

const salaryFormatEnumMap = {
  [EnumSalaryFormat.FIX]: 'fixe',
  [EnumSalaryFormat.RANGE]: 'fourchette',
};

const SalaryFormatSelection = ({ setSelected, selected }: Props) => (
  <WithLightTitle title="format salaire">
    <ExclusiveSelectionGroup
      values={Object.values(EnumSalaryFormat)}
      canBeReset={false}
      getStringValue={(key: EnumSalaryFormat | null) => (key ? salaryFormatEnumMap[key] : '')}
      getKey={key => key ?? ''}
      setSelected={setSelected}
      selected={selected}
    />
  </WithLightTitle>
);

export default SalaryFormatSelection;
