import { useMutation, useQueryClient } from 'react-query';

import { SignaturesService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';
export const useEmptySignatures = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.emptySignatures],
    async (positionBriefId: number) => {
      await SignaturesService.signaturesControllerEmptySignatures({ positionBriefId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.fetchPositionBriefDetails);
      },
    }
  );
};
