import { useMutation, useQueryClient } from 'react-query';

import { DocumentsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useDeleteDocument = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.deleteDocument],
    async (params: { id: string }) => {
      const deletedDocument = await DocumentsService.documentsControllerDeleteDocument({
        id: params.id,
      });
      if (deletedDocument) queryClient.invalidateQueries('fetchCompanyDocuments');
      return deletedDocument;
    },
    {}
  );
};
