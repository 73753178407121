import {
  AddButton,
  Button,
  ExclusiveSelectionGroup,
  FetchButton,
  HorizontalCardWithTitle,
  Modal,
  PopupActions,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusCircle, Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  ToggleSwitch,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import { useCallback, useRef } from 'react';

import { BDPHeaderStep } from 'src/Assets';
import { useCreateRecruitmentStep } from 'src/Hooks/RecruitmentSteps/useCreateRecruitmentStep';
import { useDeleteRecruitmentStep } from 'src/Hooks/RecruitmentSteps/useDeleteRecruitmentStep';
import { useUpdateRecruitmentStep } from 'src/Hooks/RecruitmentSteps/useUpdateRecruitmentStep';
import { RecruitmentStepModality } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import styles from './RecruitmentStepModal.module.scss';
import { Props, RecruitmentStepValidationSchema } from './RecruitmentStepsModal.types';

const RecruitmentStepModalityEnumMap = {
  ON_SITE: 'présentiel',
  VIDEOCONFERENCE: 'visioconférence',
  UNKNOWN: 'je ne sais pas',
};

const RecruitmentStepModal = ({ positionBrief, creation, recruitmentStep, index }: Props) => {
  const ref = useRef<PopupActions>(null);
  const createRecruitmentStepMutation = useCreateRecruitmentStep();
  const updateRecruitmentStepMutation = useUpdateRecruitmentStep();
  const recruitmentStepCreateStatus = toFetchStatus(createRecruitmentStepMutation);
  const recruitmentStepUpdateStatus = toFetchStatus(updateRecruitmentStepMutation);

  const recruitmentStepZodResolver = useFormWithZodResolver({
    schema: RecruitmentStepValidationSchema,
    defaultValues: {
      name: creation ? '' : recruitmentStep?.name,
      recruitmentStepModality: creation
        ? RecruitmentStepModality.UNKNOWN
        : RecruitmentStepModality[
            recruitmentStep?.modality as keyof typeof RecruitmentStepModality
          ],
      specificTest: creation ? false : recruitmentStep?.specificTest,
      testDetails: creation ? '' : recruitmentStep?.testDetails,
      contactName: creation ? '' : recruitmentStep?.contactName,
    },
  });

  const onClose = () => {
    if (creation) {
      recruitmentStepZodResolver.reset();
    }
    ref.current?.close();
  };

  const recruitmentStepName = recruitmentStepZodResolver.watch('name');
  const modality = recruitmentStepZodResolver.watch('recruitmentStepModality');
  const specificTest = recruitmentStepZodResolver.watch('specificTest');
  const testDetails = recruitmentStepZodResolver.watch('testDetails');
  const contactName = recruitmentStepZodResolver.watch('contactName');
  const deleteRecruitmentStep = useDeleteRecruitmentStep();

  const recruitmentStepModalHandleSubmit = useCallback(
    () =>
      recruitmentStepZodResolver.handleSubmit(values => {
        creation
          ? createRecruitmentStepMutation.mutate({
              positionBrief: { id: positionBrief },
              recruitmentStep: {
                name: values.name,
                modality:
                  RecruitmentStepModality[
                    values.recruitmentStepModality as keyof typeof RecruitmentStepModality
                  ],
                specificTest: values.specificTest,
                testDetails: values.testDetails,
                contactName: values.contactName,
              },
            })
          : updateRecruitmentStepMutation.mutate({
              id: recruitmentStep?.id ?? 0,
              recruitmentStep: {
                name: values.name,
                modality:
                  RecruitmentStepModality[
                    values.recruitmentStepModality as keyof typeof RecruitmentStepModality
                  ],
                specificTest: values.specificTest,
                testDetails: values.testDetails,
                contactName: values.contactName,
                stepRank: recruitmentStep?.stepRank ?? 0,
              },
            });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      recruitmentStepZodResolver.handleSubmit,
      createRecruitmentStepMutation,
      updateRecruitmentStepMutation,
    ]
  );

  return (
    <Modal
      ref={ref}
      trigger={
        creation ? (
          <AddButton
            icon={<PlusCircle />}
            text="ajouter une étape de recrutement/d'entretien"
            className={styles.addButtonStep}
          />
        ) : (
          <HorizontalCardWithTitle
            titleClassname={styles.title}
            className={styles.recruitmentStepCard}
            title={`${(index ?? 0) + 1}. ${recruitmentStep?.name}`}
            draggable
            classNameLeftIcon={styles.recruitmentStepLeftIcon}
            leftSideClassName={styles.leftIconDrag}
            subtitles={[
              `${
                recruitmentStep?.modality === RecruitmentStepModality.ON_SITE
                  ? 'en présentiel'
                  : recruitmentStep?.modality === RecruitmentStepModality.VIDEOCONFERENCE
                  ? 'en visioconférence'
                  : 'type rencontre non connu'
              } | ${
                recruitmentStep?.specificTest ? 'avec test spécifique client' : 'pas de test client'
              } | avec ${recruitmentStep?.contactName}`,
            ]}
            rightActions={
              <Trashcan
                onClick={event => {
                  event.stopPropagation();
                  deleteRecruitmentStep.mutate(recruitmentStep?.id ?? 0);
                }}
              />
            }
          />
        )
      }
      icon={<BDPHeaderStep />}
      title={`${
        creation ? 'ajouter une étape de recrutement' : "mettre à jour l'étape de recrutement"
      }`}
      onClose={onClose}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            recruitmentStepZodResolver.reset();
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={() => onClose()}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={
            modality === undefined || recruitmentStepName.length === 0 || contactName.length === 0
          }
          onClick={recruitmentStepModalHandleSubmit()}
          fetchStatus={creation ? recruitmentStepCreateStatus : recruitmentStepUpdateStatus}
          onSuccess={() => ref.current?.close()}
        ></FetchButton>,
      ]}
    >
      <div className={styles.form}>
        <WithLightTitle title={"nom de l'étape"}>
          <TextInput
            value={recruitmentStepName}
            onChange={e =>
              recruitmentStepZodResolver.setValue('name', (e.target as HTMLInputElement).value)
            }
          />
        </WithLightTitle>
        <WithLightTitle title="cette étape se passe en..." className={styles.modalityContainer}>
          <ExclusiveSelectionGroup<RecruitmentStepModality | null>
            values={Object.values(RecruitmentStepModality)}
            canBeReset={false}
            getStringValue={key => (key ? RecruitmentStepModalityEnumMap[key] : '')}
            getKey={key => key ?? ''}
            selected={modality}
            setSelected={value =>
              recruitmentStepZodResolver.setValue(
                'recruitmentStepModality',
                value ?? RecruitmentStepModality.ON_SITE
              )
            }
          />
        </WithLightTitle>
        <div className={styles.formLine}></div>
        <div className={styles.toggleContainer}>
          <p>y a-t-il un test spécifique client à cette étape ?</p>
          <ToggleSwitch control={recruitmentStepZodResolver.control} name="specificTest" />
        </div>
        {specificTest && (
          <TextAreaWithLightTitle
            title="détails concernant le test"
            name="testDetails"
            counterClassName={styles.counter}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={testDetails.length}
            control={recruitmentStepZodResolver.control}
          />
        )}
        <WithLightTitle
          title={"nom de l'interlocuteur rencontré"}
          className={styles.contactContainer}
        >
          <TextInput
            value={contactName}
            placeholder="ex: Jean Dupont"
            onChange={e =>
              recruitmentStepZodResolver.setValue(
                'contactName',
                (e.target as HTMLInputElement).value
              )
            }
          />
        </WithLightTitle>
      </div>
    </Modal>
  );
};

export default RecruitmentStepModal;
