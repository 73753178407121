import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import throttle from 'lodash.throttle';
import React, { useMemo, useState } from 'react';

import { useFetchCompanies } from 'src/Hooks/Companies';
import { toFetchStatus } from 'src/Services/Async';

import styles from './CompanyPicker.module.scss';
import { Props } from './CompanyPicker.types';

const CompanyPicker = ({ onCompanyChange, className }: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchCompanies(keyword);
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);

  return (
    <div className={classnames(styles.container, className)}>
      <AutoSuggest
        id="companies"
        className={styles.autoSuggest}
        searchResults={data ?? []}
        onSearch={keyword => throttledSetKeyword(keyword)}
        withSubLabel
        keyValueExtractor={item => ({
          key: item.companyId ?? '',
          value: `${item.companyName} (${item.siret})`,
          subValue: `${item.companyAddress?.postalCode} - ${item.companyAddress?.city}`,
        })}
        onChange={items => {
          onCompanyChange(items[0]);
        }}
        minLengthToSearch={3}
        fetchStatus={toFetchStatus({ isSuccess, isError, isLoading })}
      />
    </div>
  );
};

export default CompanyPicker;
