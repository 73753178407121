import { ToggleCardGroup } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Alimentaire,
  Automobile,
  Autre,
  BTP,
  Hotellerie,
  Logistique,
  Pharmaceutique,
  Service,
  Finance,
  Industrie,
  Informatique,
  Public,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import { useFetchActivitySectors } from 'src/Hooks/Repositories/useFetchActivitySectors';
import { EnumActivitySectorKey } from 'src/Services/API';

import { Props } from './SelectActivitySector.types';

const sectorsIconMapping: Record<EnumActivitySectorKey, React.ReactNode> = {
  [EnumActivitySectorKey.AGRIFOOD]: <Alimentaire />,
  [EnumActivitySectorKey.AUTOMOTIVE]: <Automobile />,
  [EnumActivitySectorKey.CONSTRUCTION]: <BTP />,
  [EnumActivitySectorKey.FINANCE]: <Finance />,
  [EnumActivitySectorKey.HOTEL_AND_CATERING]: <Hotellerie />,
  [EnumActivitySectorKey.INDUSTRY]: <Industrie />,
  [EnumActivitySectorKey.IT]: <Informatique />,
  [EnumActivitySectorKey.LOGISTICS]: <Logistique />,
  [EnumActivitySectorKey.OTHER]: <Autre />,
  [EnumActivitySectorKey.PHARMA]: <Pharmaceutique />,
  [EnumActivitySectorKey.PUBLIC]: <Public />,
  [EnumActivitySectorKey.TRADES_AND_SERVICES]: <Service />,
};

const SelectActivitySector = ({ value, onChange }: Props) => {
  const fetchActivitySectors = useFetchActivitySectors();
  return (
    <ToggleCardGroup
      items={
        fetchActivitySectors.data
          ?.filter(sector => sector.key !== EnumActivitySectorKey.OTHER)
          .map(sector => ({
            label: sector.label,
            value: sector.id.toString(),
            icon: sectorsIconMapping[sector.key],
          })) ?? []
      }
      exclusive
      onChange={onChange}
      value={value ?? null}
    />
  );
};

export default SelectActivitySector;
