import { useQuery, UseQueryOptions } from 'react-query';

import { Variable, VariablesService } from 'src/Services/API';

import { QueryKeys } from '../types';

const useFetchVariables = (
  positionBrief: number,
  extraOptions?: UseQueryOptions<unknown, unknown, Variable[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchVariables, positionBrief.toString()],
    () => {
      return VariablesService.variablesControllerFetchVariables({ positionBrief });
    },
    {
      ...extraOptions,
    }
  );
};

export default useFetchVariables;
