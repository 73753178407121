import { useMutation, useQueryClient } from 'react-query';

import { RecruitmentStepsService, UpdateRecruitmentStepBody } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useUpdateRecruitmentStep = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateRecruitmentStep],
    async ({ id, recruitmentStep }: { id: number; recruitmentStep: UpdateRecruitmentStepBody }) => {
      const updatedRecruitmentStep = await RecruitmentStepsService.recruitmentStepsControllerUpdateRecruitmentStep(
        {
          id: id,
          body: recruitmentStep,
        }
      );

      return updatedRecruitmentStep;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchRecruitmentSteps');
      },
    }
  );
};
