export const bonusFrequencyAmountValues = [
  { id: 1, key: 'DAILY', label: 'quotidienne' },
  { id: 2, key: 'WEEKLY', label: 'hebdomadaire' },
  { id: 3, key: 'MONTHLY', label: 'mensuelle' },
  { id: 4, key: 'QUARTERLY', label: 'trimestrielle' },
  { id: 5, key: 'YEARLY', label: 'annuelle' },
  { id: 6, key: 'PER_KILOMETER', label: 'par kilomètre' },
];

export const timeBonusFrequencyAmountValues = [
  { id: 1, key: 'DAILY', label: 'quotidienne' },
  { id: 2, key: 'WEEKLY', label: 'hebdomadaire' },
  { id: 3, key: 'MONTHLY', label: 'mensuelle' },
  { id: 4, key: 'QUARTERLY', label: 'trimestrielle' },
  { id: 5, key: 'YEARLY', label: 'annuelle' },
];

export const bonusFrequencyPercentageValues = [
  { id: 1, key: 'REVENUE', label: "chiffre d'affaire" },
  { id: 2, key: 'MARGIN', label: 'marge' },
  { id: 3, key: 'PROGRESSION', label: 'progression' },
  { id: 4, key: 'SALARY', label: 'salaire' },
  { id: 5, key: 'OTHER', label: 'autre' },
];
