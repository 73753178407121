import React, { useState } from 'react';
import { useLocation, Router, Switch, useRouteMatch, Route } from 'react-router-dom';

import PrivateRoute from 'src/Components/PrivateRoute';
import { useCheckActivePerimeter } from 'src/Hooks/Perimeter';
import {
  CompanyResultRoute,
  CompanyResultState,
  LocationState,
  PositionBriefRoute,
} from 'src/Services/Routing';
import history from 'src/Utils/history';

import CompanyBriefs from '../CompanyBriefs';
import Home from '../Home';
import { ModalsContext } from '../Modals/ModalsContext/ModalsContext';
import PositionBriefModification from '../PositionBriefModification/PositionBriefModification.component';

import styles from './MainPage.module.scss';
import TopBar from './TopBar';

const companyRoute: CompanyResultRoute = {
  route: '/company/:companyId?',
  component: CompanyBriefs,
};

const positionBriefRoute: PositionBriefRoute = {
  route: '/company/:companyId?/brief/:positionBriefId/:firstSection?/:secondSection?',
  component: PositionBriefModification,
};

const MainPage = () => {
  useCheckActivePerimeter();
  const match = useRouteMatch();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const companyResultLocation = useLocation<CompanyResultState>();
  const companyResultLocationState = companyResultLocation.state;
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isSigningModalOpen, setIsSigningModalOpen] = useState(false);
  return (
    <div className={styles.container}>
      <TopBar />
      <Router history={history}>
        <Switch location={background || location}>
          <PrivateRoute exact path={match.path} component={Home} />
          <PrivateRoute
            exact
            path={companyRoute.route}
            children={
              <companyRoute.component
                routeParameters={companyResultLocationState?.routeParameters}
              />
            }
          />

          <ModalsContext.Provider
            value={{
              isExportModalOpen: isExportModalOpen,
              isSigningModalOpen: isSigningModalOpen,
              setIsExportModalOpen: setIsExportModalOpen,
              setIsSigningModalOpen: setIsSigningModalOpen,
            }}
          >
            <Route path={positionBriefRoute.route} component={PositionBriefModification} />
          </ModalsContext.Provider>
        </Switch>
      </Router>
    </div>
  );
};

export default MainPage;
