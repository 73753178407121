import { z } from 'zod';

import {
  CompanyHierarchy,
  EnumCompanyHierarchySize,
  RecruitmentStepModality,
} from 'src/Services/API';

export const HierarchySchemaStructure: z.ZodType<CompanyHierarchy> = z.lazy(() =>
  z.object({
    id: z.number(),
    title: z.string(),
    subtitle: z.string().optional(),
    children: z.array(HierarchySchemaStructure).optional(),
    size: z.nativeEnum(EnumCompanyHierarchySize).optional(),
  })
);

export const organisationSchema = z.object({
  hierarchy: z.array(HierarchySchemaStructure),
  recruitmentProcess: z.string().max(4000),
  recruitmentSteps: z
    .array(
      z.object({
        id: z.number(),
        modality: z.nativeEnum(RecruitmentStepModality),
        name: z.string(),
        specificTest: z.boolean(),
        testDetails: z.string(),
        contactName: z.string(),
        stepRank: z.number(),
      })
    )
    .optional(),
});
