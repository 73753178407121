import { useMutation, useQueryClient } from 'react-query';

import { CreateExperienceBody, ExperienceService } from 'src/Services/API/index';

import { MutationKeys, QueryKeys } from '../types';

export const useCreateExperience = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.createExperience],
    async (experience: CreateExperienceBody) => {
      const createdExperience = await ExperienceService.experienceControllerCreateExperience({
        body: experience,
      });

      return createdExperience;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.fetchExperiences);
      },
    }
  );
};
