import { useQuery } from 'react-query';

import { ExperienceService } from 'src/Services/API/index';

import { QueryKeys } from '../types';

export const useFetchExperiences = (id: number) => {
  return useQuery(
    [QueryKeys.fetchExperiences, id],
    async () => {
      const experiences = await ExperienceService.experienceControllerGetExperiences({ id });
      return experiences;
    },
    {
      staleTime: Infinity,
    }
  );
};
