export enum OtherAdvantages {
  VEHICULE_FUNCTION = 'Véhicule de fonction',
  VEHICULE_SERVICE = 'Véhicule de service',
  GAS_CARD = 'Carte essence',
  COMPUTER = 'Ordinateur',
  PHONE = 'Téléphone portable',
  STAFF_HOUSING = 'Logement de fonction',
  PERCO = 'PERCO',
  TRAVEL_FEES = 'Gestion des frais de déplacement',
  INCENTIVE_BONUS = 'Intéressement',
  ATTENDANCE_BONUS = 'Participation',
  HEALTH_CARE = 'Mutuelle d’entreprise',
}

export enum TravelFee {
  AVANCE = 'Avance sur frais',
  CARD = 'Carte bancaire entreprise',
}

export interface FrequencyUnitDurationTypes {
  key: string;
  label: string;
}
