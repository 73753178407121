import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { useFetchCompanyContactsFromURL } from 'src/Hooks/Companies/useFetchCompanyContactsFromURL';
import { toFetchStatus } from 'src/Services/Async';
import nullify from 'src/Utils/nullify';

import { Props } from './ManagerPicker.types';

const ContactPicker = ({
  onChange,
  customId,
  selectedContactId,
  isBriefSigned,
  setIsEditAfterSigningModalOpen,
}: Props) => {
  const { data, isSuccess, isError, isLoading } = useFetchCompanyContactsFromURL();

  return (
    <div
      onMouseDown={() => {
        if (isBriefSigned) {
          setIsEditAfterSigningModalOpen(true);
        }
      }}
    >
      <ComboBox
        id={customId ?? 'contact'}
        searchResults={data ?? []}
        useNativeOptionFiltering
        keyValueExtractor={contact => ({
          key: contact.contactId ?? '',
          value: [contact.name, contact.firstName].filter(item => item !== undefined).join(' '),
        })}
        onChange={nullify(onChange)}
        fetchStatus={toFetchStatus({ isSuccess, isError, isLoading })}
        value={data?.find(item => item.contactId === selectedContactId) ?? null}
        placeholder="nom et prénom de la personne"
      />
    </div>
  );
};

export default ContactPicker;
