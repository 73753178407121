import { UseMutationOptions, useQueryClient, useMutation } from 'react-query';

import { PositionBrief, PositionBriefsService, PositionBriefsWithCount } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdatePositionBriefName = (
  id?: number,
  extraOptions?: UseMutationOptions<PositionBrief, unknown, { newName: string }>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.updatePositionBriefName],
    async (params: { newName: string }) => {
      if (id === undefined) {
        return Promise.reject('This BDP does not have an id');
      }
      const updatedNamePositionBrief = await PositionBriefsService.positionBriefsControllerRenamePositionStudyBrief(
        {
          id: id,
          name: params.newName,
        }
      );

      return updatedNamePositionBrief;
    },
    {
      onSuccess: (...args) => {
        queryClient.setQueriesData(
          { queryKey: QueryKeys.fetchPositionBriefs, exact: false },
          (oldData: { pages: PositionBriefsWithCount[] } | undefined) => {
            return {
              pages:
                oldData?.pages.map(page => {
                  if (id && page.positionBriefs.map(brief => brief.id).includes(id)) {
                    const briefIndex = page.positionBriefs.findIndex(brief => brief.id === id);
                    const newPositionBriefs = [...page.positionBriefs];
                    newPositionBriefs[briefIndex] = {
                      ...newPositionBriefs[briefIndex],
                      name: args[1].newName,
                    };
                    return { count: page.count, positionBriefs: newPositionBriefs };
                  } else {
                    return page;
                  }
                }) ?? [],
            };
          }
        );
        //queryClient.invalidateQueries('fetchPositionStudies');
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
