import {
  useFormWithZodResolver,
  TextAreaWithLightTitle,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { sanitizeString } from 'src/Utils/sanitizeString';

import styles from './Strategy.module.scss';
import { StrategySchema } from './Strategy.schema';

const Strategy = () => {
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const intPositionBriefIdTo = parseInt(positionBriefId);
  const { data, isSuccess, isError } = useFetchPositionBriefDetails(intPositionBriefIdTo);
  const updateMutation = useUpdatePositionBrief();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: StrategySchema,
    defaultValues: {
      hrProjects: data?.companyDetails?.hrProjects ?? '',
      investmentProjects: data?.companyDetails?.investmentProjects ?? '',
    },
  });

  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  const isBriefSigned = useMemo(() => {
    return data?.signatures !== null;
  }, [data]);

  const hrProjects = watch('hrProjects');
  const investmentProjects = watch('investmentProjects');

  const strategyHandleSubmit = () =>
    handleSubmit(values => {
      if (!isBriefSigned) {
        updateMutation.mutate({
          id: intPositionBriefIdTo,
          modification: {
            changes: {
              companyDetails: {
                hrProjects: sanitizeString(values.hrProjects),
                investmentProjects: sanitizeString(values.investmentProjects),
              },
            },
          },
        });
      }
    });

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue('hrProjects', data?.companyDetails?.hrProjects ?? '');
      setValue('investmentProjects', data?.companyDetails?.investmentProjects ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hrProjects, investmentProjects]);

  return (
    <div>
      <h2 className={styles.strategySubtitle}>pratiques / chantiers RH</h2>
      {isSuccess && (
        <TextAreaWithLightTitle
          title="quelles pratiques ou chantiers RH sont en perspective ?"
          name="hrProjects"
          counterClassName={styles.charCountLabel}
          textAreaClassName={styles.textArea}
          placeholder="en quelques mots..."
          maxLength={4000}
          valueLength={hrProjects.length}
          control={control}
          onBlurCapture={strategyHandleSubmit()}
        />
      )}
      <div className={styles.separator} />
      <h2 className={styles.strategySubtitle}>projet d'investissement</h2>
      {isSuccess && (
        <TextAreaWithLightTitle
          title="des projets d'investissement prévus ?"
          name="investmentProjects"
          counterClassName={styles.charCountLabel}
          textAreaClassName={styles.textArea}
          placeholder="en quelques mots..."
          maxLength={4000}
          valueLength={investmentProjects.length}
          control={control}
          onBlurCapture={strategyHandleSubmit()}
        />
      )}
      <EditAfterSigningModal
        isOpen={isEditAfterSigningModalOpen}
        setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
      />
      {isError && (
        <ErrorMessage message="Une erreur est survenue dans la récupération de la stratégie de la société" />
      )}
    </div>
  );
};

export default Strategy;
