import React from 'react';

import { AppIllus } from 'src/Assets/index';

import Apps from './Apps';
import ParametersPopOverMenu from './ParametersPopOverMenu';
import styles from './TopBar.module.scss';

const TopBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <AppIllus className={styles.appLogo} />
        <div className={styles.separator} />
        <h1 className={styles.title}>brief de poste</h1>
      </div>
      <div className={styles.subContainer}>
        <Apps />
        <ParametersPopOverMenu />
      </div>
    </div>
  );
};
export default TopBar;
