import {
  Button,
  DropDown,
  FetchButton,
  Modal,
  SignatureWithChoice,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { IllusBDPSigning } from 'src/Assets';
import { useFetchAgencyConsultants } from 'src/Hooks/Agencies/useFetchAgencyConsultants';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { useFetchCompanyContactsFromURL } from 'src/Hooks/Companies/useFetchCompanyContactsFromURL';
import useFetchKeyPersons from 'src/Hooks/KeyPersons/useFetchKeyPersons';
import { useUploadSignatures } from 'src/Hooks/Signatures/useUploadSignatures';
import { EnumKeyPersonKeyPersonRole } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';

import { ModalsContext } from '../ModalsContext/ModalsContext';

import styles from './SigningModal.module.scss';
import { SigningModalSchema } from './SigningModal.schema';
import { Props } from './SigningModal.types';

const SigningModal = ({ isOpen }: Props) => {
  const modalsContext = useContext(ModalsContext);
  const { givenName, familyName, siid } = useAuthenticatedUser();
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const fetchSelectedKeyPersons = useFetchKeyPersons(parseInt(positionBriefId));
  const uploadMutation = useUploadSignatures();
  const uploadFetchStatus = toFetchStatus(uploadMutation);
  const { data: clientContacts, isSuccess: clientsFetchSuccess } = useFetchCompanyContactsFromURL();
  const { data: randstadContacts } = useFetchAgencyConsultants();
  const decisionMaker = clientContacts?.find(
    contact =>
      contact.contactId ===
      fetchSelectedKeyPersons.data?.find(selectedKeyPerson =>
        selectedKeyPerson.keyPersonRole.includes(EnumKeyPersonKeyPersonRole.DECISION_MAKER_NAME)
      )?.keyPersonId
  );

  const { control, setValue, reset } = useFormWithZodResolver({
    schema: SigningModalSchema,
    defaultValues: {
      randstadSigningPerson: siid
        ? { personName: `${givenName} ${familyName}`, id: siid }
        : undefined,
      clientSigningPerson:
        decisionMaker !== undefined
          ? {
              personName: `${decisionMaker.firstName} ${decisionMaker.name}`,
              id: decisionMaker.contactId,
            }
          : undefined,
    },
  });

  const randstadSigningPerson = useController({ control, name: 'randstadSigningPerson' });
  const clientSigningPerson = useController({ control, name: 'clientSigningPerson' });
  const randstadConsultantSignature = useController({
    control,
    name: 'randstadConsultantSignature',
  });
  const clientSignature = useController({ control, name: 'clientSignature' });

  useEffect(() => {
    if (siid !== undefined && fetchSelectedKeyPersons.isSuccess && clientsFetchSuccess) {
      setValue(
        'clientSigningPerson',
        decisionMaker !== undefined
          ? {
              personName: `${decisionMaker?.firstName} ${decisionMaker?.name}`,
              id: decisionMaker?.contactId,
            }
          : undefined
      );
      setValue('randstadSigningPerson', { personName: `${givenName} ${familyName}`, id: siid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siid, fetchSelectedKeyPersons.isSuccess, clientsFetchSuccess, isOpen]);

  const [randstadConsultantKey, setRandstadConsultantKey] = useState(Math.random());
  const [clientKey, setClientKey] = useState(Math.random());

  const handleSaveAndValidate = async () => {
    const randstadBlob = randstadConsultantSignature.field.value;
    let randstadFile;
    let clientFile;
    if (randstadBlob) {
      const res: Response = await fetch(randstadBlob);
      const blob: Blob = await res.blob();
      randstadFile = new File([blob], 'randstad_signing_person_signature.png', {
        type: 'image/png',
      });
    }

    const clientBlob = clientSignature.field.value;
    if (clientBlob) {
      const res: Response = await fetch(clientBlob);
      const blob: Blob = await res.blob();
      clientFile = new File([blob], 'client_signing_person_signature.png', {
        type: 'image/png',
      });
    }

    uploadMutation.mutate({
      randstadFile: randstadFile,
      clientFile: clientFile,
      positionBriefId: positionBriefId,
      randstadSigningPersonId: randstadSigningPerson.field.value?.id ?? '',
      randstadSigningPersonName: randstadSigningPerson.field.value?.personName ?? '',
      clientSigningPersonId: clientSigningPerson.field.value?.id ?? '',
      clientSigningPersonName: clientSigningPerson.field.value?.personName ?? '',
    });
  };

  const clearAllFields = () => {
    setValue('randstadConsultantSignature', undefined);
    setValue('clientSignature', undefined);
    setValue('randstadSigningPerson', undefined);
    setValue('clientSigningPerson', undefined);
    setRandstadConsultantKey(Math.random());
    setClientKey(Math.random());
  };

  const onClose = () => {
    reset();
    modalsContext.setIsSigningModalOpen(false);
  };

  return (
    <Modal
      title="signature"
      subtitle="En signant ce brief de poste, vous confirmez la validité des informations présentées. Toute future modification sur le brief de poste annulera cette validation signée."
      onClose={onClose}
      open={isOpen}
      icon={
        <div className={styles.modalIcon}>
          <IllusBDPSigning />
        </div>
      }
      footerActionsLeft={[
        <Button.Tertiary key="reset" onClick={clearAllFields}>
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary key="close" onClick={onClose}>
          fermer
        </Button.Secondary>,
        <FetchButton
          title="valider"
          key="validate"
          onClick={handleSaveAndValidate}
          fetchStatus={uploadFetchStatus}
          disabled={
            randstadSigningPerson.field.value?.id === undefined ||
            clientSigningPerson.field.value?.id === undefined ||
            randstadConsultantSignature.field.value === undefined ||
            randstadConsultantSignature.field.value.length === 0 ||
            clientSignature.field.value === undefined ||
            clientSignature.field.value?.length === 0
          }
          onSuccess={() => {
            modalsContext.setIsSigningModalOpen(false);
          }}
        />,
      ]}
    >
      <>
        <WithLightTitle title="signataire côté randstad">
          <DropDown
            items={
              randstadContacts?.map(
                contact =>
                  `${contact.firstName ?? contact.firstName} ${contact.name ?? contact.name}`
              ) ?? []
            }
            placeholder="nom d'un consultant Randstad"
            canBeReset={true}
            className={styles.dropDown}
            childrenClassName={styles.childDropdown}
            keyValueExtractor={(item: string) => {
              return { key: item, value: item };
            }}
            selectedItem={
              randstadSigningPerson.field.value?.personName
                ? randstadSigningPerson.field.value?.personName
                : "nom d'un consultant Randstad"
            }
            onSelectItem={(item: string) =>
              randstadSigningPerson.field.onChange({
                ...randstadSigningPerson.field.value,
                personName: item,
                id: randstadContacts?.find(
                  contact =>
                    `${contact.firstName ?? contact.firstName} ${contact.name ?? contact.name}` ===
                    item
                )?.id,
              })
            }
            disable={true}
          />
        </WithLightTitle>
        <SignatureWithChoice
          containerClassName={classnames(styles.signature)}
          size={{ width: 560, height: 210 }}
          key={randstadConsultantKey}
          onSignChange={image => {
            randstadConsultantSignature.field.onChange(image ?? undefined);
          }}
          onClear={() => {
            randstadConsultantSignature.field.onChange(undefined);
          }}
        />
        <WithLightTitle
          title="signataire côté client"
          className={styles.clientSigningPersonContainer}
        >
          <DropDown
            items={
              clientContacts?.map(
                contact =>
                  `${contact.firstName ?? contact.firstName} ${contact.name ?? contact.name}`
              ) ?? []
            }
            placeholder="nom d'un interlocuteur"
            canBeReset={true}
            className={styles.dropDown}
            childrenClassName={styles.childDropdown}
            keyValueExtractor={(item: string) => {
              return { key: item, value: item };
            }}
            selectedItem={
              clientSigningPerson.field.value?.personName
                ? clientSigningPerson.field.value?.personName
                : "nom d'un interlocuteur"
            }
            onSelectItem={(item: string) =>
              clientSigningPerson.field.onChange({
                ...clientSigningPerson.field.value,
                personName: item,
                id: clientContacts?.find(
                  contact =>
                    `${contact.firstName ?? contact.firstName} ${contact.name ?? contact.name}` ===
                    item
                )?.contactId,
              })
            }
          />
        </WithLightTitle>
        <SignatureWithChoice
          containerClassName={classnames(styles.signature)}
          size={{ width: 560, height: 210 }}
          key={clientKey}
          onSignChange={image => {
            clientSignature.field.onChange(image ?? undefined);
          }}
          onClear={() => {
            clientSignature.field.onChange(undefined);
          }}
        />
      </>
    </Modal>
  );
};

export default SigningModal;
