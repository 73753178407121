import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { useAuth } from 'src/Hooks/Authentication/useAuth';

const defaultOnRedirecting = (): JSX.Element => <></>;

const defaultReturnTo = (): string => `${window.location.pathname}${window.location.search}`;

export const ProtectedRoute = ({
  Component,
  ...args
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth();
  const {
    returnTo = defaultReturnTo,
    onRedirecting = defaultOnRedirecting,
    loginOptions = {},
  } = {};

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...loginOptions.appState,
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect, loginOptions, returnTo]);
  return (
    <Route
      render={_ => {
        if (!isAuthenticated) {
          return onRedirecting();
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Component {..._} />;
      }}
      {...args}
    />
  );
};

export default ProtectedRoute;
