import { GoBackArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCompanyDetails } from 'src/Hooks/Companies';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CompanyResultState } from 'src/Services/Routing';

import BriefCreationModal from '../Modals/BriefCreationModal';

import styles from './CompanyBriefs.module.scss';
import PositionBriefList from './PositionBriefList/PositionBriefList.component';

const CompanyBriefs = () => {
  const history = useHistory();
  const location = useLocation<CompanyResultState>();
  const currentBrand = useSelector(getCurrentBrand);

  const { companyId } = useParams<{ companyId: string }>();
  let companyName = location.state?.routeParameters?.companyName;

  const { isLoading, isError, data } = useFetchCompanyDetails(companyId, {
    enabled: companyName === undefined && currentBrand !== undefined,
  });

  companyName = companyName ?? data?.companyName;

  return (
    <div className={styles.container}>
      <div
        className={styles.goBack}
        onClick={() => {
          history.push('/');
        }}
      >
        <div className={styles.goBackArrowButton}>
          <GoBackArrow />
        </div>
        <div>accueil</div>
      </div>
      <div className={styles.titleBar}>
        <div className={styles.companyNameContainer}>
          {companyName && <p className={styles.companyName}>{companyName}</p>}

          {!companyName && isLoading && (
            <ContentLoader
              height="4rem"
              width="100%"
              uniqueKey="companyName"
              backgroundColor="#d7d7d7"
            >
              <rect x="0%" y="30" rx="4" ry="4" width="50%" height="50" />
            </ContentLoader>
          )}

          {!companyName && isError && (
            <ErrorMessage message="Une erreur est survenue lors de la récupération du nom du compte" />
          )}
        </div>
        <BriefCreationModal companyId={companyId} companyName={companyName} />
      </div>
      <PositionBriefList companyId={companyId} />
    </div>
  );
};

export default CompanyBriefs;
