import { useMutation, useQueryClient } from 'react-query';

import { ExperienceService } from 'src/Services/API/index';

import { MutationKeys, QueryKeys } from '../types';

export const useRemoveExperience = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.removeExperience],
    async (experienceId: number) => {
      return await ExperienceService.experienceControllerRemoveExperience({
        id: experienceId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.fetchExperiences);
      },
    }
  );
};
