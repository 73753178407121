import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { useHistory } from 'react-router';

import { HomePagePeople } from 'src/Assets';
import CompanyPicker from 'src/Components/CompanyPicker';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';

import styles from './Home.module.scss';
import { LastPositionBriefs } from './LastPositionBriefs';

const Home = () => {
  const { givenName } = useAuthenticatedUser();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <HomePagePeople className={styles.icon} />
      <div className={styles.searchContainer}>
        <div className={styles.hello}>Bonjour {givenName} !</div>
        <div className={styles.title}>créer ou retrouver un brief de poste</div>
        <WithLightTitle title="de quel compte s'agit-il ?">
          <CompanyPicker
            className={styles.companyPicker}
            onCompanyChange={company =>
              history.push({
                pathname: `/company/${company.companyId}`,
                state: {
                  routeParameters: {
                    companyId: company.companyId,
                    companyName: company.companyName,
                  },
                },
              })
            }
          />
        </WithLightTitle>
      </div>
      <LastPositionBriefs />
    </div>
  );
};

export default Home;
