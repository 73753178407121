import { useQuery } from 'react-query';

import { PositionMotivesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchPositionMotives = (contractType?: string) => {
  return useQuery(
    [QueryKeys.fetchPositionMotives, contractType],
    async () => {
      return contractType
        ? PositionMotivesService.positionMotivesControllerGetPositionMotives({ contractType })
        : [];
    },
    { staleTime: 10 * 60 * 1000, cacheTime: 10 * 60 * 1000 }
  );
};
