import { PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useContext } from 'react';

import { TimeContext } from './../../../TimeContext';
import { WorkingHourItem, defaultWorkingHoursItem } from './WorkingHourItem';
import styles from './WorkingHours.module.scss';
import { Props } from './WorkingHours.types';

const WorkingHours = ({ isBriefSigned, setIsEditAfterSigningModalOpen }: Props) => {
  const timeContext = useContext(TimeContext);
  const timeWorkingHoursValue = timeContext.watch('timeWorkingHours');
  const disabled = timeWorkingHoursValue.length >= 4;

  return (
    <>
      <div>
        {timeWorkingHoursValue &&
          timeWorkingHoursValue.map((_, index) => (
            <WorkingHourItem
              key={index}
              index={index}
              className={styles.itemContainer}
              remove={index => {
                const newTimeWorkingHours = timeWorkingHoursValue;
                newTimeWorkingHours.splice(index, 1);
                timeContext.setValue('timeWorkingHours', newTimeWorkingHours);
                timeContext.positionInformationHandleSubmit()();
              }}
              isBriefSigned={isBriefSigned}
              setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
            />
          ))}
      </div>
      <button
        className={classnames(styles.addButton, { [styles.disabled]: disabled })}
        onClick={() => {
          if (isBriefSigned) {
            setIsEditAfterSigningModalOpen(true);
          } else {
            timeContext.setValue('timeWorkingHours', [
              ...timeWorkingHoursValue,
              defaultWorkingHoursItem,
            ]);
            timeContext.positionInformationHandleSubmit()();
          }
        }}
        disabled={disabled}
      >
        <PlusCircle
          className={classnames(styles.plusCircleIcon, { [styles.disabled]: disabled })}
        />
        <p>{disabled ? 'nombre maximal de créneaux atteint' : 'ajouter un créneau horaire'}</p>
      </button>
    </>
  );
};

export default WorkingHours;
