export function addOrRemove<T extends { id: number | string; label?: string }>(
  arr: T[],
  item: T
): T[] {
  const filtered = arr.filter(el => el.id !== item.id);

  if (filtered.length === arr.length) {
    return [...arr, item];
  }

  return filtered;
}
