import { EnumReportingFrequencyUnit } from 'src/Services/API';

const frequencyUnitEnumMap = {
  WEEKLY: 'semaine',
  MONTHLY: 'mois',
  YEARLY: 'an',
};

export function frequencyUnitEnumToKeyValue(
  frequencyUnit: EnumReportingFrequencyUnit
): { key: string; value: string } {
  return {
    key: frequencyUnit,
    value: frequencyUnitEnumMap[frequencyUnit],
  };
}
