import {
  Checkbox,
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { Diploma } from 'src/Assets';
import { EnumStudyLevelLevel, EnumStudyLevelPriority } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { FormationContext } from '../FormationContext';

import styles from './StudyLevelSelection.module.scss';
import { Props } from './StudyLevelSelection.types';
import StudyLevelSelectionBadge from './StudyLevelSelectionBadge';
import { findStudyLevelFromLevel, STUDY_LEVEL_MAPPING_TO_STRING } from './utils';

export const StudyLevelSelection = ({ isBriefSigned, setIsEditAfterSigningModalOpen }: Props) => {
  const formationContext = useContext(FormationContext);

  const selectedStudyLevels = formationContext.watch('studyLevels');

  const onClickCheckbox = (modifiedLevel: EnumStudyLevelLevel) => {
    formationContext.setValue(
      `studyLevels`,
      Object.keys(EnumStudyLevelLevel).map(basicLevel => {
        const basicStudyLevel = findStudyLevelFromLevel(
          basicLevel as EnumStudyLevelLevel,
          selectedStudyLevels
        );
        return {
          level: basicLevel as EnumStudyLevelLevel,
          selected:
            basicLevel === modifiedLevel
              ? !basicStudyLevel?.selected ?? true
              : basicStudyLevel?.selected ?? false,
          priority:
            basicLevel === modifiedLevel
              ? EnumStudyLevelPriority.UNKNOWN
              : basicStudyLevel?.priority ?? EnumStudyLevelPriority.UNKNOWN,
        };
      })
    );
    formationContext.curriculumHandleSubmit()();
  };

  return (
    <WithLightTitle
      className={styles.selectedContainer}
      title={"niveaux d'études"}
      rightTitleComponent={pluralFormat(
        selectedStudyLevels.filter(study => study.selected).length,
        'sélectionné'
      )}
    >
      {Object.keys(EnumStudyLevelLevel).map(currentLevel => {
        return (
          <div key={`div-${currentLevel}`}>
            <div className={styles.separator} key={`separator-${currentLevel}`} />
            <HorizontalTextIconCard
              containerClassName={classNames({
                [styles.selectedCard]: findStudyLevelFromLevel(
                  currentLevel as EnumStudyLevelLevel,
                  selectedStudyLevels
                )?.selected,
              })}
              key={currentLevel}
              label={STUDY_LEVEL_MAPPING_TO_STRING[currentLevel as EnumStudyLevelLevel]}
              leftIcon={
                <div className={styles.leftIcon}>
                  <div
                    className={styles.selectable}
                    onMouseDown={() => {
                      if (isBriefSigned) {
                        setIsEditAfterSigningModalOpen(true);
                      }
                    }}
                  >
                    <Checkbox
                      checked={
                        findStudyLevelFromLevel(
                          currentLevel as EnumStudyLevelLevel,
                          selectedStudyLevels
                        )?.selected
                      }
                      onChange={() => {
                        onClickCheckbox(currentLevel as EnumStudyLevelLevel);
                      }}
                    />
                  </div>
                  <div className={styles.diplomaIcon}>
                    <Diploma />
                  </div>
                </div>
              }
              rightComponent={
                findStudyLevelFromLevel(currentLevel as EnumStudyLevelLevel, selectedStudyLevels)
                  ?.selected && (
                  <div
                    onMouseDown={() => {
                      if (isBriefSigned) {
                        setIsEditAfterSigningModalOpen(true);
                      }
                    }}
                  >
                    <StudyLevelSelectionBadge currentLevel={currentLevel as EnumStudyLevelLevel} />
                  </div>
                )
              }
            />
          </div>
        );
      })}
      <div className={styles.separator} />
    </WithLightTitle>
  );
};
