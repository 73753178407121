import { z } from 'zod';

import { EnumStudyLevelLevel, EnumStudyLevelPriority } from 'src/Services/API';

export const CurriculumSchema = z.object({
  studyLevels: z.array(
    z.object({
      level: z.nativeEnum(EnumStudyLevelLevel),
      selected: z.boolean(),
      priority: z.nativeEnum(EnumStudyLevelPriority),
    })
  ),
  diplomas: z.string(),
  medicalSpecializationsIds: z.array(z.string()),
  languageIds: z.array(z.string()),
  languagesNotes: z.string(),
});
