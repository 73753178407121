import React from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { z } from 'zod';

import { positionInformationSchema } from './PositionInformation.schema';

export const TimeContext = React.createContext({
  control: {} as Control<z.infer<typeof positionInformationSchema>>,
  watch: ((_name: string) => null) as UseFormWatch<z.infer<typeof positionInformationSchema>>,
  setValue: ((_name: string, _value: unknown) => null) as UseFormSetValue<
    z.infer<typeof positionInformationSchema>
  >,
  positionInformationHandleSubmit: () => () => Promise.resolve(),
});
