export enum DISPLAY_MODE {
  LIST,
  GRID,
}

interface UserPreferencesState {
  cardDisplayMode: DISPLAY_MODE;
}

export default UserPreferencesState;
