import { useQuery } from 'react-query';

import { PositionBriefsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchPositionBriefDetails = (briefId: number) => {
  return useQuery(
    [QueryKeys.fetchPositionBriefDetails, briefId],
    async () => {
      const positionBrief = await PositionBriefsService.positionBriefsControllerGetPositionBriefById(
        {
          id: briefId,
        }
      );
      return positionBrief;
    },
    {
      staleTime: Infinity,
    }
  );
};
