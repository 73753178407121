import moment from 'moment';
import React from 'react';

import styles from './PositionBriefCard.module.scss';
import { Props } from './PositionBriefCard.types';

const PositionBriefCard = ({
  title,
  createdAt,
  updatedAt,
  service,
  onClick,
  rightActions,
}: Props) => (
  <div className={styles.container} onClick={onClick}>
    <div style={{ flex: 1 }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>
        <div>{service}</div>
        <div>
          {`date de création ${moment(createdAt).format('L')}, dernière modification ${moment(
            updatedAt
          ).format('L')}`}
        </div>
      </div>
    </div>
    {rightActions}
  </div>
);

export default PositionBriefCard;
