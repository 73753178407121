import { ComboBoxWithCheckbox } from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect, useState } from 'react';

import useFetchKeyPersons from 'src/Hooks/KeyPersons/useFetchKeyPersons';
import useUpdateKeyPersons from 'src/Hooks/KeyPersons/useUpdateKeyPersons';
import {
  CompanyContact,
  EnumKeyPersonKeyPersonRole,
  EnumUpdateKeyPersonBodyKeyPersonRole,
  UpdateKeyPersonBody,
} from 'src/Services/API';

import styles from './KeyPersonsPicker.module.scss';
import { KeyPersonsPickerSchema } from './KeyPersonsPicker.schema';
import { KeyPersonsDto, Props } from './KeyPersonsPicker.types';

const KeyPersonsPicker = ({
  briefId,
  companyContacts,
  fetchStatus,
  isBriefSigned,
  setIsEditAfterSigningModalOpen,
}: Props) => {
  const fetchSelectedKeyPersons = useFetchKeyPersons(briefId);
  const selectedKeyPersons = fetchSelectedKeyPersons.data ?? [];
  const updateKeyPersonsMutation = useUpdateKeyPersons();

  const { handleSubmit, setValue } = useFormWithZodResolver({
    schema: KeyPersonsPickerSchema,
    defaultValues: {
      keyPersons: selectedKeyPersons ?? [],
    },
  });

  const keyPersonsHandleSubmit = () =>
    handleSubmit(values => {
      briefId &&
        values?.keyPersons &&
        updateKeyPersonsMutation.mutate({
          positionBriefId: briefId,
          keyPersons: values.keyPersons
            .filter(keyPerson => keyPerson?.keyPersonRole !== undefined)
            .map(keyPerson => ({
              ...keyPerson,
              keyPersonRole: (keyPerson?.keyPersonRole as unknown) as EnumUpdateKeyPersonBodyKeyPersonRole[],
            })) as UpdateKeyPersonBody[],
        });
    });

  const submit = keyPersonsHandleSubmit();

  const refreshedChecked = () => {
    const directSuperior = selectedKeyPersons.find(selectedKeyPerson =>
      selectedKeyPerson.keyPersonRole.includes(EnumKeyPersonKeyPersonRole.DIRECT_SUPERIOR_NAME)
    );
    const recruitmentOfficer = selectedKeyPersons.find(selectedKeyPerson =>
      selectedKeyPerson.keyPersonRole.includes(EnumKeyPersonKeyPersonRole.RECRUITMENT_OFFICER_NAME)
    );
    const decisionMaker = selectedKeyPersons.find(selectedKeyPerson =>
      selectedKeyPerson.keyPersonRole.includes(EnumKeyPersonKeyPersonRole.DECISION_MAKER_NAME)
    );
    return {
      directSuperior: {
        id: directSuperior?.id,
        keyPersonId: directSuperior?.keyPersonId,
        keyPersonRole: [EnumKeyPersonKeyPersonRole.DIRECT_SUPERIOR_NAME],
        missionReports: directSuperior?.missionReports ?? false,
        recruitmentPoints: directSuperior?.recruitmentPoints ?? false,
      },
      recruitmentOfficer: {
        id: recruitmentOfficer?.id,
        keyPersonId: recruitmentOfficer?.keyPersonId,
        keyPersonRole: [EnumKeyPersonKeyPersonRole.RECRUITMENT_OFFICER_NAME],
        missionReports: recruitmentOfficer?.missionReports ?? false,
        recruitmentPoints: recruitmentOfficer?.recruitmentPoints ?? false,
      },
      decisionMaker: {
        id: decisionMaker?.id,
        keyPersonId: decisionMaker?.keyPersonId,
        keyPersonRole: [EnumKeyPersonKeyPersonRole.DECISION_MAKER_NAME],
        missionReports: decisionMaker?.missionReports ?? false,
        recruitmentPoints: decisionMaker?.recruitmentPoints ?? false,
      },
    };
  };

  const [checked, setChecked] = useState(refreshedChecked());

  const updateChecked = (newValue: KeyPersonsDto) => {
    setChecked(newValue);
    setValue('keyPersons', [
      newValue.directSuperior,
      newValue.recruitmentOfficer,
      newValue.decisionMaker,
    ]);
    submit();
  };

  useEffect(() => {
    setChecked(refreshedChecked());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeyPersons]);

  return (
    <>
      <div
        onMouseDown={() => {
          if (isBriefSigned) {
            setIsEditAfterSigningModalOpen(true);
          }
        }}
      >
        <ComboBoxWithCheckbox
          comboId={'direct-superior'}
          comboSearchResults={companyContacts}
          comboKeyValueExtractor={(contact: {
            contactId?: string;
            name?: string;
            firstName?: string;
          }) => ({
            key: contact.contactId ?? '',
            value: [contact.name, contact.firstName].filter(item => item !== undefined).join(' '),
          })}
          comboBoxPlaceholder={'choisir une personne...'}
          comboBoxOnChange={(contact: CompanyContact | null) => {
            if (contact !== null) {
              updateChecked({
                ...checked,
                directSuperior: {
                  ...checked.directSuperior,
                  keyPersonId: contact.contactId,
                  missionReports:
                    contact.contactId === checked.recruitmentOfficer.keyPersonId
                      ? checked.recruitmentOfficer.missionReports
                      : contact.contactId === checked.decisionMaker.keyPersonId
                      ? checked.decisionMaker.missionReports
                      : checked.directSuperior.missionReports,
                  recruitmentPoints:
                    contact.contactId === checked.recruitmentOfficer.keyPersonId
                      ? checked.recruitmentOfficer.missionReports
                      : contact.contactId === checked.decisionMaker.keyPersonId
                      ? checked.decisionMaker.recruitmentPoints
                      : checked.directSuperior.recruitmentPoints,
                },
              });
            } else {
              updateChecked({
                ...checked,
                directSuperior: {
                  ...checked.directSuperior,
                  missionReports: false,
                  recruitmentPoints: false,
                  keyPersonId: undefined,
                },
              });
            }
          }}
          comboBoxValue={
            companyContacts?.find(
              contact => contact.contactId === checked?.directSuperior?.keyPersonId
            ) ?? null
          }
          comboBoxFetchStatus={fetchStatus}
          comboBoxTitle={'nom du responsable +1'}
          checkboxesData={[
            {
              label: 'les dossiers de présentation',
              checked: checked.directSuperior.missionReports,
              onChange: () => {
                updateChecked({
                  ...checked,
                  directSuperior: {
                    ...checked.directSuperior,
                    missionReports: !checked.directSuperior.missionReports,
                  },
                  recruitmentOfficer: {
                    ...checked.recruitmentOfficer,
                    missionReports:
                      checked.directSuperior.keyPersonId === checked.recruitmentOfficer.keyPersonId
                        ? !checked.directSuperior.missionReports
                        : checked.recruitmentOfficer.missionReports,
                  },
                  decisionMaker: {
                    ...checked.decisionMaker,
                    missionReports:
                      checked.directSuperior.keyPersonId === checked.decisionMaker.keyPersonId
                        ? !checked.directSuperior.missionReports
                        : checked.decisionMaker.missionReports,
                  },
                });
              },
            },
            {
              label: 'progress report',
              checked: checked.directSuperior.recruitmentPoints,
              onChange: () => {
                updateChecked({
                  ...checked,
                  directSuperior: {
                    ...checked.directSuperior,
                    recruitmentPoints: !checked.directSuperior.recruitmentPoints,
                  },
                  recruitmentOfficer: {
                    ...checked.recruitmentOfficer,
                    recruitmentPoints:
                      checked.directSuperior.keyPersonId === checked.recruitmentOfficer.keyPersonId
                        ? !checked.directSuperior.recruitmentPoints
                        : checked.recruitmentOfficer.recruitmentPoints,
                  },
                  decisionMaker: {
                    ...checked.decisionMaker,
                    recruitmentPoints:
                      checked.directSuperior.keyPersonId === checked.decisionMaker.keyPersonId
                        ? !checked.directSuperior.recruitmentPoints
                        : checked.decisionMaker.recruitmentPoints,
                  },
                });
              },
            },
          ]}
          checkboxesTitle={'cette personne recevra: '}
          containerClassName={styles.comboBoxContainer}
        />
      </div>
      <div
        onMouseDown={() => {
          if (isBriefSigned) {
            setIsEditAfterSigningModalOpen(true);
          }
        }}
      >
        <ComboBoxWithCheckbox
          comboId={'recruitment-officer'}
          comboSearchResults={companyContacts}
          comboKeyValueExtractor={(contact: {
            contactId?: string;
            name?: string;
            firstName?: string;
          }) => ({
            key: contact.contactId ?? '',
            value: [contact.name, contact.firstName].filter(item => item !== undefined).join(' '),
          })}
          comboBoxPlaceholder={'choisir une personne...'}
          comboBoxOnChange={(contact: CompanyContact | null) => {
            if (contact !== null) {
              updateChecked({
                ...checked,
                recruitmentOfficer: {
                  ...checked.recruitmentOfficer,
                  keyPersonId: contact.contactId,
                  missionReports:
                    contact.contactId === checked.directSuperior.keyPersonId
                      ? checked.directSuperior.missionReports
                      : contact.contactId === checked.decisionMaker.keyPersonId
                      ? checked.decisionMaker.missionReports
                      : checked.recruitmentOfficer.missionReports,
                  recruitmentPoints:
                    contact.contactId === checked.directSuperior.keyPersonId
                      ? checked.directSuperior.recruitmentPoints
                      : contact.contactId === checked.decisionMaker.keyPersonId
                      ? checked.decisionMaker.recruitmentPoints
                      : checked.recruitmentOfficer.recruitmentPoints,
                },
              });
            } else {
              updateChecked({
                ...checked,
                recruitmentOfficer: {
                  ...checked.recruitmentOfficer,
                  keyPersonId: undefined,
                  missionReports: false,
                  recruitmentPoints: false,
                },
              });
            }
          }}
          comboBoxValue={
            companyContacts?.find(
              contact => contact.contactId === checked?.recruitmentOfficer?.keyPersonId
            ) ?? null
          }
          comboBoxFetchStatus={fetchStatus}
          comboBoxTitle={'nom du chargé de recrutement'}
          checkboxesData={[
            {
              label: 'les dossiers de présentation',
              checked: checked.recruitmentOfficer.missionReports,
              onChange: () => {
                updateChecked({
                  ...checked,
                  recruitmentOfficer: {
                    ...checked.recruitmentOfficer,
                    missionReports: !checked.recruitmentOfficer.missionReports,
                  },
                  directSuperior: {
                    ...checked.directSuperior,
                    missionReports:
                      checked.directSuperior.keyPersonId === checked.recruitmentOfficer.keyPersonId
                        ? !checked.recruitmentOfficer.missionReports
                        : checked.directSuperior.missionReports,
                  },
                  decisionMaker: {
                    ...checked.decisionMaker,
                    missionReports:
                      checked.decisionMaker.keyPersonId === checked.recruitmentOfficer.keyPersonId
                        ? !checked.recruitmentOfficer.missionReports
                        : checked.decisionMaker.missionReports,
                  },
                });
              },
            },
            {
              label: 'progress report',
              checked: checked.recruitmentOfficer.recruitmentPoints,
              onChange: () => {
                updateChecked({
                  ...checked,
                  recruitmentOfficer: {
                    ...checked.recruitmentOfficer,
                    recruitmentPoints: !checked.recruitmentOfficer.recruitmentPoints,
                  },
                  directSuperior: {
                    ...checked.directSuperior,
                    recruitmentPoints:
                      checked.directSuperior.keyPersonId === checked.recruitmentOfficer.keyPersonId
                        ? !checked.recruitmentOfficer.recruitmentPoints
                        : checked.directSuperior.recruitmentPoints,
                  },
                  decisionMaker: {
                    ...checked.decisionMaker,
                    recruitmentPoints:
                      checked.decisionMaker.keyPersonId === checked.recruitmentOfficer.keyPersonId
                        ? !checked.recruitmentOfficer.recruitmentPoints
                        : checked.decisionMaker.recruitmentPoints,
                  },
                });
              },
            },
          ]}
          checkboxContainerClassName={styles.recruitmentOfficerCheckbox}
          containerClassName={styles.comboBoxContainer}
        />
      </div>
      <div
        onMouseDown={() => {
          if (isBriefSigned) {
            setIsEditAfterSigningModalOpen(true);
          }
        }}
      >
        <ComboBoxWithCheckbox
          comboId={'decision-maker'}
          comboSearchResults={companyContacts}
          comboKeyValueExtractor={(contact: {
            contactId?: string;
            name?: string;
            firstName?: string;
          }) => ({
            key: contact.contactId ?? '',
            value: [contact.name, contact.firstName].filter(item => item !== undefined).join(' '),
          })}
          comboBoxPlaceholder={'choisir une personne...'}
          comboBoxOnChange={(contact: CompanyContact | null) => {
            if (contact !== null) {
              updateChecked({
                ...checked,
                decisionMaker: {
                  ...checked.decisionMaker,
                  keyPersonId: contact.contactId,
                  missionReports:
                    contact.contactId === checked.directSuperior.keyPersonId
                      ? checked.directSuperior.missionReports
                      : contact.contactId === checked.recruitmentOfficer.keyPersonId
                      ? checked.recruitmentOfficer.missionReports
                      : checked.decisionMaker.missionReports,
                  recruitmentPoints:
                    contact.contactId === checked.directSuperior.keyPersonId
                      ? checked.directSuperior.recruitmentPoints
                      : contact.contactId === checked.recruitmentOfficer.keyPersonId
                      ? checked.recruitmentOfficer.recruitmentPoints
                      : checked.decisionMaker.recruitmentPoints,
                },
              });
            } else {
              updateChecked({
                ...checked,
                decisionMaker: {
                  ...checked.decisionMaker,
                  keyPersonId: undefined,
                  missionReports: false,
                  recruitmentPoints: false,
                },
              });
            }
          }}
          comboBoxValue={
            companyContacts?.find(
              contact => contact.contactId === checked?.decisionMaker?.keyPersonId
            ) ?? null
          }
          comboBoxFetchStatus={fetchStatus}
          comboBoxTitle={'nom du décisionnaire sur le poste'}
          checkboxesData={[
            {
              label: 'les dossiers de présentation',
              checked: checked.decisionMaker.missionReports,
              onChange: () => {
                updateChecked({
                  ...checked,
                  decisionMaker: {
                    ...checked.decisionMaker,
                    missionReports: !checked.decisionMaker.missionReports,
                  },
                  directSuperior: {
                    ...checked.directSuperior,
                    missionReports:
                      checked.decisionMaker.keyPersonId === checked.directSuperior.keyPersonId
                        ? !checked.decisionMaker.missionReports
                        : checked.directSuperior.missionReports,
                  },
                  recruitmentOfficer: {
                    ...checked.recruitmentOfficer,
                    missionReports:
                      checked.decisionMaker.keyPersonId === checked.recruitmentOfficer.keyPersonId
                        ? !checked.decisionMaker.missionReports
                        : checked.recruitmentOfficer.missionReports,
                  },
                });
              },
            },
            {
              label: 'progress report',
              checked: checked.decisionMaker.recruitmentPoints,
              onChange: () => {
                updateChecked({
                  ...checked,
                  decisionMaker: {
                    ...checked.decisionMaker,
                    recruitmentPoints: !checked.decisionMaker.recruitmentPoints,
                  },
                  directSuperior: {
                    ...checked.directSuperior,
                    recruitmentPoints:
                      checked.decisionMaker.keyPersonId === checked.directSuperior.keyPersonId
                        ? !checked.decisionMaker.recruitmentPoints
                        : checked.directSuperior.recruitmentPoints,
                  },
                  recruitmentOfficer: {
                    ...checked.recruitmentOfficer,
                    recruitmentPoints:
                      checked.decisionMaker.keyPersonId === checked.recruitmentOfficer.keyPersonId
                        ? !checked.decisionMaker.recruitmentPoints
                        : checked.recruitmentOfficer.recruitmentPoints,
                  },
                });
              },
            },
          ]}
          checkboxContainerClassName={styles.decisionMakerCheckbox}
          containerClassName={styles.comboBoxContainer}
        />
      </div>
    </>
  );
};

export default KeyPersonsPicker;
