import { useQuery, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService, RecruitingMissionWithFormattedDate } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchRecruitingMissions = (
  companyId: string,
  extraOptions?: UseQueryOptions<
    RecruitingMissionWithFormattedDate[],
    unknown,
    RecruitingMissionWithFormattedDate[],
    string[]
  >
) => {
  const brandCode = useSelector(getCurrentBrand)?.brandCodeApiHeader ?? '';
  const agencyId = useSelector(getCurrentAgency) ?? '';

  return useQuery(
    [QueryKeys.fetchRecruitingMissions, agencyId, brandCode, companyId],
    async () => {
      if (agencyId === '' || brandCode === '') {
        throw new Error("l'agence ou la marque ne sont pas choisies'");
      }
      const result = await CompaniesService.companiesControllerGetCompanyRecruitingMissions({
        id: companyId,
        agencyId: agencyId,
        brandCode: brandCode,
      });
      return result;
    },
    {
      enabled: companyId !== undefined && companyId !== '',
      ...extraOptions,
    }
  );
};
