import { TextAreaWithLightTitle } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { MEDICAL_BRAND_CODES } from 'src/Utils/medicalBrandCodes';

import styles from './FormationSelection.module.scss';
import { Props } from './FormationSelection.types';
import { MedicalSpecializationsSelection } from './MedicalSpecializationsSelection';
import { StudyLevelSelection } from './StudyLevelSelection';

export const FormationSelection = ({
  diplomas,
  control,
  curriculumHandleSubmit,
  setIsEditAfterSigningModalOpen,
  isBriefSigned,
}: Props) => {
  const brand = useSelector(getCurrentBrand);

  return (
    <>
      <div className={styles.title}>formation</div>
      <StudyLevelSelection
        isBriefSigned={isBriefSigned}
        setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
      />
      <div className={styles.diplomasContainer}>
        <TextAreaWithLightTitle
          title="les diplômes et formations"
          name="diplomas"
          counterClassName={styles.charCountLabel}
          textAreaClassName={styles.textArea}
          placeholder="en quelques mots..."
          maxLength={4000}
          valueLength={diplomas.length}
          control={control}
          onBlurCapture={curriculumHandleSubmit()}
        />
      </div>
      {brand?.brandCodeApiHeader && MEDICAL_BRAND_CODES.includes(brand?.brandCodeApiHeader) && (
        <MedicalSpecializationsSelection />
      )}
    </>
  );
};
