import React from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { z } from 'zod';

import { CurriculumSchema } from '../Curriculum.schema';

export const FormationContext = React.createContext({
  control: {} as Control<z.infer<typeof CurriculumSchema>>,
  watch: ((_name: string) => null) as UseFormWatch<z.infer<typeof CurriculumSchema>>,
  setValue: ((_name: string, _value: unknown) => null) as UseFormSetValue<
    z.infer<typeof CurriculumSchema>
  >,
  curriculumHandleSubmit: () => () => Promise.resolve(),
});
