import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { FieldPath, FieldValues } from 'react-hook-form';

import { useFetchContractStatuses } from 'src/Hooks/Repositories/useFetchContractStatuses';

import { Props } from './ContractStatusesSelection.types';

const ContractStatusesSelection = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
}: Props<TFieldValues, TName>) => {
  const contractStatusesQuery = useFetchContractStatuses();

  return (
    <div>
      {contractStatusesQuery.isSuccess && (
        <WithLightTitle title="statut">
          <ExclusiveSelectionGroup
            values={contractStatusesQuery?.data}
            name={name}
            control={control}
            getSelectedFormValue={element => element?.id}
            canBeReset
            getSelectedValue={value => {
              return contractStatusesQuery?.data.find(({ id }) => id === value);
            }}
            getStringValue={({ label }) => label}
            getKey={({ key }) => key}
          />
        </WithLightTitle>
      )}
      {contractStatusesQuery.isLoading && (
        <ContentLoader height="5rem" width="100%" uniqueKey="contractStatusesSelectionLoader">
          <rect x="0%" y="5" rx="4" ry="4" width="80%" height="48" />
        </ContentLoader>
      )}
    </div>
  );
};

export default ContractStatusesSelection;
