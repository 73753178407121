import { UseQueryResult } from 'react-query';

import { Document } from 'src/Services/API';

export interface Props {
  companyId: string;
  rawDocuments: UseQueryResult<Document[], unknown>;
  setIsEditAfterSigningModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isBriefSigned?: boolean;
}

export enum UploadStatus {
  None,
  InProgress,
  Failed,
  Done,
}

export interface DropzoneItem {
  id?: string;
  file: File;
  subtitle?: string;
  status: UploadStatus;
}
