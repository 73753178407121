import { useQuery } from 'react-query';

import { RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDepartments = () => {
  return useQuery(
    [QueryKeys.fetchDepartments],
    async () => {
      return RepositoriesService.repositoriesControllerGetDepartments();
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
};
