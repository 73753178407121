import { useQuery } from 'react-query';

import { ContractTypesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchContractTypes = () => {
  return useQuery(
    [QueryKeys.fetchContractTypes],
    async () => {
      return ContractTypesService.contractTypesControllerGetContractTypes();
    },
    { staleTime: 10 * 60 * 1000, cacheTime: 10 * 60 * 1000 }
  );
};
