import { Button, ParametersPopup } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Gear,
  CircleRandstad,
  Document,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';

import { useModalWithLocation } from 'src/Hooks/Navigation';
import { useFetchPerimeter } from 'src/Hooks/Perimeter';
import { useFetchAllowedToExport } from 'src/Hooks/Perimeter/useAllowedToExport';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';

import styles from './ParametersPopOverMenu.module.scss';
import { Props } from './ParametersPopOverMenu.types';

const ParametersPopOverMenu = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const { data: perimeter } = useFetchPerimeter({});
  const canExportQuery = useFetchAllowedToExport();
  const canExport = canExportQuery.data?.includes(perimeter?.id ?? '');
  const canExportQueryFetchStatus = toFetchStatus(canExportQuery);
  const { open } = useModalWithLocation('perimeter-selection');
  const { open: openExport } = useModalWithLocation('export');
  return (
    // The props "keys" and "nested" are needed because Flash App is using olf version of reactjs popup
    // TODO: https://gitlab.com/randstad-lean-mobile-factory/build/flash/flash-web/-/issues/109 permet de ne plsu avoir besoin de ces props
    <ParametersPopup
      trigger={
        <div>
          <Button.Tertiary
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Gear title="gear" className={classnames({ [styles.openGearButtonIcon]: isOpened })} />
          </Button.Tertiary>
        </div>
      }
      position="bottom right"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      offsetX={16}
      version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
      children={
        <div>
          <Button.Tertiary
            className={styles.button}
            onClick={() => {
              open({});
            }}
          >
            <CircleRandstad className={styles.circleRandstad} />
            choix de vos unités
          </Button.Tertiary>
          {canExportQueryFetchStatus === FETCH_STATUS.PENDING && (
            <ContentLoader height="3.5rem" width="100%" uniqueKey="export">
              <rect x="0%" y="5" rx="4" ry="4" width="80%" height="48" />
            </ContentLoader>
          )}
          {canExportQueryFetchStatus === FETCH_STATUS.FULFILLED && canExport && (
            <Button.Tertiary
              className={styles.button}
              onClick={() => {
                openExport({});
              }}
            >
              <Document className={styles.circleRandstad} />
              export des bdp
            </Button.Tertiary>
          )}
        </div>
      }
      {...props}
    />
  );
};
export default ParametersPopOverMenu;
