import {
  TextAreaWithLightTitle,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { sanitizeString } from 'src/Utils/sanitizeString';

import styles from './Advantage.module.scss';
import { AdvantageSchema } from './Advantage.schema';

const Advantage = () => {
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const id = parseInt(positionBriefId);
  const positionBriefQuery = useFetchPositionBriefDetails(id);
  const positionBriefFetchStatus = toFetchStatus(positionBriefQuery);
  const updateMutation = useUpdatePositionBrief();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: AdvantageSchema,
    defaultValues: {
      corporateCulture: positionBriefQuery.data?.companyDetails?.corporateCulture ?? '',
      otherAdvantages: positionBriefQuery.data?.companyDetails?.otherAdvantages ?? '',
    },
  });

  const corporateCulture = watch('corporateCulture');
  const otherAdvantages = watch('otherAdvantages');

  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  const isBriefSigned = useMemo(() => {
    return positionBriefQuery.data?.signatures !== null;
  }, [positionBriefQuery.data]);

  const cultureHandleSubmit = () =>
    handleSubmit(values => {
      if (!isBriefSigned) {
        updateMutation.mutate({
          id,
          modification: {
            changes: {
              companyDetails: {
                corporateCulture: sanitizeString(values.corporateCulture),
                otherAdvantages: sanitizeString(values.otherAdvantages),
              },
            },
          },
        });
      }
    });

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue('corporateCulture', positionBriefQuery.data?.companyDetails?.corporateCulture ?? '');
      setValue('otherAdvantages', positionBriefQuery.data?.companyDetails?.otherAdvantages ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corporateCulture, otherAdvantages]);

  return (
    <div className={styles.container}>
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.title}>culture et valeurs de l'entreprise</div>
          <TextAreaWithLightTitle
            title="décrivez ce qui caractérise la culture et les valeurs de l'entreprise"
            name="corporateCulture"
            counterClassName={styles.counter}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={corporateCulture.length}
            control={control}
            onBlurCapture={cultureHandleSubmit()}
          />
          <TextAreaWithLightTitle
            title="autres informations concernant le contexte de l'entreprise"
            name="otherAdvantages"
            counterClassName={styles.counter}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={otherAdvantages.length}
            control={control}
            onBlurCapture={cultureHandleSubmit()}
          />
          <EditAfterSigningModal
            isOpen={isEditAfterSigningModalOpen}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des avantages de la société" />
      )}
    </div>
  );
};

export default Advantage;
