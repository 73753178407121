import { z } from 'zod';

export interface Props {
  companyId: string;
  companyName?: string;
}

export const briefCreationSchema = z.object({
  name: z
    .string()
    .min(1)
    .refine(
      value => value.split('').filter(caracter => caracter !== ' ').length !== 0,
      'Veuillez entrer autre chose que des espaces'
    ),
  qualification: z.object({ id: z.string().optional(), label: z.string().optional() }),
  company: z.object({ companyId: z.string(), companyName: z.string() }),
});
