import { useQuery, UseQueryOptions } from 'react-query';

import { Bonus, BonusesService } from 'src/Services/API';

import { QueryKeys } from '../types';

const useFetchBonuses = (
  positionBrief: number,
  extraOptions?: UseQueryOptions<unknown, unknown, Bonus[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchBonuses, positionBrief.toString()],
    () => {
      return BonusesService.bonusesControllerFetchBonuses({ positionBrief });
    },
    {
      ...extraOptions,
    }
  );
};

export default useFetchBonuses;
