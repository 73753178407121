/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class ConsultantsService {
  /**
   *
   */
  static consultantsControllerGetPerimeter(
    options: IRequestOptions = {}
  ): Promise<ConsultantPerimeter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/perimeter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static consultantsControllerGetAllowedToExport(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/canExport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  static companiesControllerGetCompanies(
    params: {
      /** requestBody */
      body?: GetCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanySearchResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyDetail(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyDetails> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyContacts(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyContact[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/contacts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyWorkingLocations(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyAddress[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/workingLocation';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyRecruitingMissions(
    params: {
      /**  */
      id: string;
      /**  */
      agencyId: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecruitingMissionWithFormattedDate[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/recruitingMissions';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencyId: params['agencyId'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QualificationsService {
  /**
   *
   */
  static qualificationsControllerGetQualifications(
    params: {
      /**  */
      societyCode?: string;
      /**  */
      brand?: string;
      /**  */
      keyword?: string;
      /**  */
      qualificationIds?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        societyCode: params['societyCode'],
        brand: params['brand'],
        keyword: params['keyword'],
        qualificationIds: params['qualificationIds'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PositionBriefsService {
  /**
   *
   */
  static positionBriefsControllerCreatePositionBrief(
    params: {
      /** requestBody */
      body?: CreatePositionBriefBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionBrief> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerFetchPositionBriefs(
    params: {
      /**  */
      companyId?: string;
      /**  */
      agencyId: string;
      /**  */
      brandCode: string;
      /**  */
      limit?: number;
      /**  */
      offset?: number;
      /**  */
      orderByCreationDate?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionBriefsWithCount> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        companyId: params['companyId'],
        agencyId: params['agencyId'],
        brandCode: params['brandCode'],
        limit: params['limit'],
        offset: params['offset'],
        orderByCreationDate: params['orderByCreationDate'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerDeletePositionBrief(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerUpdatePositionBrief(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: PatchPositionBriefBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionBrief> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerGetPositionBriefById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionBrief> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerDuplicatePositionBrief(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionBrief> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/{id}/duplicate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerRenamePositionStudyBrief(
    params: {
      /**  */
      id: number;
      /**  */
      name: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionBrief> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/{id}/name';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerLinkPositionBriefAndMission(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: LinkPositionBriefToMissionsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionBrief> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/{id}/missions';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerGetPositionBriefPdf(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetPositionBriefPdfQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/{id}/pdf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionBriefsControllerSendRecruitmentOfficerMail(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SendMailParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PositionBriefs/{id}/mail';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VariablesService {
  /**
   *
   */
  static variablesControllerCreateVariable(
    params: {
      /** requestBody */
      body?: CreateVariableBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Variable> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variables';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variablesControllerFetchVariables(
    params: {
      /**  */
      positionBrief: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Variable[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variables';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { positionBrief: params['positionBrief'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variablesControllerDeleteVariable(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variables/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variablesControllerUpdateVariables(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateVariablesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Variable> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variables/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompetitorsService {
  /**
   *
   */
  static competitorsControllerCreateCompetitors(
    params: {
      /** requestBody */
      body?: CreateCompetitorsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Competitor[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/competitors';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static competitorsControllerFetchCompetitors(
    params: {
      /**  */
      positionBriefId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Competitor[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/competitors';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { positionBriefId: params['positionBriefId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static competitorsControllerDeleteCompetitor(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/competitors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static competitorsControllerUpdateCompetitors(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateCompetitorsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Competitor> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/competitors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BonusesService {
  /**
   *
   */
  static bonusesControllerCreateBonus(
    params: {
      /** requestBody */
      body?: CreateBonusBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Bonus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bonuses';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bonusesControllerFetchBonuses(
    params: {
      /**  */
      positionBrief: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Bonus[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bonuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { positionBrief: params['positionBrief'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bonusesControllerDeleteBonus(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bonuses/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bonusesControllerUpdateBonus(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateBonusBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Bonus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bonuses/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RepositoriesService {
  /**
   *
   */
  static repositoriesControllerGetDepartments(
    options: IRequestOptions = {}
  ): Promise<Department[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/departments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetCities(
    params: {
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<City[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/cities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetRisks(options: IRequestOptions = {}): Promise<Risk[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/risks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetMedicalSpecializations(
    params: {
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MedicalSpecialization[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/medicalSpecializations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class KeyPersonsService {
  /**
   *
   */
  static keyPersonsControllerFetchKeyPersons(
    params: {
      /**  */
      positionBrief: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<KeyPerson[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/keyPersons';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { positionBrief: params['positionBrief'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static keyPersonsControllerCreateKeyPerson(
    params: {
      /** requestBody */
      body?: CreateKeyPersonBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<KeyPerson> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/keyPersons';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static keyPersonsControllerUpdateKeyPerson(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateKeyPersonBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/keyPersons/update/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static keyPersonsControllerUpdateKeyPersons(
    params: {
      /** requestBody */
      body?: UpdateKeyPersonsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/keyPersons/updateMany';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static keyPersonsControllerDeleteKeyPerson(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/keyPersons/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DocumentsService {
  /**
   *
   */
  static documentsControllerGetDocumentsByCompanyId(
    params: {
      /**  */
      companyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Document[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{companyId}';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentsControllerUploadDocument(
    params: {
      /**  */
      companyId: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Document> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{companyId}';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        data.append('file', params['file'] as any);
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentsControllerGetDocumentById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{id}/document';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentsControllerDeleteDocument(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Document> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExperienceService {
  /**
   *
   */
  static experienceControllerGetExperiences(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Experience[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/experience';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static experienceControllerCreateExperience(
    params: {
      /** requestBody */
      body?: CreateExperienceBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Experience> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/experience/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static experienceControllerRemoveExperience(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/experience/remove';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AgenciesService {
  /**
   *
   */
  static agenciesControllerGetAgencyConsultants(
    params: {
      /**  */
      agencyId: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Consultant[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/consultants';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencyId: params['agencyId'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RecruitmentStepsService {
  /**
   *
   */
  static recruitmentStepsControllerFetchRecruitmentSteps(
    params: {
      /**  */
      positionBriefId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecruitmentStepParams[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/recruitment-steps';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { positionBriefId: params['positionBriefId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static recruitmentStepsControllerCreateRecruitmentStep(
    params: {
      /** requestBody */
      body?: CreateRecruitmentStepBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecruitmentStep> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/recruitment-steps';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static recruitmentStepsControllerUpdateRecruitmentStep(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateRecruitmentStepBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecruitmentStep> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/recruitment-steps/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static recruitmentStepsControllerDeleteRecruitmentStep(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/recruitment-steps/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static recruitmentStepsControllerUpdateRecruitmentSteps(
    params: {
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/recruitment-steps/updateRecruitmentSteps';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActivitySectorsService {
  /**
   *
   */
  static activitySectorsControllerGetActivitySectors(
    options: IRequestOptions = {}
  ): Promise<ActivitySector[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/activity-sectors';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExportService {
  /**
   *
   */
  static exportControllerGetExportData(
    params: {
      /** requestBody */
      body?: GetInterviewsExportParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/export/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PositionMotivesService {
  /**
   *
   */
  static positionMotivesControllerGetPositionMotives(
    params: {
      /**  */
      contractType: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionMotive[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/position-motives/{contractType}';
      url = url.replace('{contractType}', params['contractType'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContractTypesService {
  /**
   *
   */
  static contractTypesControllerGetContractTypes(
    options: IRequestOptions = {}
  ): Promise<ContractType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contract-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContractStatusesService {
  /**
   *
   */
  static contractStatusesControllerGetContractStatuses(
    options: IRequestOptions = {}
  ): Promise<ContractStatus[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contract-statuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LanguagesService {
  /**
   *
   */
  static languagesControllerGetLanguages(options: IRequestOptions = {}): Promise<Language[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/languages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SignaturesService {
  /**
   *
   */
  static signaturesControllerUploadSignatures(
    params: {
      /**  */
      randstadFile: any;
      /**  */
      clientFile: any;
      /**  */
      positionBriefId: string;
      /**  */
      randstadSigningPersonName: string;
      /**  */
      randstadSigningPersonId: string;
      /**  */
      clientSigningPersonName: string;
      /**  */
      clientSigningPersonId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Signatures> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/signatures';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['randstadFile']) {
        data.append('randstadFile', params['randstadFile'] as any);
      }

      if (params['clientFile']) {
        data.append('clientFile', params['clientFile'] as any);
      }

      if (params['positionBriefId']) {
        data.append('positionBriefId', params['positionBriefId'] as any);
      }

      if (params['randstadSigningPersonName']) {
        data.append('randstadSigningPersonName', params['randstadSigningPersonName'] as any);
      }

      if (params['randstadSigningPersonId']) {
        data.append('randstadSigningPersonId', params['randstadSigningPersonId'] as any);
      }

      if (params['clientSigningPersonName']) {
        data.append('clientSigningPersonName', params['clientSigningPersonName'] as any);
      }

      if (params['clientSigningPersonId']) {
        data.append('clientSigningPersonId', params['clientSigningPersonId'] as any);
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static signaturesControllerEmptySignatures(
    params: {
      /**  */
      positionBriefId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/signatures/{positionBriefId}';
      url = url.replace('{positionBriefId}', params['positionBriefId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface OSMConsultantModelDtoBrandDto {
  /**  */
  code?: string;

  /**  */
  name?: string;
}

export interface OSMConsultantModelDtoSpecialityDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMConsultantModelDtoConsultantAgencyByCompany {
  /**  */
  societyId?: string;

  /**  */
  brand?: OSMConsultantModelDtoBrandDto;

  /**  */
  brandCodeApiHeader?: string;

  /**  */
  agencies?: string[];

  /**  */
  specialities?: OSMConsultantModelDtoSpecialityDto[];
}

export interface ConsultantPerimeter {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  branchId?: string;

  /**  */
  regionId?: string;

  /**  */
  zoneId?: string;

  /**  */
  defaultAgencyId?: string;

  /**  */
  defaultCompanyId?: string;

  /**  */
  companies?: OSMConsultantModelDtoConsultantAgencyByCompany[];
}

export interface GetCompaniesBody {
  /**  */
  keyword: string;

  /**  */
  agencies: string[];

  /**  */
  brandCode: string;
}

export interface OSMCompanyModelCompanyDtoCompanyAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;
}

export interface OSMCommonModelDateDto {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  day?: number;
}

export interface OSMCompanyModelSearchDtoVisitDto {
  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  endDate?: OSMCommonModelDateDto;
}

export interface OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CompanySearchResult {
  /**  */
  societyStatus?: string;

  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  lastVisit?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  hasFrameworkAgreement?: boolean;

  /**  */
  delegationPotential?: number;

  /**  */
  externalCode?: string;

  /**  */
  collectiveAgreement?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  timeTrackingTool?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  siret?: string;
}

export interface OSMCompanyModelCompanyDtoNafDto {
  /**  */
  ID?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoAgencyStrategyDto {
  /**  */
  ID?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoCollectiveAgreementDto {
  /**  */
  ID?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoEstablishmentTypeDto {
  /**  */
  ID?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoGetCompanyAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;
}

export interface OSMCompanyModelDtoCompanyAgencyPortfolioDto {
  /**  */
  code?: string;

  /**  */
  societyCode?: string;

  /**  */
  portfolioType?: string;
}

export interface OSMCompanyModelDtoCompanySectorDto {
  /**  */
  isPublic?: boolean;

  /**  */
  label?: string;
}

export interface OSMCompanyModelFrameworkAgreementDtoFrameworkAgreementDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface CompanyDetails {
  /**  */
  companyName?: string;

  /**  */
  siret?: string;

  /**  */
  NAF?: OSMCompanyModelCompanyDtoNafDto;

  /**  */
  acronym?: string;

  /**  */
  agencyStrategy?: OSMCompanyModelCompanyDtoAgencyStrategyDto;

  /**  */
  brandStrategy?: string;

  /**  */
  collectiveAgreement?: OSMCompanyModelCompanyDtoCollectiveAgreementDto;

  /**  */
  establishmentType?: OSMCompanyModelCompanyDtoEstablishmentTypeDto;

  /**  */
  portalFunction?: boolean;

  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoGetCompanyAddressDto;

  /**  */
  companyOtherAddress?: OSMCompanyModelCompanyDtoGetCompanyAddressDto[];

  /**  */
  agencyPortfolios?: OSMCompanyModelDtoCompanyAgencyPortfolioDto[];

  /**  */
  solvency?: string;

  /**  */
  companySector?: OSMCompanyModelDtoCompanySectorDto;

  /**  */
  status?: string;

  /**  */
  servicesUri?: string;

  /**  */
  contactsUri?: string;

  /**  */
  practicalInformation?: string;

  /**  */
  frameworkAgreement?: OSMCompanyModelFrameworkAgreementDtoFrameworkAgreementDto;

  /**  */
  phone?: string;

  /**  */
  workforce?: number;

  /**  */
  isConsultantContact?: boolean;
}

export interface CompanyContact {
  /**  */
  contactId?: string;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  position?: string;

  /**  */
  statut?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  isActiveOnCurrentCompany?: boolean;

  /**  */
  mainContact?: boolean;

  /**  */
  myContact?: boolean;

  /**  */
  dontMailMe?: boolean;

  /**  */
  dontCallMe?: boolean;

  /**  */
  dontPostMe?: boolean;

  /**  */
  dontPlaceMe?: boolean;

  /**  */
  portalAccess?: boolean;
}

export interface CompanyAddress {
  /**  */
  address?: string;

  /**  */
  cityName?: string;

  /**  */
  zipCode?: string;

  /**  */
  inseeCode?: string;
}

export interface OSMCompanyModelCommonDtoQualificationDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;
}

export interface OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface RecruitingMissionWithFormattedDate {
  /**  */
  missionId?: string;

  /**  */
  qualification?: OSMCompanyModelCommonDtoQualificationDto;

  /**  */
  agencyId?: string;

  /**  */
  numberPositions?: number;

  /**  */
  salesPhase?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  serviceType?: string;

  /**  */
  hasAttachedDocument?: boolean;

  /**  */
  commandDate?: Date;
}

export interface Qualification {
  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  shortLabel?: string;
}

export interface CreatePositionBriefBody {
  /**  */
  name: string;

  /**  */
  companyId: string;

  /**  */
  qualificationId: string;

  /**  */
  agencyId: string;

  /**  */
  brandCode: string;

  /**  */
  creatorUserId: string;
}

export interface ActivitySector {
  /**  */
  id: number;

  /**  */
  key: EnumActivitySectorKey;

  /**  */
  label: string;
}

export interface CompanyHierarchyChildren {}

export interface CompanyHierarchy {
  /**  */
  id: number;

  /**  */
  title: string;

  /**  */
  subtitle?: string;

  /**  */
  children?: CompanyHierarchyChildren;

  /**  */
  size?: EnumCompanyHierarchySize;
}

export interface PositionBriefCompanyDetails {
  /**  */
  id: number;

  /**  */
  groupName?: string;

  /**  */
  activitySector?: ActivitySector;

  /**  */
  corporateCulture?: string;

  /**  */
  otherAdvantages?: string;

  /**  */
  hierarchy?: CompanyHierarchy[];

  /**  */
  recruitmentProcess?: string;

  /**  */
  hrProjects?: string;

  /**  */
  investmentProjects?: string;
}

export interface PositionMotive {
  /**  */
  id: number;

  /**  */
  key: EnumPositionMotiveKey;

  /**  */
  label: string;

  /**  */
  contractTypes: EnumPositionMotiveContractTypes;
}

export interface ContractType {
  /**  */
  id: number;

  /**  */
  key: EnumContractTypeKey;

  /**  */
  label: string;
}

export interface ContractStatus {
  /**  */
  id: number;

  /**  */
  key: EnumContractStatusKey;

  /**  */
  label: string;
}

export interface PositionBriefPositionContextDetails {
  /**  */
  openPositionDurationUnit?: OpenPositionDurationUnit;

  /**  */
  id: number;

  /**  */
  tenurable?: boolean;

  /**  */
  recruitmentOpenPositionDuration?: number;

  /**  */
  positionMotive?: PositionMotive;

  /**  */
  contractType?: ContractType;

  /**  */
  contractStatus?: ContractStatus;

  /**  */
  trialPeriodDuration?: string;

  /**  */
  cddDuration?: number;

  /**  */
  recruitmentReason?: string;

  /**  */
  difficulties?: string;
}

export interface BasicItem {
  /**  */
  id: string;

  /**  */
  label?: string;
}

export interface PositionBriefEnvironmentAndMissionsDetails {
  /**  */
  id: number;

  /**  */
  missions?: string;

  /**  */
  hasManagement?: boolean;

  /**  */
  numberOfPeopleToManage?: number;

  /**  */
  managementSkills?: string;

  /**  */
  remoteManagement?: boolean;

  /**  */
  environments?: string;

  /**  */
  handicapFriendly?: boolean;

  /**  */
  hasRisks?: boolean;

  /**  */
  risks?: BasicItem[];

  /**  */
  otherRisks?: string;
}

export interface PositionBriefPositionGeneralInformation {
  /**  */
  id: number;

  /**  */
  services?: string;

  /**  */
  confidentialityClause?: boolean;

  /**  */
  mobilityClause?: boolean;

  /**  */
  managerId?: string;
}

export interface PositionBriefPositionMobility {
  /**  */
  remoteFrequencyUnit?: RemoteFrequencyUnit;

  /**  */
  id: number;

  /**  */
  remoteAccepted?: boolean;

  /**  */
  remoteDays?: number;

  /**  */
  remoteFrequencyValue?: number;

  /**  */
  hasBusinessTrips?: boolean;

  /**  */
  address?: CompanyAddress;

  /**  */
  mobilityAreas?: string[];

  /**  */
  nationalMobility?: boolean;
}

export interface HoursPerWeek {
  /**  */
  hours?: number;

  /**  */
  minutes?: number;
}

export interface WorkingHour {
  /**  */
  startTime?: string;

  /**  */
  endTime?: string;

  /**  */
  days?: EnumWorkingHourDays[];
}

export interface PositionBriefPositionTime {
  /**  */
  timePackage?: EnumTimePackage;

  /**  */
  id: number;

  /**  */
  timeDaysPerYear?: number;

  /**  */
  timeHoursPerWeek?: HoursPerWeek;

  /**  */
  timeWorkingHours?: WorkingHour[];

  /**  */
  timeRttCount?: number;

  /**  */
  timeOnCallDuty?: boolean;

  /**  */
  timeOnCallDutyDetails?: string;
}

export interface PositionBriefPositionSalary {
  /**  */
  salaryBasis?: EnumSalaryBasis;

  /**  */
  salaryFormat?: EnumSalaryFormat;

  /**  */
  id: number;

  /**  */
  minSalaryBasis?: number;

  /**  */
  maxSalaryBasis?: number;

  /**  */
  salaryMonthCount?: number;

  /**  */
  restaurantTickets?: boolean;

  /**  */
  positionAssets?: string;

  /**  */
  advantages?: string;

  /**  */
  healthCareValue?: string;

  /**  */
  incentiveBonusValue?: string;

  /**  */
  incentiveFrequency?: EnumPositionBriefPositionSalaryIncentiveFrequency;

  /**  */
  attendanceBonusValue?: string;

  /**  */
  attendanceFrequency?: EnumPositionBriefPositionSalaryAttendanceFrequency;
}

export interface StudyLevel {
  /**  */
  level: EnumStudyLevelLevel;

  /**  */
  selected: boolean;

  /**  */
  priority: EnumStudyLevelPriority;
}

export interface Language {
  /**  */
  id: number;

  /**  */
  key: EnumLanguageKey;

  /**  */
  label: string;
}

export interface Reporting {
  /**  */
  name: string;

  /**  */
  numberPerPeriod: number;

  /**  */
  frequencyUnit: EnumReportingFrequencyUnit;

  /**  */
  modalitiesDescription: string;
}

export interface PositionBriefProfileDetails {
  /**  */
  id: number;

  /**  */
  studyLevels?: StudyLevel[];

  /**  */
  diplomas?: string;

  /**  */
  medicalSpecializationsIds?: string[];

  /**  */
  languages?: Language[];

  /**  */
  languagesNotes?: string;

  /**  */
  technicalSkills?: string;

  /**  */
  behavioralSkills?: string;

  /**  */
  reportings?: Reporting[];
}

export interface PositionBrief {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  creatorUserId?: string;

  /**  */
  qualificationId?: string;

  /**  */
  companyId?: string;

  /**  */
  agencyId?: string;

  /**  */
  brandCode?: string;

  /**  */
  createdAt: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  recruitementMissionIds?: string[];

  /**  */
  companyDetails?: PositionBriefCompanyDetails;

  /**  */
  positionContextDetails?: PositionBriefPositionContextDetails;

  /**  */
  environmentAndMissionsDetails?: PositionBriefEnvironmentAndMissionsDetails;

  /**  */
  positionGeneralInformation?: PositionBriefPositionGeneralInformation;

  /**  */
  positionMobility?: PositionBriefPositionMobility;

  /**  */
  positionTime?: PositionBriefPositionTime;

  /**  */
  positionSalary?: PositionBriefPositionSalary;

  /**  */
  profileDetails?: PositionBriefProfileDetails;

  /**  */
  signatures?: Signatures;
}

export interface Signatures {
  /**  */
  id: number;

  /**  */
  positionBrief: PositionBrief;

  /**  */
  randstadSigningPersonName?: string;

  /**  */
  randstadSigningPersonId?: string;

  /**  */
  randstadSignatureOriginalName?: string;

  /**  */
  randstadSignatureSize?: number;

  /**  */
  randstadSignatureEncoding?: string;

  /**  */
  randstadSignatureMimetype?: string;

  /**  */
  randstadSignatureBuffer?: object;

  /**  */
  clientSigningPersonName?: string;

  /**  */
  clientSigningPersonId?: string;

  /**  */
  clientSignatureOriginalName?: string;

  /**  */
  clientSignatureSize?: number;

  /**  */
  clientSignatureEncoding?: string;

  /**  */
  clientSignatureMimetype?: string;

  /**  */
  clientSignatureBuffer?: object;

  /**  */
  createdAt: Date;

  /**  */
  updatedAt: Date;
}

export interface CompanyDetailsWithId {
  /**  */
  companyName?: string;

  /**  */
  siret?: string;

  /**  */
  NAF?: OSMCompanyModelCompanyDtoNafDto;

  /**  */
  acronym?: string;

  /**  */
  agencyStrategy?: OSMCompanyModelCompanyDtoAgencyStrategyDto;

  /**  */
  brandStrategy?: string;

  /**  */
  collectiveAgreement?: OSMCompanyModelCompanyDtoCollectiveAgreementDto;

  /**  */
  establishmentType?: OSMCompanyModelCompanyDtoEstablishmentTypeDto;

  /**  */
  portalFunction?: boolean;

  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoGetCompanyAddressDto;

  /**  */
  companyOtherAddress?: OSMCompanyModelCompanyDtoGetCompanyAddressDto[];

  /**  */
  agencyPortfolios?: OSMCompanyModelDtoCompanyAgencyPortfolioDto[];

  /**  */
  solvency?: string;

  /**  */
  companySector?: OSMCompanyModelDtoCompanySectorDto;

  /**  */
  status?: string;

  /**  */
  servicesUri?: string;

  /**  */
  contactsUri?: string;

  /**  */
  practicalInformation?: string;

  /**  */
  frameworkAgreement?: OSMCompanyModelFrameworkAgreementDtoFrameworkAgreementDto;

  /**  */
  phone?: string;

  /**  */
  workforce?: number;

  /**  */
  isConsultantContact?: boolean;

  /**  */
  companyId?: string;
}

export interface FullPositionBrief {
  /**  */
  qualification?: Qualification;

  /**  */
  company?: CompanyDetailsWithId;

  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  creatorUserId?: string;

  /**  */
  agencyId?: string;

  /**  */
  brandCode?: string;

  /**  */
  createdAt: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  recruitementMissionIds?: string[];

  /**  */
  companyDetails?: PositionBriefCompanyDetails;

  /**  */
  positionContextDetails?: PositionBriefPositionContextDetails;

  /**  */
  environmentAndMissionsDetails?: PositionBriefEnvironmentAndMissionsDetails;

  /**  */
  positionGeneralInformation?: PositionBriefPositionGeneralInformation;

  /**  */
  positionMobility?: PositionBriefPositionMobility;

  /**  */
  positionTime?: PositionBriefPositionTime;

  /**  */
  positionSalary?: PositionBriefPositionSalary;

  /**  */
  profileDetails?: PositionBriefProfileDetails;

  /**  */
  signatures?: Signatures;
}

export interface PositionBriefsWithCount {
  /**  */
  positionBriefs: FullPositionBrief[];

  /**  */
  count: number;
}

export interface AuthenticatedUser {
  /**  */
  givenName?: string;

  /**  */
  familyName?: string;

  /**  */
  siid?: string;

  /**  */
  email?: string;
}

export interface LinkPositionBriefToMissionsParams {
  /**  */
  user: AuthenticatedUser;

  /**  */
  missionIds: string[];
}

export interface IdObject {
  /**  */
  id: number;
}

export interface UpdatePositionBriefCompanyDetailsFields {
  /**  */
  activitySector?: IdObject;

  /**  */
  groupName?: string;

  /**  */
  corporateCulture?: string;

  /**  */
  otherAdvantages?: string;

  /**  */
  hierarchy?: CompanyHierarchy[];

  /**  */
  recruitmentProcess?: string;

  /**  */
  hrProjects?: string;

  /**  */
  investmentProjects?: string;
}

export interface UpdatePositionBriefPositionContextDetailsFields {
  /**  */
  openPositionDurationUnit?: OpenPositionDurationUnit;

  /**  */
  contractType?: IdObject;

  /**  */
  contractStatus?: IdObject;

  /**  */
  positionMotive?: IdObject;

  /**  */
  tenurable?: boolean;

  /**  */
  recruitmentOpenPositionDuration?: number;

  /**  */
  trialPeriodDuration?: string;

  /**  */
  cddDuration?: number;

  /**  */
  recruitmentReason?: string;

  /**  */
  difficulties?: string;
}

export interface UpdatePositionBriefFields {
  /**  */
  companyDetails?: UpdatePositionBriefCompanyDetailsFields;

  /**  */
  positionContextDetails?: UpdatePositionBriefPositionContextDetailsFields;

  /**  */
  environmentAndMissionsDetails?: object;

  /**  */
  positionGeneralInformation?: object;

  /**  */
  positionMobility?: object;

  /**  */
  positionTime?: object;

  /**  */
  positionSalary?: object;

  /**  */
  profileDetails?: object;

  /**  */
  signatures?: Signatures;

  /**  */
  qualificationId?: string;

  /**  */
  companyId?: string;

  /**  */
  agencyId?: string;

  /**  */
  brandCode?: string;

  /**  */
  recruitementMissionIds?: string[];
}

export interface ClearPositionBriefCompanyDetailsFields {
  /**  */
  activitySector?: boolean;
}

export interface ClearPositionBriefPositionContextDetailsFields {
  /**  */
  tenurable?: boolean;

  /**  */
  positionMotive?: boolean;

  /**  */
  contractType?: boolean;

  /**  */
  contractStatus?: boolean;

  /**  */
  trialPeriodDuration?: boolean;

  /**  */
  cddDuration?: boolean;
}

export interface ClearPositionBriefEnvironmentAndMissionsDetailsFields {
  /**  */
  hasManagement?: boolean;

  /**  */
  numberOfPeopleToManage?: boolean;

  /**  */
  managementSkills?: boolean;

  /**  */
  remoteManagement?: boolean;

  /**  */
  handicapFriendly?: boolean;

  /**  */
  hasRisks?: boolean;

  /**  */
  risks?: boolean;

  /**  */
  otherRisks?: boolean;
}

export interface ClearPositionBriefPositionGeneralInformationFields {
  /**  */
  managerId?: boolean;

  /**  */
  confidentialityClause?: boolean;

  /**  */
  mobilityClause?: boolean;
}

export interface ClearPositionBriefPositionMobilityFields {
  /**  */
  remoteAccepted?: boolean;

  /**  */
  remoteDays?: boolean;

  /**  */
  remoteFrequencyValue?: boolean;

  /**  */
  remoteFrequencyUnit?: boolean;

  /**  */
  address?: boolean;

  /**  */
  hasBusinessTrips?: boolean;

  /**  */
  nationalMobility?: boolean;

  /**  */
  mobilityAreas?: boolean;
}

export interface ClearPositionBriefPositionTimeFields {
  /**  */
  timeOnCallDuty?: boolean;
}

export interface ClearPositionBriefPositionSalaryFields {
  /**  */
  restaurantTickets?: boolean;
}

export interface ClearPositionBriefFields {
  /**  */
  companyDetails?: ClearPositionBriefCompanyDetailsFields;

  /**  */
  positionContextDetails?: ClearPositionBriefPositionContextDetailsFields;

  /**  */
  environmentAndMissionsDetails?: ClearPositionBriefEnvironmentAndMissionsDetailsFields;

  /**  */
  positionGeneralInformation?: ClearPositionBriefPositionGeneralInformationFields;

  /**  */
  positionMobility?: ClearPositionBriefPositionMobilityFields;

  /**  */
  positionTime?: ClearPositionBriefPositionTimeFields;

  /**  */
  positionSalary?: ClearPositionBriefPositionSalaryFields;
}

export interface PatchPositionBriefBody {
  /**  */
  changes?: UpdatePositionBriefFields;

  /**  */
  _clear?: ClearPositionBriefFields;
}

export interface CompanySection {
  /**  */
  information: boolean;

  /**  */
  assets: boolean;

  /**  */
  strategy: boolean;

  /**  */
  organization: boolean;
}

export interface PositionSection {
  /**  */
  information: boolean;

  /**  */
  context: boolean;

  /**  */
  environment: boolean;
}

export interface ProfileSection {
  /**  */
  curriculum: boolean;

  /**  */
  skills: boolean;
}

export interface ConclusionSection {
  /**  */
  signatures: boolean;
}

export interface Sections {
  /**  */
  company: CompanySection;

  /**  */
  position: PositionSection;

  /**  */
  profile: ProfileSection;

  /**  */
  conclusion: ConclusionSection;
}

export interface GetPositionBriefPdfQueryParams {
  /**  */
  user: AuthenticatedUser;

  /**  */
  brandCode: string;

  /**  */
  agencyId: string;

  /**  */
  sections: Sections;
}

export interface SendMailParams {
  /**  */
  user: AuthenticatedUser;

  /**  */
  agencyId: string;

  /**  */
  selectedMailAddresses: string[];

  /**  */
  emailComment?: string;
}

export interface VariableParams {
  /**  */
  type: VariableType;

  /**  */
  name: string;

  /**  */
  description?: string;
}

export interface CreateVariableBody {
  /**  */
  positionBrief: IdObject;

  /**  */
  variable: VariableParams;
}

export interface Variable {
  /**  */
  type: VariableType;

  /**  */
  id: number;

  /**  */
  positionBrief: PositionBrief;

  /**  */
  name: string;

  /**  */
  description?: string;
}

export interface UpdateVariablesBody {
  /**  */
  type: VariableType;

  /**  */
  name: string;

  /**  */
  description?: string;
}

export interface CompetitorParams {
  /**  */
  status: CompetitorStatus;

  /**  */
  name: string;
}

export interface CreateCompetitorsBody {
  /**  */
  positionBrief: IdObject;

  /**  */
  competitors: CompetitorParams[];
}

export interface Competitor {
  /**  */
  status: CompetitorStatus;

  /**  */
  id: number;

  /**  */
  positionBrief: PositionBrief;

  /**  */
  name: string;
}

export interface UpdateCompetitorsBody {
  /**  */
  status: CompetitorStatus;
}

export interface BonusParams {
  /**  */
  frequency?: BonusFrequency;

  /**  */
  bonusType?: BonusType;

  /**  */
  reference?: BonusReference;

  /**  */
  name: string;

  /**  */
  amount?: number;

  /**  */
  description?: string;
}

export interface CreateBonusBody {
  /**  */
  positionBrief: IdObject;

  /**  */
  bonus: BonusParams;
}

export interface Bonus {
  /**  */
  frequency?: BonusFrequency;

  /**  */
  bonusType?: BonusType;

  /**  */
  reference?: BonusReference;

  /**  */
  id: number;

  /**  */
  positionBrief: PositionBrief;

  /**  */
  name: string;

  /**  */
  amount?: number;

  /**  */
  description?: string;
}

export interface UpdateBonusBody {
  /**  */
  frequency?: BonusFrequency;

  /**  */
  bonusType?: BonusType;

  /**  */
  reference?: BonusReference;

  /**  */
  name: string;

  /**  */
  amount?: number;

  /**  */
  description?: string;
}

export interface Department {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface City {
  /**  */
  uId?: string;

  /**  */
  insee?: string;

  /**  */
  zipCode?: string;

  /**  */
  name?: string;
}

export interface Risk {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface MedicalSpecialization {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface KeyPerson {
  /**  */
  id: number;

  /**  */
  positionBrief: PositionBrief;

  /**  */
  keyPersonId: string;

  /**  */
  keyPersonRole: EnumKeyPersonKeyPersonRole[];

  /**  */
  missionReports: boolean;

  /**  */
  recruitmentPoints: boolean;
}

export interface KeyPersonParams {
  /**  */
  keyPersonId: string;

  /**  */
  missionReports: boolean;

  /**  */
  recruitmentPoints: boolean;

  /**  */
  keyPersonRole: EnumKeyPersonParamsKeyPersonRole[];
}

export interface CreateKeyPersonBody {
  /**  */
  positionBrief: IdObject;

  /**  */
  keyPerson: KeyPersonParams;
}

export interface UpdateKeyPersonBody {
  /**  */
  keyPersonId?: string;

  /**  */
  id?: number;

  /**  */
  missionReports: boolean;

  /**  */
  recruitmentPoints: boolean;

  /**  */
  keyPersonRole: EnumUpdateKeyPersonBodyKeyPersonRole[];
}

export interface UpdateKeyPersonsBody {
  /**  */
  positionBriefId: number;

  /**  */
  keyPersons: UpdateKeyPersonBody[];
}

export interface Document {
  /**  */
  id: string;

  /**  */
  companyId: string;

  /**  */
  name: string;

  /**  */
  size: number;

  /**  */
  encoding: string;

  /**  */
  mimetype: string;

  /**  */
  fileData: object;
}

export interface Experience {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  value: number;

  /**  */
  positionBrief: PositionBrief;
}

export interface CreateExperienceBody {
  /**  */
  positionBriefId: number;

  /**  */
  name: string;

  /**  */
  value: number;
}

export interface Consultant {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  email?: string;
}

export interface RecruitmentStepParams {
  /**  */
  modality: RecruitmentStepModality;

  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  specificTest: boolean;

  /**  */
  testDetails: string;

  /**  */
  contactName: string;

  /**  */
  stepRank: number;
}

export interface RecruitmentStepCreateParams {
  /**  */
  modality: RecruitmentStepModality;

  /**  */
  name: string;

  /**  */
  specificTest: boolean;

  /**  */
  testDetails: string;

  /**  */
  contactName: string;
}

export interface CreateRecruitmentStepBody {
  /**  */
  positionBrief: IdObject;

  /**  */
  recruitmentStep: RecruitmentStepCreateParams;
}

export interface RecruitmentStep {
  /**  */
  modality: RecruitmentStepModality;

  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  specificTest: boolean;

  /**  */
  testDetails: string;

  /**  */
  contactName: string;

  /**  */
  stepRank: number;

  /**  */
  positionBrief: PositionBrief;
}

export interface UpdateRecruitmentStepBody {
  /**  */
  modality: RecruitmentStepModality;

  /**  */
  name: string;

  /**  */
  specificTest: boolean;

  /**  */
  testDetails: string;

  /**  */
  contactName: string;

  /**  */
  stepRank: number;
}

export interface GetInterviewsExportParams {
  /**  */
  consultantMail: string;

  /**  */
  startDate: Date;
}
export enum EnumActivitySectorKey {
  'AUTOMOTIVE' = 'AUTOMOTIVE',
  'TRADES_AND_SERVICES' = 'TRADES_AND_SERVICES',
  'LOGISTICS' = 'LOGISTICS',
  'HOTEL_AND_CATERING' = 'HOTEL_AND_CATERING',
  'AGRIFOOD' = 'AGRIFOOD',
  'IT' = 'IT',
  'INDUSTRY' = 'INDUSTRY',
  'CONSTRUCTION' = 'CONSTRUCTION',
  'PHARMA' = 'PHARMA',
  'PUBLIC' = 'PUBLIC',
  'FINANCE' = 'FINANCE',
  'OTHER' = 'OTHER',
}
export enum EnumCompanyHierarchySize {
  'SMALL' = 'SMALL',
  'MEDIUM' = 'MEDIUM',
  'BIG' = 'BIG',
}
export enum OpenPositionDurationUnit {
  'WEEKS' = 'WEEKS',
  'MONTHS' = 'MONTHS',
  'YEARS' = 'YEARS',
}
export enum EnumPositionMotiveKey {
  'POSITION_CREATION' = 'POSITION_CREATION',
  'REPLACEMENT' = 'REPLACEMENT',
  'ACTIVITY_GROWTH' = 'ACTIVITY_GROWTH',
  'SUCCESSOR' = 'SUCCESSOR',
  'ASSOCIATE' = 'ASSOCIATE',
}
export enum EnumPositionMotiveContractTypes {
  'LIBERAL' = 'LIBERAL',
  'VACATION' = 'VACATION',
  'TNS' = 'TNS',
  'CDD' = 'CDD',
  'CDI' = 'CDI',
}
export enum EnumContractTypeKey {
  'LIBERAL' = 'LIBERAL',
  'VACATION' = 'VACATION',
  'TNS' = 'TNS',
  'CDD' = 'CDD',
  'CDI' = 'CDI',
}
export enum EnumContractStatusKey {
  'EXECUTIVE_OFFICER' = 'EXECUTIVE_OFFICER',
  'EXECUTIVE' = 'EXECUTIVE',
  'SUPERVISOR' = 'SUPERVISOR',
  'EMPLOYEE' = 'EMPLOYEE',
}
export enum RemoteFrequencyUnit {
  'WEEK' = 'WEEK',
  'MONTH' = 'MONTH',
  'YEAR' = 'YEAR',
}

export enum EnumTimePackage {
  'HOUR' = 'HOUR',
  'DAY' = 'DAY',
}
export enum EnumWorkingHourDays {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}
export enum EnumSalaryBasis {
  'HOUR' = 'HOUR',
  'MONTH' = 'MONTH',
  'YEAR' = 'YEAR',
}

export enum EnumSalaryFormat {
  'FIX' = 'FIX',
  'RANGE' = 'RANGE',
}
export enum EnumPositionBriefPositionSalaryIncentiveFrequency {
  'DAILY' = 'DAILY',
  'WEEKLY' = 'WEEKLY',
  'MONTHLY' = 'MONTHLY',
  'QUARTERLY' = 'QUARTERLY',
  'YEARLY' = 'YEARLY',
}
export enum EnumPositionBriefPositionSalaryAttendanceFrequency {
  'DAILY' = 'DAILY',
  'WEEKLY' = 'WEEKLY',
  'MONTHLY' = 'MONTHLY',
  'QUARTERLY' = 'QUARTERLY',
  'YEARLY' = 'YEARLY',
}
export enum EnumStudyLevelLevel {
  'CAP_BEP' = 'CAP_BEP',
  'BAC' = 'BAC',
  'BAC_PLUS_2' = 'BAC_PLUS_2',
  'BAC_PLUS_3' = 'BAC_PLUS_3',
  'BAC_PLUS_5' = 'BAC_PLUS_5',
  'ENGINEER' = 'ENGINEER',
  'PHD' = 'PHD',
}
export enum EnumStudyLevelPriority {
  'UNKNOWN' = 'UNKNOWN',
  'OPTIONAL' = 'OPTIONAL',
  'MANDATORY' = 'MANDATORY',
}
export enum EnumLanguageKey {
  'ENGLISH' = 'ENGLISH',
  'SPANISH' = 'SPANISH',
  'GERMAN' = 'GERMAN',
  'PORTUGUESE' = 'PORTUGUESE',
  'ITALIAN' = 'ITALIAN',
  'ARABIC' = 'ARABIC',
  'RUSSIAN' = 'RUSSIAN',
  'CHINESE' = 'CHINESE',
}
export enum EnumReportingFrequencyUnit {
  'WEEKLY' = 'WEEKLY',
  'MONTHLY' = 'MONTHLY',
  'YEARLY' = 'YEARLY',
}
export enum VariableType {
  'INDIVIDUAL' = 'INDIVIDUAL',
  'COLLECTIVE' = 'COLLECTIVE',
}

export enum CompetitorStatus {
  'OUTSIDER' = 'OUTSIDER',
  'MINEUR' = 'MINEUR',
  'LEADER' = 'LEADER',
}

export enum BonusFrequency {
  'DAILY' = 'DAILY',
  'WEEKLY' = 'WEEKLY',
  'MONTHLY' = 'MONTHLY',
  'QUARTERLY' = 'QUARTERLY',
  'YEARLY' = 'YEARLY',
  'PER_KILOMETER' = 'PER_KILOMETER',
}

export enum BonusType {
  'AMOUNT' = 'AMOUNT',
  'PERCENTAGE' = 'PERCENTAGE',
}

export enum BonusReference {
  'REVENUE' = 'REVENUE',
  'MARGIN' = 'MARGIN',
  'PROGRESSION' = 'PROGRESSION',
  'SALARY' = 'SALARY',
  'OTHER' = 'OTHER',
}
export enum EnumKeyPersonKeyPersonRole {
  'DIRECT_SUPERIOR_NAME' = 'DIRECT_SUPERIOR_NAME',
  'RECRUITMENT_OFFICER_NAME' = 'RECRUITMENT_OFFICER_NAME',
  'DECISION_MAKER_NAME' = 'DECISION_MAKER_NAME',
}
export enum EnumKeyPersonParamsKeyPersonRole {
  'DIRECT_SUPERIOR_NAME' = 'DIRECT_SUPERIOR_NAME',
  'RECRUITMENT_OFFICER_NAME' = 'RECRUITMENT_OFFICER_NAME',
  'DECISION_MAKER_NAME' = 'DECISION_MAKER_NAME',
}
export enum EnumUpdateKeyPersonBodyKeyPersonRole {
  'DIRECT_SUPERIOR_NAME' = 'DIRECT_SUPERIOR_NAME',
  'RECRUITMENT_OFFICER_NAME' = 'RECRUITMENT_OFFICER_NAME',
  'DECISION_MAKER_NAME' = 'DECISION_MAKER_NAME',
}
export enum RecruitmentStepModality {
  'ON_SITE' = 'ON_SITE',
  'VIDEOCONFERENCE' = 'VIDEOCONFERENCE',
  'UNKNOWN' = 'UNKNOWN',
}
