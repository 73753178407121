import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { Competitor, CompetitorsService, CompetitorStatus } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

import useFetchCompetitors from './useFetchCompetitors';

const useUpdateCompetitor = (
  positionBriefId: string,
  extraOptions?: UseMutationOptions<Competitor, unknown, { id: number; status: CompetitorStatus }>
) => {
  const queryClient = useQueryClient();
  const { ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.updateCompetitor, positionBriefId],
    async ({ id, status }: { id: number; status: CompetitorStatus }) => {
      const body = { status };
      return await CompetitorsService.competitorsControllerUpdateCompetitors({ id, body });
    },
    {
      onMutate: async ({ id, status }) => {
        await queryClient.cancelQueries([QueryKeys.fetchCompetitors, positionBriefId]);
        queryClient.setQueryData(
          [QueryKeys.fetchCompetitors, positionBriefId],
          (oldData?: ReturnType<typeof useFetchCompetitors>['data']) => {
            return (
              oldData?.map(competitor =>
                competitor.id === id ? { ...competitor, status } : competitor
              ) ?? []
            );
          }
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.fetchCompetitors);
      },
      ...rest,
    }
  );
};

export default useUpdateCompetitor;
