import { z } from 'zod';

export const BonusSchema = z
  .object({
    name: z.string(),
    hasAmount: z.boolean(),
    amount: z.string().nullable(),
    frequency: z.string().nullable(),
    description: z.string(),
    bonusType: z.string().nullable(),
    reference: z.string().optional().nullable(),
  })
  .refine(
    ({ hasAmount, amount }) => {
      return !hasAmount || parseFloat(amount ?? '') >= 0;
    },
    {
      message: 'doit être positif',
      path: ['amount'],
    }
  );
