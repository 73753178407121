export enum QueryKeys {
  fetchPerimeter = 'fetchPerimeter',
  fetchDepartments = 'fetchDepartments',
  fetchMedicalSpecializations = 'fetchMedicalSpecializations',
  fetchCompanies = 'fetchCompanies',
  fetchCompanyDetails = 'fetchCompanyDetails',
  fetchCompanyContacts = 'fetchCompanyContacts',
  fetchCompanyWorkingLocations = 'fetchCompanyWorkingLocations',
  fetchPositionBriefs = 'fetchPositionBriefs',
  fetchPositionBriefDetails = 'fetchPositionBriefDetails',
  fetchQualificationById = 'fetchQualificationById',
  fetchCompetitors = 'fetchCompetitors',
  fetchActivitySectors = 'fetchActivitySectors',
  fetchContractStatuses = 'fetchContractStatuses',
  fetchContractTypes = 'fetchContractTypes',
  fetchPositionMotives = 'fetchPositionMotives',
  fetchBonuses = 'fetchBonuses',
  fetchVariables = 'fetchVariables',
  fetchKeyPersons = 'fetchKeyPersons',
  fetchCompanyDocuments = 'fetchCompanyDocuments',
  fetchRisks = 'fetchRisks',
  fetchServices = 'fetchServices',
  fetchExperiences = 'fetchExperiences',
  fetchLanguages = 'fetchLanguages',
  fetchAllowedToExport = 'fetchAllowedToExport',
  fetchRecruitmentSteps = 'fetchRecruitmentSteps',
  fetchRecruitingMissions = 'fetchRecruitingMissions',
  fetchAgencyConsultants = 'fetchAgencyConsultants',
}

export enum MutationKeys {
  fetchCities = 'fetchCities',
  fetchQualifications = 'fetchQualifications',
  createPositionBrief = 'createPositionBrief',
  deletePositionBrief = 'deletePositionBrief',
  duplicatePositionBrief = 'duplicatePositionBrief',
  updatePositionBriefName = 'updatePositionBriefName',
  updatePositionBrief = 'updatePositionBrief',
  createCompetitor = 'createCompetitor',
  deleteCompetitor = 'deleteCompetitor',
  updateCompetitor = 'updateCompetitor',
  createBonus = 'createBonus',
  updateBonus = 'updateBonus',
  deleteBonus = 'deleteBonus',
  createVariable = 'createVariable',
  deleteVariable = 'deleteVariable',
  deleteDocument = 'deleteDocument',
  fetchDocumentById = 'fetchDocumentById',
  uploadDocument = 'uploadDocument',
  generatePDF = 'generatePDF',
  updateKeyPersons = 'updateKeyPersons',
  updateRecruitmentSteps = 'updateRecruitmentSteps',
  sendMail = 'sendMail',
  createExperience = 'createExperience',
  removeExperience = 'removeExperience',
  exportBriefsData = 'exportBriefsData',
  createRecruitmentStep = 'createRecruitmentStep',
  updateRecruitmentStep = 'updateRecruitmentStep',
  deleteRecruitmentStep = 'deleteRecruitmentStep',
  linkMissionsToBDP = 'linkMissionsToBDP',
  uploadSignatures = 'uploadSignatures',
  emptySignatures = 'emptySignatures',
}
