import {
  WithLightTitle,
  TimePicker,
  InclusiveSelectionGroup,
} from '@randstad-lean-mobile-factory/react-components-core';
import { MoinsCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { EnumWorkingHourDays } from 'src/Services/API';

import { TimeContext } from './../../../../TimeContext';
import styles from './WorkingHourItem.module.scss';
import { Props } from './WorkingHourItem.types';

const daysToString = {
  MONDAY: 'L',
  TUESDAY: 'M',
  WEDNESDAY: 'M',
  THURSDAY: 'J',
  FRIDAY: 'V',
  SATURDAY: 'S',
  SUNDAY: 'D',
};

const WorkingHourItem = ({
  index,
  remove,
  className,
  isBriefSigned,
  setIsEditAfterSigningModalOpen,
}: Props) => {
  const timeContext = useContext(TimeContext);
  const name = `timeWorkingHours.${index}` as const;
  const startTimeValue = timeContext.watch(`${name}.startTime`);
  const endTimeValue = timeContext.watch(`${name}.endTime`);
  const daysValue = timeContext.watch(`${name}.days`);

  return (
    <div className={classNames(styles.container, styles.row, className)}>
      <WithLightTitle title="horaires de travail">
        <div className={styles.row}>
          <TimePicker
            className={styles.startTimePicker}
            value={startTimeValue ?? ''}
            onChange={event => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              } else {
                timeContext.setValue(`${name}.startTime`, (event.target as HTMLInputElement).value);
                timeContext.positionInformationHandleSubmit()();
              }
            }}
          />
          <TimePicker
            className={styles.endTimePicker}
            value={endTimeValue ?? ''}
            onChange={event => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              } else {
                timeContext.setValue(`${name}.endTime`, (event.target as HTMLInputElement).value);
                timeContext.positionInformationHandleSubmit()();
              }
            }}
          />
        </div>
      </WithLightTitle>
      <div className={styles.daysContainer}>
        <WithLightTitle title="jours">
          <InclusiveSelectionGroup
            values={Object.values(EnumWorkingHourDays)}
            getKey={key => key}
            getStringValue={label => daysToString[label]}
            selected={daysValue}
            setSelected={selectedDays => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              } else {
                timeContext.setValue(`${name}.days`, selectedDays);
                timeContext.positionInformationHandleSubmit()();
              }
            }}
          />
        </WithLightTitle>
      </div>
      <button
        className={styles.removeButton}
        onClick={() => {
          if (isBriefSigned) {
            setIsEditAfterSigningModalOpen(true);
          } else {
            remove(index);
          }
        }}
      >
        <MoinsCircle className={styles.removeButtonIcon} />
      </button>
    </div>
  );
};

export default WorkingHourItem;
