import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { PositionBriefsService } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

const useSendMail = () => {
  const user = useAuthenticatedUser();
  const currentAgency = useSelector(getCurrentAgency) ?? '';

  return useMutation(
    [MutationKeys.sendMail],
    async ({
      positionBriefId,
      selectedMailAddresses,
      emailComment,
    }: {
      positionBriefId: number;
      selectedMailAddresses: string[];
      emailComment: string;
    }) => {
      return PositionBriefsService.positionBriefsControllerSendRecruitmentOfficerMail({
        id: positionBriefId,
        body: {
          user,
          agencyId: currentAgency ?? '',
          selectedMailAddresses,
          emailComment,
        },
      });
    },
    {
      onSuccess: () => {
        toast.success("le pdf du bdp a été envoyé aux interlocuteurs spécifiés ainsi qu'à vous");
      },
      onError: () => {
        toast.error("erreur lors de l'envoi du mail");
      },
    }
  );
};

export default useSendMail;
