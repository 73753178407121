import { z } from 'zod';

import { EnumKeyPersonKeyPersonRole } from 'src/Services/API';

export const KeyPersonsPickerSchema = z.object({
  keyPersons: z
    .array(
      z
        .object({
          id: z.number().optional(),
          keyPersonId: z.string().optional(),
          keyPersonRole: z.array(z.nativeEnum(EnumKeyPersonKeyPersonRole)).optional(),
          missionReports: z.boolean().optional(),
          recruitmentPoints: z.boolean().optional(),
        })
        .optional()
    )
    .optional(),
});
