import {
  WithLightTitle,
  AutoSuggest,
  Button,
  FetchButton,
  Modal,
  Badge,
  PopupActions,
  PopupMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  CloseSmall,
  Email,
  IllusBDPEmail,
  PlusCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useState, useCallback, useEffect, useRef } from 'react';

import { useFetchCompanyContactsFromURL } from 'src/Hooks/Companies/useFetchCompanyContactsFromURL';
import useSendMail from 'src/Hooks/Mail/useSendMail';
import { toFetchStatus } from 'src/Services/Async';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './MailModal.module.scss';
import { MailModalSchema } from './MailModal.schema';
import { Props } from './MailModal.types';
import { testEmail } from './utils';

const MailModal = ({ positionBriefId, selectedMailAddresses, setSelectedMailAddresses }: Props) => {
  const ref = useRef<PopupActions>(null);
  const allCompanyContacts =
    useFetchCompanyContactsFromURL()?.data?.filter(contact => testEmail(contact.email ?? '')) ?? [];
  const [currentEmailAddress, setCurrentEmailAddress] = useState('');
  const [noOptionsText, setNoOptionsText] = useState(
    'veuillez entrer un format de mail valide (exemple@randstad.fr)'
  );
  const researchedContactEmails = allCompanyContacts
    .filter(
      contact =>
        (contact.email?.toLowerCase().includes(currentEmailAddress.toLowerCase()) ||
          contact?.name?.includes(currentEmailAddress)) &&
        !selectedMailAddresses.includes(contact.email ?? '')
    )
    .map(contact => contact.email);

  const onClose = () => {
    setValue('emailComment', '');
    setSelectedMailAddresses([]);
    ref.current?.close();
  };

  const { reset, control, watch, setValue } = useFormWithZodResolver({
    schema: MailModalSchema,
    defaultValues: {
      emailComment: '',
    },
  });
  const sendMailMutation = useSendMail();

  const handleEnter = useCallback(
    e => {
      if (e.key === 'Enter') {
        if (
          selectedMailAddresses.length < 10 &&
          !selectedMailAddresses.includes(currentEmailAddress) &&
          testEmail(currentEmailAddress)
        ) {
          setSelectedMailAddresses([...selectedMailAddresses, currentEmailAddress]);
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEmailAddress]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEnter);
    return () => document.removeEventListener('keydown', handleEnter);
  }, [handleEnter]);

  useEffect(() => {
    if (testEmail(currentEmailAddress)) {
      setNoOptionsText("appuyez sur 'entrée' pour ajouter cette adresse mail");
    } else {
      setNoOptionsText('veuillez entrer un format de mail valide (exemple@randstad.fr)');
    }
  }, [currentEmailAddress]);

  const emailComment = watch('emailComment');
  return (
    <Modal
      title="transmettre par mail"
      ref={ref}
      onClose={onClose}
      trigger={
        <PopupMenu.Item
          key="sendEmail"
          icon={<Email />}
          text="transmettre par mail"
          onClick={ref.current?.open}
          keepOpenOnClick
        />
      }
      icon={
        <div className={styles.modalIcon}>
          <IllusBDPEmail />
        </div>
      }
      footerActionsLeft={[
        <Button.Tertiary onClick={() => reset()}>tout réinitialiser</Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>fermer</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={selectedMailAddresses.length === 0}
          onClick={() =>
            sendMailMutation.mutate({ positionBriefId, selectedMailAddresses, emailComment })
          }
          fetchStatus={toFetchStatus(sendMailMutation)}
          onSuccess={onClose}
        />,
      ]}
    >
      <WithLightTitle
        className={styles.autoSuggestContainer}
        title="rechercher parmi les collaborateurs du client ou ajouter une adresse de votre choix"
      >
        <AutoSuggest
          id="contactsSearch"
          placeholder="ex : exemple@randstad.fr"
          value={selectedMailAddresses}
          disabled={selectedMailAddresses.length === 10}
          popupIcon={<PlusCircle />}
          noOptionsText={noOptionsText}
          onChange={(contactIds: string[]) => {
            if (contactIds.length <= 10) {
              setSelectedMailAddresses(contactIds.sort());
            }
            setCurrentEmailAddress('');
          }}
          keyValueExtractor={(searchResult: string) => {
            return {
              key: searchResult,
              value: `${allCompanyContacts.find(dep => dep.email === searchResult)?.email}`,
            };
          }}
          onSearch={(searchedValue: string) => {
            setCurrentEmailAddress(searchedValue);
          }}
          searchResults={researchedContactEmails.map(email => email ?? '')}
        />
      </WithLightTitle>
      {selectedMailAddresses?.length > 0 && (
        <WithLightTitle
          title={
            selectedMailAddresses?.length > 0
              ? pluralFormat(selectedMailAddresses?.length, 'adresse email ajoutée')
              : ''
          }
          className={styles.separator}
        >
          {selectedMailAddresses?.map((emailAddress: string) => (
            <Badge
              className={styles.activeBadge}
              value={emailAddress.toLowerCase() ?? ''}
              rightIcon={<CloseSmall />}
              onRightIconClick={() =>
                setSelectedMailAddresses(
                  selectedMailAddresses.filter((address: string) => address !== emailAddress)
                )
              }
            />
          ))}
        </WithLightTitle>
      )}
      <div className={styles.emailCommentContainer}>
        <TextAreaWithLightTitle
          title="commentaire (optionnel)"
          name="emailComment"
          counterClassName={styles.counter}
          textAreaClassName={styles.textArea}
          placeholder="en quelques mots..."
          maxLength={255}
          valueLength={emailComment.length}
          control={control}
        />
      </div>
    </Modal>
  );
};

export default MailModal;
