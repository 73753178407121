import { useMutation, UseMutationOptions } from 'react-query';

import { ExportService } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

export const useExportPositionBriefs = (
  extraOptions?: UseMutationOptions<void, unknown, unknown, unknown>
) => {
  const { email } = useAuthenticatedUser();
  return useMutation(
    [MutationKeys.exportBriefsData],
    async (params: { startDate: Date }) => {
      await ExportService.exportControllerGetExportData({
        body: { startDate: params.startDate, consultantMail: email ?? '' },
      });
    },
    { ...extraOptions }
  );
};
