/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';

export const ModalsContext = React.createContext({
  isExportModalOpen: false,
  isSigningModalOpen: false,
  setIsExportModalOpen: (_isExportModalOpen: boolean): void => {},
  setIsSigningModalOpen: (_isSigningModalOpen: boolean): void => {},
});
