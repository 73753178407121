import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { variableTypeValues } from 'src/Utils/variableTypeValues';

import styles from './VariableTypeSelection.module.scss';
import { Props } from './VariableTypeSelection.types';

const VariableTypeSelection = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
}: Props<TFieldValues, TName>) => {
  return (
    <div>
      <WithLightTitle title="type de contrat">
        <ExclusiveSelectionGroup
          values={variableTypeValues}
          buttonClassName={styles.button}
          name={name}
          canBeReset={false}
          control={control}
          getKey={({ key }) => key}
          getSelectedValue={value => {
            return variableTypeValues.find(({ id }) => id === value);
          }}
          getStringValue={({ label }) => label}
          getSelectedFormValue={element => element?.id}
        />
      </WithLightTitle>
    </div>
  );
};

export default VariableTypeSelection;
