import { useQuery } from 'react-query';

import { ConsultantsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchAllowedToExport = () => {
  return useQuery(
    [QueryKeys.fetchAllowedToExport],
    async () => {
      return ConsultantsService.consultantsControllerGetAllowedToExport();
    },
    { staleTime: 10 * 60 * 1000, cacheTime: 10 * 60 * 1000 }
  );
};
