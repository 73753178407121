import { PopupMenu, ThreeDotsButton } from '@randstad-lean-mobile-factory/react-components-core';
import { Copy, Pencil } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import BDPDeletionWarningModal from 'src/Containers/Modals/BDPDeletionWarningModal';
import BDPRenameModal from 'src/Containers/Modals/BDPRenameModal';
import { useDuplicatePositionBrief } from 'src/Hooks/PositionBriefs/useDuplicatePositionBrief';
import { getEditionRoute } from 'src/Services/Routing';

import styles from './BriefActions.module.scss';
import { Props } from './BriefActions.types';

const BriefActions = ({ briefId, briefName, companyId }: Props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const duplicateMutation = useDuplicatePositionBrief();
  return (
    <PopupMenu
      position="left top"
      width={248}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false);
      }}
      offsetX={-16}
      trigger={<ThreeDotsButton triggered={isOpen} className={styles.trigger} />}
    >
      <PopupMenu.Item
        icon={<Pencil />}
        text="consulter/modifier"
        onClick={() => {
          history.push(
            getEditionRoute({
              companyId: companyId,
              positionBriefId: briefId?.toString(),
              section: 'company/information',
            })
          );
        }}
      />
      <BDPDeletionWarningModal briefId={briefId} />
      <PopupMenu.Item
        icon={<Copy />}
        text="dupliquer"
        onClick={() => {
          if (briefId !== undefined) {
            duplicateMutation.mutate(briefId);
          }
        }}
      />
      <BDPRenameModal positionBriefName={briefName ?? ''} positionBriefId={briefId} />
    </PopupMenu>
  );
};

export default BriefActions;
