import {
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchMedicalSpecializations } from 'src/Hooks/Repositories/useFetchMedicalSpecializations';
import { getLabelFromId } from 'src/Utils/getById';

import { FormationContext } from '../FormationContext';

import MedicalSpecializationsPicker from './MedicalSpecializationsPicker';
import styles from './MedicalSpecializationsSelection.module.scss';

export const MedicalSpecializationsSelection = () => {
  const formationContext = useContext(FormationContext);

  const selectedMedicalSpecializationsIds = formationContext.watch('medicalSpecializationsIds');
  const { data, isSuccess, isError, isLoading } = useFetchMedicalSpecializations();

  const updateSelectedMedicalSpecializationsIds = (newValue: string[]) => {
    formationContext.setValue('medicalSpecializationsIds', newValue);
    formationContext.curriculumHandleSubmit()();
  };
  return (
    <>
      {isSuccess && (
        <>
          <WithLightTitle
            title="DU, DIU (pour médecins)"
            className={styles.medicalSpecializationsPicker}
          >
            <MedicalSpecializationsPicker
              allMedicalSpecializations={data}
              selectedMedicalSpecializationsIds={selectedMedicalSpecializationsIds}
              setSelectedMedicalSpecializationsIds={medicalSpecializationsIds =>
                updateSelectedMedicalSpecializationsIds(medicalSpecializationsIds)
              }
            />
          </WithLightTitle>
          {selectedMedicalSpecializationsIds.length > 0 && (
            <WithLightTitle
              className={styles.selectedContainer}
              title={
                selectedMedicalSpecializationsIds.length < 2 ? 'DU/DIU ajoutée' : 'DU/DIU ajoutées'
              }
            >
              {selectedMedicalSpecializationsIds.map(medicalSpecializationId => {
                return (
                  <div key={`medical-specialization-${medicalSpecializationId}`}>
                    <div
                      className={styles.separator}
                      key={`separator-${medicalSpecializationId}`}
                    />
                    <HorizontalTextIconCard
                      containerClassName={styles.selectedCard}
                      key={medicalSpecializationId}
                      label={getLabelFromId(medicalSpecializationId, data)}
                      rightIcon={<Trashcan />}
                      onRightIconClick={() => {
                        updateSelectedMedicalSpecializationsIds(
                          selectedMedicalSpecializationsIds.filter(
                            selectedMedicalSpecializationId =>
                              selectedMedicalSpecializationId !== medicalSpecializationId
                          )
                        );
                      }}
                    />
                  </div>
                );
              })}
            </WithLightTitle>
          )}
        </>
      )}
      {isLoading && (
        <ContentLoader height="20rem" width="100%" uniqueKey="curriculumLoader">
          <rect x="0%" y="5" rx="4" ry="4" width="30%" height="15" />
          <rect x="0%" y="35" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="55" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="30%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="30%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="135" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="155" rx="4" ry="4" width="20%" height="15" />
        </ContentLoader>
      )}
      {isError && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des spécialités médicales" />
      )}
    </>
  );
};
