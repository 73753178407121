import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { Bonus, BonusesService, UpdateBonusBody } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

type UpdateBonusArgs = {
  id: number;
  body: UpdateBonusBody;
};

const useUpdateBonus = (extraOptions?: UseMutationOptions<Bonus, unknown, UpdateBonusArgs>) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};

  return useMutation(
    [MutationKeys.updateBonus],
    async ({ id, body }: UpdateBonusArgs) => {
      return BonusesService.bonusesControllerUpdateBonus({ id, body });
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries(QueryKeys.fetchBonuses);
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};

export default useUpdateBonus;
