import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { VariablesService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

import useFetchVariables from './useFetchVariables';

const useDeleteVariable = (
  positionBrief: string,
  extraOptions?: UseMutationOptions<unknown, unknown, number>
) => {
  const queryClient = useQueryClient();
  const { ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.deleteVariable, positionBrief],
    async (id: number) => {
      return VariablesService.variablesControllerDeleteVariable({ id });
    },
    {
      onMutate: id => {
        queryClient.setQueryData(
          [QueryKeys.fetchVariables, positionBrief],
          (oldData?: ReturnType<typeof useFetchVariables>['data']) => {
            return oldData?.filter(variable => variable.id !== id) ?? [];
          }
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.fetchVariables);
      },
      ...rest,
    }
  );
};

export default useDeleteVariable;
