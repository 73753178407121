import {
  AutoSuggest,
  Badge,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { WhiteCircleCross } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import { useFetchDepartments } from 'src/Hooks/Repositories/useFetchDepartments';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { regions } from 'src/Utils/regions';

import styles from './MobilityAreasSelection.module.scss';
import { Props } from './MobilityAreasSelection.types';

const MobilityAreasSelection = ({
  selectedMobilityAreas,
  setSelectedMobilityAreas,
  isBriefSigned,
  setIsEditAfterSigningModalOpen,
}: Props) => {
  const allDepartments = useFetchDepartments().data ?? [];
  const allMobilityAreas = [...allDepartments, ...regions];
  const [keyword, setKeyword] = useState('');
  const researchedMobilityAreas = allMobilityAreas
    .filter(
      mobilityArea =>
        (mobilityArea.label?.toLowerCase().includes(keyword.toLowerCase()) ||
          mobilityArea.id.includes(keyword)) &&
        !selectedMobilityAreas.includes(mobilityArea.id)
    )
    .map(mobilityArea => mobilityArea.id);

  return (
    <div>
      {allMobilityAreas && (
        <WithLightTitle className={styles.autoSuggestContainer} title="bassin de mobilité">
          <div
            onMouseDown={() => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              }
            }}
          >
            <AutoSuggest
              id="mobilityAreaSearch"
              placeholder="ajouter les lieux un par un"
              value={selectedMobilityAreas}
              onChange={(mobilityAreas: string[]) => {
                setSelectedMobilityAreas(mobilityAreas.sort());
                setKeyword('');
              }}
              keyValueExtractor={(searchResult: string) => ({
                key: searchResult,
                value: allDepartments.find(department => department.id === searchResult)
                  ? `${searchResult} - ${
                      allMobilityAreas.find(mobilityArea => mobilityArea.id === searchResult)?.label
                    }`
                  : searchResult,
              })}
              onSearch={(searchedValue: string) => {
                setKeyword(searchedValue);
              }}
              searchResults={researchedMobilityAreas}
            />
          </div>
        </WithLightTitle>
      )}
      {selectedMobilityAreas?.length > 0 && (
        <WithLightTitle
          className={styles.badgesContainer}
          title={
            selectedMobilityAreas?.length > 0
              ? pluralFormat(selectedMobilityAreas?.length, 'département ajouté')
              : ''
          }
        >
          {selectedMobilityAreas?.map(mobilityAreaId => (
            <Badge
              className={styles.badge}
              value={
                allDepartments.find(department => department.id === mobilityAreaId)
                  ? (allMobilityAreas.find(value => mobilityAreaId === value.id)?.id ?? '') +
                    ' - ' +
                    (allMobilityAreas
                      .find(value => mobilityAreaId === value.id)
                      ?.label?.toLowerCase() ?? '')
                  : mobilityAreaId
              }
              rightIcon={
                <div
                  onMouseDown={() => {
                    if (isBriefSigned) {
                      setIsEditAfterSigningModalOpen(true);
                    }
                  }}
                >
                  <WhiteCircleCross />
                </div>
              }
              onRightIconClick={() =>
                setSelectedMobilityAreas(
                  selectedMobilityAreas.filter(mobilityArea => mobilityArea !== mobilityAreaId)
                )
              }
            />
          ))}
        </WithLightTitle>
      )}
    </div>
  );
};

export default MobilityAreasSelection;
