import { z } from 'zod';

export const environmentAndMissionsSchema = z.object({
  missions: z.string(),
  hasManagement: z.string(),
  numberOfPeopleToManage: z
    .string()
    .optional()
    .refine(
      value => value?.length === 0 || (!isNaN(parseInt(value ?? '')) && parseInt(value ?? '') >= 0),
      { message: 'doit être supérieur à 0' }
    ),
  managementSkills: z.string(),
  remoteManagement: z.string(),
  environments: z.string(),
  handicapFriendly: z.string(),
});

export type environmentAndMissionsSchemaType = z.infer<typeof environmentAndMissionsSchema>;
