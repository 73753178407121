import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { KeyPerson, KeyPersonsService, UpdateKeyPersonsBody } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

const useUpdateKeyPersons = (
  extraOptions?: UseMutationOptions<KeyPerson[], unknown, UpdateKeyPersonsBody>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};

  return useMutation(
    [MutationKeys.updateKeyPersons],
    async (body: UpdateKeyPersonsBody) => {
      return KeyPersonsService.keyPersonsControllerUpdateKeyPersons({ body });
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries(QueryKeys.fetchKeyPersons);
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};

export default useUpdateKeyPersons;
