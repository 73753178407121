import {
  AddButton,
  Checkbox,
  DropDown,
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  BDPBonusIcon,
  BDPVariableIcon,
  PlusCircle,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  TextInput,
  TripleChoiceButton,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import { BonusFrequencyTypes } from 'src/Containers/Position/PositionInformation/BonusModal/BonusModal.types';
import MobilityAreasSelection from 'src/Containers/Position/PositionInformation/MobilityAreasSelection/index';
import useFetchBonuses from 'src/Hooks/Bonuses/useFetchBonuses';
import { useFetchCompanyContactsFromURL } from 'src/Hooks/Companies/useFetchCompanyContactsFromURL';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import useDeleteVariable from 'src/Hooks/Variables/useDeleteVariable';
import useFetchVariables from 'src/Hooks/Variables/useFetchVariables';
import { FETCH_STATUS } from 'src/Redux/Types';
import {
  EnumPositionBriefPositionSalaryAttendanceFrequency,
  EnumPositionBriefPositionSalaryIncentiveFrequency,
  RemoteFrequencyUnit,
} from 'src/Services/API';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/Async';
import {
  bonusFrequencyAmountValues,
  bonusFrequencyPercentageValues,
  timeBonusFrequencyAmountValues,
} from 'src/Utils/bonusFrequencyValues';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { sanitizeString } from 'src/Utils/sanitizeString';
import { variableTypeValues } from 'src/Utils/variableTypeValues';
import { YesNoUnknownEnum } from 'src/Utils/yesNoUnknownEnum';
import { databaseToZodData, zodToDatabaseData } from 'src/Utils/ZodDatabaseTransformer';

import AddressSelection from './AddressSelection';
import BonusActions from './BonusActions/BonusActions.component';
import BonusModal from './BonusModal';
import ManagerPicker from './ManagerPicker';
import styles from './PositionInformation.module.scss';
import { positionInformationSchema } from './PositionInformation.schema';
import {
  FrequencyUnitDurationTypes,
  OtherAdvantages,
  TravelFee,
} from './PositionInformation.types';
import SalarySelection from './SalarySelection';
import { TimeContext } from './TimeContext';
import TimeSelection from './TimeSelection';
import { remoteFrequencyUnitValues } from './utils';
import VariableModal from './VariableModal';

const PositionInformation = () => {
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const id = parseInt(positionBriefId);
  const positionBriefQuery = useFetchPositionBriefDetails(id);
  const positionBriefFetchStatus = toFetchStatus(positionBriefQuery);
  const updateMutation = useUpdatePositionBrief();
  const managersQuery = useFetchCompanyContactsFromURL();
  const variablesQuery = useFetchVariables(id);
  const bonusesQuery = useFetchBonuses(id);
  const bonusesFetchStatus = toFetchStatus(bonusesQuery);
  const variablesFetchStatus = toFetchStatus(variablesQuery);
  const managersFetchStatus = toFetchStatus(managersQuery);
  const globalFetchStatus = mergeSeveralFetchStatus([
    positionBriefFetchStatus,
    bonusesFetchStatus,
    variablesFetchStatus,
    managersFetchStatus,
  ]);
  const deleteVariableMutation = useDeleteVariable(positionBriefId);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty, isSubmitted },
  } = useFormWithZodResolver({
    schema: positionInformationSchema,
    defaultValues: {
      // Section Informations générales
      services: positionBriefQuery?.data?.positionGeneralInformation?.services ?? '',
      confidentialityClause: databaseToZodData(
        positionBriefQuery.data?.positionGeneralInformation?.confidentialityClause
      ),
      mobilityClause: databaseToZodData(
        positionBriefQuery.data?.positionGeneralInformation?.mobilityClause
      ),
      managerId: positionBriefQuery?.data?.positionGeneralInformation?.managerId ?? undefined,
      // Section Mobilité
      remoteAccepted: databaseToZodData(positionBriefQuery.data?.positionMobility?.remoteAccepted),
      remoteDays: positionBriefQuery.data?.positionMobility?.remoteDays?.toString() ?? null,
      remoteFrequencyValue:
        positionBriefQuery.data?.positionMobility?.remoteFrequencyValue?.toString() ?? null,
      remoteFrequencyUnit: positionBriefQuery.data?.positionMobility?.remoteFrequencyUnit,
      hasBusinessTrips: databaseToZodData(
        positionBriefQuery.data?.positionMobility?.hasBusinessTrips
      ),
      address: positionBriefQuery.data?.positionMobility?.address ?? {
        address: '',
        zipCode: '',
        cityName: '',
        inseeCode: '',
      },
      mobilityAreas: (positionBriefQuery.data?.positionMobility?.mobilityAreas ?? []) as string[],
      nationalMobility: databaseToZodData(
        positionBriefQuery.data?.positionMobility?.nationalMobility
      ),
      // Section Horaire
      timePackage: positionBriefQuery.data?.positionTime?.timePackage ?? undefined,
      timeDaysPerYear: positionBriefQuery.data?.positionTime?.timeDaysPerYear?.toString() ?? '',
      timeHoursPerWeek: {
        hours: positionBriefQuery.data?.positionTime?.timeHoursPerWeek?.hours ?? null,
        minutes: positionBriefQuery.data?.positionTime?.timeHoursPerWeek?.minutes ?? null,
      },
      timeWorkingHours: positionBriefQuery.data?.positionTime?.timeWorkingHours ?? [],
      timeRttCount: positionBriefQuery.data?.positionTime?.timeRttCount?.toString() ?? '',
      timeOnCallDuty: databaseToZodData(positionBriefQuery.data?.positionTime?.timeOnCallDuty),
      timeOnCallDutyDetails: positionBriefQuery.data?.positionTime?.timeOnCallDutyDetails ?? '',
      // Section Salaire
      salaryBasis: positionBriefQuery.data?.positionSalary?.salaryBasis,
      salaryFormat: positionBriefQuery.data?.positionSalary?.salaryFormat,
      minSalaryBasis: positionBriefQuery.data?.positionSalary?.minSalaryBasis?.toString() ?? null,
      maxSalaryBasis: positionBriefQuery.data?.positionSalary?.maxSalaryBasis?.toString() ?? null,
      salaryMonthCount:
        positionBriefQuery.data?.positionSalary?.salaryMonthCount?.toString() ?? null,
      restaurantTickets: databaseToZodData(
        positionBriefQuery.data?.positionSalary?.restaurantTickets
      ),
      positionAssets: positionBriefQuery.data?.positionSalary?.positionAssets ?? '',
      advantages: Object.values(OtherAdvantages).map(advantage => {
        return positionBriefQuery.data?.positionSalary?.advantages?.includes(advantage)
          ? {
              key: advantage,
              value: true,
            }
          : { key: advantage, value: false };
      }),
      travelFee1:
        positionBriefQuery.data?.positionSalary?.advantages?.includes(
          OtherAdvantages.TRAVEL_FEES
        ) && positionBriefQuery.data?.positionSalary?.advantages?.includes(TravelFee.AVANCE),
      travelFee2:
        positionBriefQuery.data?.positionSalary?.advantages?.includes(
          OtherAdvantages.TRAVEL_FEES
        ) && positionBriefQuery.data?.positionSalary?.advantages?.includes(TravelFee.CARD),
      healthCare: positionBriefQuery.data?.positionSalary?.healthCareValue ?? '',
      incentiveBonus: positionBriefQuery.data?.positionSalary?.incentiveBonusValue ?? '',
      incentiveFrequency: Object.values(EnumPositionBriefPositionSalaryIncentiveFrequency).find(
        key =>
          key ===
          (positionBriefQuery.data?.positionSalary?.incentiveFrequency
            ? EnumPositionBriefPositionSalaryIncentiveFrequency[
                positionBriefQuery.data.positionSalary.incentiveFrequency
              ]
            : undefined)
      ),
      attendanceBonus: positionBriefQuery.data?.positionSalary?.attendanceBonusValue ?? '',
      attendanceFrequency: Object.values(EnumPositionBriefPositionSalaryAttendanceFrequency).find(
        key =>
          key ===
          (positionBriefQuery.data?.positionSalary?.attendanceFrequency
            ? EnumPositionBriefPositionSalaryAttendanceFrequency[
                positionBriefQuery.data.positionSalary.attendanceFrequency
              ]
            : undefined)
      ),
    },
  });
  const { field: managerField } = useController({ control, name: 'managerId' });
  const { field: advantages } = useController({ control, name: 'advantages' });
  const { field: travelFee1 } = useController({ control, name: 'travelFee1' });
  const { field: travelFee2 } = useController({ control, name: 'travelFee2' });
  const healthCare = watch('healthCare');
  const incentiveBonus = watch('incentiveBonus');
  const attendanceFrequency = watch('attendanceFrequency');
  const incentiveFrequency = watch('incentiveFrequency');
  const attendanceBonus = watch('attendanceBonus');

  const positionInformationHandleSubmit = () =>
    handleSubmit(values => {
      updateMutation.mutate({
        id,
        modification: {
          changes: {
            ...values,
            positionGeneralInformation: {
              confidentialityClause: zodToDatabaseData(values.confidentialityClause),
              mobilityClause: zodToDatabaseData(values.mobilityClause),
              managerId: values.managerId ?? undefined,
              services: sanitizeString(values.services),
            },
            positionMobility: {
              remoteAccepted: zodToDatabaseData(values.remoteAccepted),
              remoteDays: parseInt(values.remoteDays ?? '') ?? null,
              remoteFrequencyValue: values.remoteFrequencyValue
                ? parseInt(values.remoteFrequencyValue)
                : undefined,
              remoteFrequencyUnit:
                RemoteFrequencyUnit[
                  remoteFrequencyUnitController.field.value as keyof typeof RemoteFrequencyUnit
                ],
              hasBusinessTrips: zodToDatabaseData(values.hasBusinessTrips),
              address: values.address ?? undefined,
              mobilityAreas: values.mobilityAreas ?? undefined,
              nationalMobility: zodToDatabaseData(values.nationalMobility),
            },
            positionTime: {
              timePackage: values.timePackage ?? undefined,
              timeDaysPerYear: parseInt(values.timeDaysPerYear ?? '') ?? null,
              timeHoursPerWeek: {
                hours: values.timeHoursPerWeek?.hours ?? undefined,
                minutes: values.timeHoursPerWeek?.minutes ?? undefined,
              },
              timeWorkingHours:
                values.timeWorkingHours.map(workingHour => {
                  return {
                    ...workingHour,
                    startTime: workingHour.startTime ?? undefined,
                    endTime: workingHour.endTime ?? undefined,
                  };
                }) ?? [],
              timeRttCount: parseInt(values.timeRttCount ?? '') ?? null,
              timeOnCallDuty: zodToDatabaseData(values.timeOnCallDuty),
              timeOnCallDutyDetails: values.timeOnCallDutyDetails ?? undefined,
            },
            positionSalary: {
              salaryBasis: values.salaryBasis ?? undefined,
              salaryFormat: values.salaryFormat ?? undefined,
              minSalaryBasis: parseInt(values.minSalaryBasis ?? '') ?? null,
              maxSalaryBasis: parseInt(values.maxSalaryBasis ?? '') ?? null,
              salaryMonthCount: parseInt(values.salaryMonthCount ?? '') ?? null,
              restaurantTickets: zodToDatabaseData(values.restaurantTickets),
              positionAssets: sanitizeString(values.positionAssets),
              advantages: values.advantages
                .filter(adv => adv.value)
                ?.map(adv =>
                  adv.key === OtherAdvantages.TRAVEL_FEES
                    ? values.travelFee1
                      ? values.travelFee2
                        ? `${adv.key},${TravelFee.AVANCE},${TravelFee.CARD}`
                        : `${adv.key},${TravelFee.AVANCE}`
                      : values.travelFee2
                      ? `${adv.key},${TravelFee.CARD}`
                      : adv.key
                    : adv.key
                )
                .join(','),
              healthCareValue: values.advantages.find(
                adv => adv.key === OtherAdvantages.HEALTH_CARE
              )?.value
                ? healthCare
                : '',
              incentiveBonusValue: values.advantages.find(
                adv => adv.key === OtherAdvantages.INCENTIVE_BONUS
              )?.value
                ? incentiveBonus
                : '',
              incentiveFrequency: values.advantages.find(
                adv => adv.key === OtherAdvantages.INCENTIVE_BONUS
              )?.value
                ? values.incentiveFrequency
                  ? EnumPositionBriefPositionSalaryIncentiveFrequency[
                      values.incentiveFrequency as EnumPositionBriefPositionSalaryIncentiveFrequency
                    ]
                  : undefined
                : undefined,
              attendanceBonusValue: values.advantages.find(
                adv => adv.key === OtherAdvantages.ATTENDANCE_BONUS
              )?.value
                ? attendanceBonus
                : '',
              attendanceFrequency: values.advantages.find(
                adv => adv.key === OtherAdvantages.ATTENDANCE_BONUS
              )?.value
                ? values.attendanceFrequency
                  ? EnumPositionBriefPositionSalaryAttendanceFrequency[
                      values.attendanceFrequency as EnumPositionBriefPositionSalaryAttendanceFrequency
                    ]
                  : undefined
                : undefined,
            },
          },
          _clear: {
            positionGeneralInformation: {
              managerId: managerField?.value?.length === 0,
              confidentialityClause: values.confidentialityClause === YesNoUnknownEnum.Unknown,
              mobilityClause: values.mobilityClause === YesNoUnknownEnum.Unknown,
            },
            positionMobility: {
              remoteAccepted: values.remoteAccepted === YesNoUnknownEnum.Unknown,
              remoteDays: values.remoteAccepted !== YesNoUnknownEnum.Yes,
              remoteFrequencyValue: values.hasBusinessTrips !== YesNoUnknownEnum.Yes,
              remoteFrequencyUnit: values.hasBusinessTrips !== YesNoUnknownEnum.Yes,
              address: values.nationalMobility === YesNoUnknownEnum.Yes,
              hasBusinessTrips: values.hasBusinessTrips === YesNoUnknownEnum.Unknown,
              nationalMobility: values.nationalMobility === YesNoUnknownEnum.Unknown,
              mobilityAreas:
                values.nationalMobility === YesNoUnknownEnum.Yes ||
                values.hasBusinessTrips !== YesNoUnknownEnum.Yes,
            },
            positionTime: {
              timeOnCallDuty: values.timeOnCallDuty === YesNoUnknownEnum.Unknown,
            },
            positionSalary: {
              restaurantTickets: values.restaurantTickets === YesNoUnknownEnum.Unknown,
            },
          },
        },
      });
    });
  const onCheckBoxesChange = (checkboxName: string, subBox?: TravelFee) => {
    const advantagesStates: { key: string; value: boolean }[] = advantages.value;
    let newValues: { key: string; value: boolean }[] = advantagesStates;

    if (subBox) {
      if (advantagesStates.find(adv => adv.key === OtherAdvantages.TRAVEL_FEES)?.key) {
        subBox === TravelFee.AVANCE
          ? setValue('travelFee1', !travelFee1.value)
          : setValue('travelFee2', !travelFee2.value);
      }
    } else {
      newValues = advantagesStates.map(adv =>
        adv.key === checkboxName
          ? { key: adv.key, value: !adv.value }
          : { key: adv.key, value: adv.value }
      );

      if (newValues.find(adv => adv.key === OtherAdvantages.TRAVEL_FEES)?.value === false) {
        setValue('travelFee1', false);
        setValue('travelFee2', false);
      }

      setValue('advantages', newValues);
    }

    positionInformationHandleSubmit()();
  };
  // Section Informations générales
  const services = watch('services');
  const confidentialityClause = watch('confidentialityClause');
  const mobilityClause = watch('mobilityClause');
  //Section Mobilité
  const remoteAccepted = watch('remoteAccepted');
  const remoteDays = watch('remoteDays');
  const mobilityAreasController = useController({ control, name: 'mobilityAreas' });
  const hasBusinessTrips = watch('hasBusinessTrips');
  const remoteFrequencyValue = watch('remoteFrequencyValue');
  const selectedMobilityAreas = watch('mobilityAreas');
  const addressController = useController({ control, name: 'address' });
  const nationalMobility = watch('nationalMobility');
  const positionAssets = watch('positionAssets');

  const timeDaysPerYear = watch('timeDaysPerYear');
  const salaryBasis = watch('salaryBasis');
  const salaryFormat = watch('salaryFormat');
  const salaryMonthCount = watch('salaryMonthCount');
  const minSalaryBasis = watch('minSalaryBasis');
  const maxSalaryBasis = watch('maxSalaryBasis');
  const restaurantTickets = watch('restaurantTickets');

  const remoteFrequencyUnitController = useController({
    name: 'remoteFrequencyUnit',
    control: control,
  });

  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  const isBriefSigned = useMemo(() => {
    return positionBriefQuery.data?.signatures !== null;
  }, [positionBriefQuery.data]);

  useEffect(() => {
    if (isDirty || isSubmitted) {
      positionInformationHandleSubmit()();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Section Informations générales
    confidentialityClause,
    mobilityClause,
    managerField.value,
    // Section Mobilité
    hasBusinessTrips,
    remoteAccepted,
    remoteDays,
    addressController.field.value,
    selectedMobilityAreas,
    nationalMobility,
    remoteFrequencyUnitController.field.value,
  ]);

  useEffect(() => {
    if ((isDirty || isSubmitted) && nationalMobility && !isBriefSigned) {
      setValue('mobilityAreas', []);
      setValue('address', { address: undefined, cityName: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //Section mobilité
    nationalMobility,
  ]);

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue('services', positionBriefQuery.data?.positionGeneralInformation?.services ?? '');
      setValue(
        'confidentialityClause',
        databaseToZodData(
          positionBriefQuery.data?.positionGeneralInformation?.confidentialityClause
        )
      );
      setValue(
        'mobilityClause',
        databaseToZodData(positionBriefQuery.data?.positionGeneralInformation?.mobilityClause)
      );
      setValue(
        'remoteAccepted',
        databaseToZodData(positionBriefQuery.data?.positionMobility?.remoteAccepted)
      );
      setValue('remoteDays', positionBriefQuery.data?.positionMobility?.remoteDays?.toString());
      setValue(
        'hasBusinessTrips',
        databaseToZodData(positionBriefQuery.data?.positionMobility?.hasBusinessTrips)
      );
      setValue(
        'remoteFrequencyValue',
        positionBriefQuery.data?.positionMobility?.remoteFrequencyValue?.toString() ?? ''
      );
      setValue(
        'nationalMobility',
        databaseToZodData(positionBriefQuery.data?.positionMobility?.nationalMobility)
      );
      setValue(
        'timeDaysPerYear',
        positionBriefQuery.data?.positionTime?.timeDaysPerYear?.toString()
      );
      setValue('salaryBasis', positionBriefQuery.data?.positionSalary?.salaryBasis);
      setValue('salaryFormat', positionBriefQuery.data?.positionSalary?.salaryFormat);
      setValue(
        'salaryMonthCount',
        positionBriefQuery.data?.positionSalary?.salaryMonthCount?.toString() ?? ''
      );
      setValue(
        'minSalaryBasis',
        positionBriefQuery.data?.positionSalary?.minSalaryBasis?.toString() ?? ''
      );
      setValue(
        'maxSalaryBasis',
        positionBriefQuery.data?.positionSalary?.maxSalaryBasis?.toString() ?? ''
      );
      setValue(
        'restaurantTickets',
        databaseToZodData(positionBriefQuery.data?.positionSalary?.restaurantTickets)
      );
      setValue('positionAssets', positionBriefQuery.data?.positionSalary?.positionAssets ?? '');
      setValue(
        'incentiveBonus',
        positionBriefQuery.data?.positionSalary?.incentiveBonusValue ?? ''
      );
      setValue(
        'attendanceBonus',
        positionBriefQuery.data?.positionSalary?.attendanceBonusValue ?? ''
      );
      setValue('healthCare', positionBriefQuery.data?.positionSalary?.healthCareValue ?? '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    services,
    confidentialityClause,
    mobilityClause,
    remoteAccepted,
    remoteDays,
    hasBusinessTrips,
    remoteFrequencyValue,
    nationalMobility,
    timeDaysPerYear,
    salaryBasis,
    salaryFormat,
    salaryMonthCount,
    minSalaryBasis,
    maxSalaryBasis,
    restaurantTickets,
    positionAssets,
    incentiveBonus,
    attendanceBonus,
    healthCare,
  ]);

  return (
    <div className={styles.container}>
      {globalFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.title}>détails du poste</div>
          <TextAreaWithLightTitle
            title="à quel(s) service(s) le poste est-il rattaché ?"
            name="services"
            counterClassName={styles.counter}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={services.length}
            control={control}
            onBlurCapture={positionInformationHandleSubmit()}
          />
          <WithLightTitle title="nom du responsable" className={styles.managerPicker}>
            <ManagerPicker
              onChange={contact => managerField.onChange(contact?.contactId ?? '')}
              selectedContactId={managerField.value ?? undefined}
              isBriefSigned={isBriefSigned}
              setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
            />
          </WithLightTitle>
          <WithLightTitle
            title="une clause de confidentialité est-elle demandée pour ce poste ?"
            className={styles.tripleChoiceContainer}
          >
            <TripleChoiceButton control={control} name="confidentialityClause" />
          </WithLightTitle>
          <WithLightTitle
            title="une clause de mobilité est-elle demandée pour ce poste ?"
            className={classnames(styles.tripleChoiceContainer, styles.pageSubPart)}
          >
            <TripleChoiceButton control={control} name="mobilityClause" />
          </WithLightTitle>
          <div className={styles.separator}></div>
          <div className={styles.title}>mobilité</div>
          <WithLightTitle
            title="télétravail accepté pour ce poste ?"
            className={styles.tripleChoiceContainer}
          >
            <TripleChoiceButton control={control} name="remoteAccepted" />
          </WithLightTitle>
          {remoteAccepted === YesNoUnknownEnum.Yes && (
            <WithLightTitle
              title="nombre de jours de télétravail par semaine"
              className={styles.remoteDaysContainer}
            >
              <div className={styles.remoteInput}>
                <TextInput
                  key="remoteDays"
                  type="number"
                  placeholder="jours"
                  control={control}
                  name="remoteDays"
                  min={0}
                  max={7}
                />
              </div>
            </WithLightTitle>
          )}
          <WithLightTitle
            title="poste soumis à des déplacements ?"
            className={styles.tripleChoiceContainer}
          >
            <TripleChoiceButton control={control} name="hasBusinessTrips" />
          </WithLightTitle>
          {hasBusinessTrips === YesNoUnknownEnum.Yes && nationalMobility !== YesNoUnknownEnum.Yes && (
            <div>
              <div className={styles.mobility}>
                <MobilityAreasSelection
                  selectedMobilityAreas={mobilityAreasController.field.value ?? []}
                  setSelectedMobilityAreas={mobilityAreasController.field.onChange}
                  isBriefSigned={isBriefSigned}
                  setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
                />
              </div>
              <WithLightTitle title="fréquence">
                <div className={styles.movingDaysContainer}>
                  <TextInput
                    containerClassName={styles.movingDaysInput}
                    placeholder="jours"
                    control={control}
                    name="remoteFrequencyValue"
                    type="number"
                    onBlurCapture={positionInformationHandleSubmit()}
                    min={0}
                  />
                  <div className={styles.byDiv}>par</div>
                  <div
                    className={styles.unitDropDown}
                    onMouseDown={() => {
                      if (isBriefSigned) {
                        setIsEditAfterSigningModalOpen(true);
                      }
                    }}
                  >
                    <DropDown
                      placeholder={'unité'}
                      items={remoteFrequencyUnitValues}
                      canBeReset
                      keyValueExtractor={(openPositionDuration: FrequencyUnitDurationTypes) => ({
                        key: openPositionDuration.key,
                        value: openPositionDuration.label,
                      })}
                      onSelectItem={(openPositionDuration: FrequencyUnitDurationTypes) => {
                        remoteFrequencyUnitController.field.onChange(
                          openPositionDuration ? openPositionDuration.key : 'unité'
                        );
                      }}
                      selectedItem={remoteFrequencyUnitValues.find(
                        remoteFrequencyUnit =>
                          remoteFrequencyUnit.key === remoteFrequencyUnitController.field.value
                      )}
                    />
                  </div>
                </div>
              </WithLightTitle>
            </div>
          )}
          <WithLightTitle
            title="option mobilité nationale"
            className={styles.tripleChoiceContainer}
          >
            <TripleChoiceButton control={control} name="nationalMobility" />
          </WithLightTitle>
          {nationalMobility !== YesNoUnknownEnum.Yes && (
            <AddressSelection
              companyId={positionBriefQuery?.data?.companyId ?? ''}
              selectedAddress={addressController.field.value}
              setSelectedAddress={addressController.field.onChange}
              isBriefSigned={isBriefSigned}
              setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
            />
          )}
          <div className={styles.separator}></div>
          <div className={styles.title}>horaire / temps de travail</div>
          <TimeContext.Provider
            value={{
              control,
              watch,
              setValue,
              positionInformationHandleSubmit,
            }}
          >
            <TimeSelection
              positionBrief={positionBriefQuery.data}
              setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
            />
          </TimeContext.Provider>
          <div className={styles.separator}></div>
          <div className={styles.title}>montant salaire</div>
          <SalarySelection
            control={control}
            watch={watch}
            onControlChange={() => {
              if (isDirty || isSubmitted) {
                positionInformationHandleSubmit()();
              }
            }}
          />
          <div className={styles.separator}></div>
          <div className={styles.title}>variable</div>
          {variablesQuery.data !== undefined && variablesQuery.data.length > 0 && (
            <WithLightTitle
              title="vos variables pour ce poste"
              rightTitleComponent={
                (variablesQuery.data?.length ?? 0) > 0 && (
                  <div className={styles.selectedVariablesOrBonuses}>
                    {pluralFormat(variablesQuery.data?.length, 'ajoutée')}
                  </div>
                )
              }
            >
              {variablesQuery.data?.map(variable => {
                return (
                  <HorizontalTextIconCard
                    containerClassName={styles.bonusesAndVariablesCard}
                    textContainerClassName={styles.textContainerClassName}
                    leftIcon={<BDPVariableIcon />}
                    key={variable.id}
                    label={`${variable.name}`}
                    subLabel={`type : 
                        ${
                          variableTypeValues.find(
                            variableType => variableType.key === variable.type
                          )?.label
                        } 
                        ${
                          variable.description !== undefined && variable.description.length > 0
                            ? ' | description : ' + variable.description
                            : ''
                        }`}
                    rightIcon={<Trashcan />}
                    onRightIconClick={() => {
                      if (!isBriefSigned) {
                        deleteVariableMutation.mutate(variable.id);
                      } else {
                        setIsEditAfterSigningModalOpen(true);
                      }
                    }}
                  />
                );
              })}
            </WithLightTitle>
          )}
          <div
            onMouseDown={() => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              }
            }}
          >
            <VariableModal positionBrief={id} />
          </div>
          <div className={styles.separator}></div>
          <div className={styles.title}>primes</div>
          {bonusesQuery.data !== undefined && bonusesQuery.data.length > 0 && (
            <WithLightTitle
              title="vos primes pour ce poste"
              rightTitleComponent={
                (bonusesQuery.data?.length ?? 0) > 0 && (
                  <div className={styles.selectedVariablesOrBonuses}>
                    {pluralFormat(bonusesQuery.data?.length, 'ajoutée')}
                  </div>
                )
              }
            >
              {bonusesQuery.data?.map(bonus => {
                return (
                  <HorizontalTextIconCard
                    containerClassName={styles.bonusesAndVariablesCard}
                    textContainerClassName={styles.textContainerClassName}
                    leftIcon={<BDPBonusIcon />}
                    key={bonus.id}
                    label={`${bonus.name}`}
                    subLabel={`montant : 
                        ${
                          bonus.amount === null
                            ? 'montant indéfini'
                            : bonus.amount +
                              (bonus.bonusType === 'AMOUNT'
                                ? ' €'
                                : ' % | sur : ' +
                                  bonusFrequencyPercentageValues.find(
                                    bonusReference => bonusReference.key === bonus.reference
                                  )?.label)
                        }
                        ${
                          bonus.frequency !== null && bonus.frequency !== undefined
                            ? '| fréquence : ' +
                              bonusFrequencyAmountValues.find(
                                bonusFrequency => bonusFrequency.key === bonus.frequency
                              )?.label
                            : ''
                        } 
                        ${
                          bonus.description !== undefined && bonus.description.length > 0
                            ? ' | description : ' + bonus.description
                            : ''
                        }`}
                    rightComponent={
                      <div
                        onMouseDown={() => {
                          if (positionBriefQuery.data?.signatures !== undefined) {
                            setIsEditAfterSigningModalOpen(true);
                          }
                        }}
                      >
                        <BonusActions bonus={bonus} />
                      </div>
                    }
                  />
                );
              })}
            </WithLightTitle>
          )}
          <AddButton
            className={styles.addButton}
            icon={<PlusCircle className={styles.addButtonIcon} />}
            text="ajouter une prime"
            onClick={() => {
              if (!isBriefSigned) {
                setIsBonusModalOpen(true);
              } else {
                setIsEditAfterSigningModalOpen(true);
              }
            }}
          />
          <BonusModal
            positionBrief={id}
            isOpen={isBonusModalOpen}
            setIsOpen={setIsBonusModalOpen}
          />
          <div className={styles.separator}></div>
          <div className={styles.title}>avantages</div>
          <WithLightTitle title={'avantages et primes'}>
            <div className={styles.checkBoxContainer}>
              <div>
                {Object.values(OtherAdvantages).map((advantage, index) => {
                  return index < 8 ? (
                    <>
                      <div key={index} className={styles.checkBox}>
                        <Checkbox
                          key={index + advantage}
                          onChange={() => {
                            if (isBriefSigned) {
                              setIsEditAfterSigningModalOpen(true);
                            } else {
                              onCheckBoxesChange(advantage);
                            }
                          }}
                          checked={advantages.value.find(adv => adv.key === advantage)?.value}
                        />
                        {advantage}
                      </div>
                      <div>
                        {advantage === OtherAdvantages.TRAVEL_FEES &&
                          advantages.value.find(adv => adv.key === OtherAdvantages.TRAVEL_FEES)
                            ?.value && (
                            <>
                              <div className={styles.subcheckBox}>
                                <Checkbox
                                  key={index + advantage + TravelFee.AVANCE}
                                  onChange={() => {
                                    if (isBriefSigned) {
                                      setIsEditAfterSigningModalOpen(true);
                                    } else {
                                      onCheckBoxesChange(advantage, TravelFee.AVANCE);
                                    }
                                  }}
                                  checked={
                                    advantages.value.find(adv => adv.key === advantage)?.value &&
                                    travelFee1.value
                                  }
                                />
                                {TravelFee.AVANCE}
                              </div>
                              <div className={styles.subcheckBox}>
                                <Checkbox
                                  key={index + advantage + TravelFee.CARD}
                                  onChange={() => {
                                    if (isBriefSigned) {
                                      setIsEditAfterSigningModalOpen(true);
                                    } else {
                                      onCheckBoxesChange(advantage, TravelFee.CARD);
                                    }
                                  }}
                                  checked={
                                    advantages.value.find(adv => adv.key === advantage)?.value &&
                                    travelFee2.value
                                  }
                                />
                                {TravelFee.CARD}
                              </div>
                            </>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  );
                })}
              </div>
              <div>
                {Object.values(OtherAdvantages).map((advantage, index) => {
                  return index > 7 ? (
                    <>
                      <div key={index} className={styles.checkBox}>
                        <Checkbox
                          key={index + advantage}
                          onChange={() => {
                            if (isBriefSigned) {
                              setIsEditAfterSigningModalOpen(true);
                            } else {
                              onCheckBoxesChange(advantage);
                            }
                          }}
                          checked={advantages.value.find(adv => adv.key === advantage)?.value}
                        />
                        {advantage}
                      </div>
                      <div>
                        {advantage === OtherAdvantages.HEALTH_CARE &&
                          advantages.value.find(adv => adv.key === OtherAdvantages.HEALTH_CARE)
                            ?.value && (
                            <WithLightTitle
                              title="prise en charge de la famille en %"
                              className={styles.healthInsurance}
                            >
                              <TextInput
                                name="healthCare"
                                control={control}
                                onBlurCapture={positionInformationHandleSubmit()}
                                containerClassName={styles.containerHealth}
                                className={styles.insideText}
                                type="number"
                                min={0}
                                max={100}
                              />
                            </WithLightTitle>
                          )}

                        {((advantage === OtherAdvantages.INCENTIVE_BONUS &&
                          advantages.value.find(adv => adv.key === OtherAdvantages.INCENTIVE_BONUS)
                            ?.value) ||
                          (advantage === OtherAdvantages.ATTENDANCE_BONUS &&
                            advantages.value.find(
                              adv => adv.key === OtherAdvantages.ATTENDANCE_BONUS
                            )?.value)) && (
                          <div className={styles.containerTextCheckBox}>
                            <WithLightTitle title="valeur en €" className={styles.valueEur}>
                              <TextInput
                                name={
                                  advantage === OtherAdvantages.ATTENDANCE_BONUS
                                    ? 'attendanceBonus'
                                    : 'incentiveBonus'
                                }
                                type="number"
                                control={control}
                                className={styles.insideText}
                                onBlurCapture={positionInformationHandleSubmit()}
                                min={0}
                              />
                            </WithLightTitle>

                            <WithLightTitle title="fréquence" className={styles.frequency}>
                              <DropDown
                                placeholder={'fréquence'}
                                items={timeBonusFrequencyAmountValues}
                                className={styles.frequencyDrop}
                                canBeReset
                                keyValueExtractor={(bonusFrequency: BonusFrequencyTypes) => ({
                                  key: bonusFrequency.key,
                                  value: bonusFrequency.label,
                                })}
                                onSelectItem={(frequency: BonusFrequencyTypes) => {
                                  if (isBriefSigned) {
                                    setIsEditAfterSigningModalOpen(true);
                                  } else {
                                    advantage === OtherAdvantages.ATTENDANCE_BONUS
                                      ? setValue(
                                          'attendanceFrequency',
                                          frequency
                                            ? Object.values(
                                                EnumPositionBriefPositionSalaryAttendanceFrequency
                                              ).find(key => key === frequency.key)
                                            : frequency
                                        )
                                      : setValue(
                                          'incentiveFrequency',
                                          frequency
                                            ? Object.values(
                                                EnumPositionBriefPositionSalaryIncentiveFrequency
                                              ).find(key => key === frequency.key)
                                            : frequency
                                        );

                                    positionInformationHandleSubmit()();
                                  }
                                }}
                                selectedItem={timeBonusFrequencyAmountValues.find(
                                  bonusFrequency =>
                                    bonusFrequency.key ===
                                    (advantage === OtherAdvantages.ATTENDANCE_BONUS
                                      ? attendanceFrequency
                                      : incentiveFrequency)
                                )}
                              />
                            </WithLightTitle>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  );
                })}
              </div>
            </div>
          </WithLightTitle>
          <div className={styles.separator}></div>
          <div className={styles.title}>atouts du poste</div>
          <TextAreaWithLightTitle
            title="décrivez quelques atouts spécifiques au poste"
            name="positionAssets"
            counterClassName={styles.counter}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={positionAssets.length}
            control={control}
            onBlurCapture={positionInformationHandleSubmit()}
          />
          <EditAfterSigningModal
            isOpen={isEditAfterSigningModalOpen}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
        </>
      )}
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des informations générales du poste" />
      )}
    </div>
  );
};

export default PositionInformation;
