import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';

import { IllusBDPNoBrief } from 'src/Assets';
import BriefActions from 'src/Components/BriefActions';
import PositionBriefCard from 'src/Components/BriefStudyCard';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchPositionBriefs } from 'src/Hooks/PositionBriefs/useFetchPositionBriefs';
import { getEditionRoute } from 'src/Services/Routing';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './PositionBriefList.module.scss';
import { Props } from './PositionBriefList.types';

const PositionBriefList = ({ companyId }: Props) => {
  const history = useHistory();

  const {
    isSuccess,
    isLoading,
    isError,
    data: positionBriefsPages,
    fetchNextPage,
    isFetchingNextPage,
  } = useFetchPositionBriefs({
    companyId,
  });
  const { ref, inView } = useInView();
  const positionBriefsNumber = positionBriefsPages?.pages[0].count ?? 0;
  useEffect(() => {
    if (
      inView &&
      !isFetchingNextPage &&
      positionBriefsPages?.pages.flat(1).length !== undefined &&
      positionBriefsNumber > positionBriefsPages?.pages.flat(1).length
    ) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, positionBriefsPages]);

  return (
    <div>
      {isLoading && (
        <>
          <div className={styles.numberOfPositionBriefs}>
            <ContentLoader
              height="2rem"
              width="12rem"
              uniqueKey="bdpNumber"
              backgroundColor="#d7d7d7"
            >
              <rect x="0%" y="10" rx="4" ry="4" width="100%" height="15" />
            </ContentLoader>
          </div>

          {new Array(5).fill(undefined).map((_, index) => (
            <PositionBriefCard.Loading key={`ps-card-loading-${index}`} />
          ))}
        </>
      )}

      {isError && <ErrorMessage message="Une erreur est survenue dans la récupération des BDP" />}

      {isSuccess && (
        <>
          <div className={styles.numberOfPositionBriefs}>
            {pluralFormat(positionBriefsNumber, 'BDP enregistré')}
          </div>
          {positionBriefsNumber < 1 && <IllusBDPNoBrief className={styles.noBriefImg} />}
          {positionBriefsPages?.pages.map(positionBriefGroup =>
            positionBriefGroup.positionBriefs.map(positionBrief => (
              <PositionBriefCard
                title={positionBrief.name ?? ''}
                createdAt={positionBrief.createdAt}
                updatedAt={positionBrief.updatedAt}
                service={positionBrief.company?.companyName}
                rightActions={
                  <BriefActions
                    briefId={positionBrief.id}
                    briefName={positionBrief.name}
                    companyId={positionBrief.company?.companyId}
                  />
                }
                onClick={() => {
                  history.push(
                    getEditionRoute({
                      companyId: positionBrief.company?.companyId,
                      positionBriefId: positionBrief.id?.toString(),
                      section: 'company/information',
                    })
                  );
                }}
              />
            ))
          )}
          <div className={styles.displayMore} ref={ref}>
            {isFetchingNextPage && <PositionBriefCard.Loading key={`ps-card-loading-load-more`} />}
          </div>
        </>
      )}
    </div>
  );
};

export default PositionBriefList;
