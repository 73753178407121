import { DropDown, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  TextAreaWithLightTitle,
  TextInput,
  TripleChoiceButton,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import EditAfterSigningModal from 'src/Containers/Modals/EditAfterSigningModal';
import ContractTypeSelection from 'src/Containers/Position/PositionInformation/ContractTypeSelection/index';
import { useFetchPositionBriefDetails } from 'src/Hooks/PositionBriefs/useFetchPositionBriefDetails';
import { useUpdatePositionBrief } from 'src/Hooks/PositionBriefs/useUpdatePositionBrief';
import { useFetchPositionMotives } from 'src/Hooks/Repositories/useFetchPositionMotives';
import { FETCH_STATUS } from 'src/Redux/Types';
import { OpenPositionDurationUnit, PositionMotive } from 'src/Services/API';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/Async';
import { sanitizeString } from 'src/Utils/sanitizeString';
import { YesNoUnknownEnum } from 'src/Utils/yesNoUnknownEnum';
import { databaseToZodData, zodToDatabaseData } from 'src/Utils/ZodDatabaseTransformer';

import StatusesSelection from '../PositionInformation/StatusesSelection';

import styles from './Context.module.scss';
import { ContextSchema } from './Context.schema';
import { OpenPositionDurationTypes } from './Context.types';
import { openPositionDurationValues } from './utils';

const CDD_STATUS = 4;
const CDI_STATUS = 5;

const Context = () => {
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const id = parseInt(positionBriefId);
  const positionBriefQuery = useFetchPositionBriefDetails(id);
  const fetchPositionMotives = useFetchPositionMotives(
    positionBriefQuery?.data?.positionContextDetails?.contractType?.key
  );
  const globalFetchStatus = mergeFetchStatus(
    toFetchStatus(positionBriefQuery),
    toFetchStatus(fetchPositionMotives)
  );
  const updateMutation = useUpdatePositionBrief();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty, isSubmitted },
  } = useFormWithZodResolver({
    schema: ContextSchema,
    defaultValues: {
      contractStatus:
        positionBriefQuery?.data?.positionContextDetails?.contractStatus?.id ?? undefined,
      contractType: positionBriefQuery?.data?.positionContextDetails?.contractType?.id ?? undefined,
      trialPeriodDuration:
        positionBriefQuery?.data?.positionContextDetails?.trialPeriodDuration ?? '',
      cddDuration: positionBriefQuery?.data?.positionContextDetails?.cddDuration?.toString() ?? '',
      tenurable: databaseToZodData(positionBriefQuery?.data?.positionContextDetails?.tenurable),
      positionMotiveId: positionBriefQuery?.data?.positionContextDetails?.positionMotive?.id,
      recruitmentReason: positionBriefQuery.data?.positionContextDetails?.recruitmentReason ?? '',
      recruitmentOpenPositionDuration:
        positionBriefQuery.data?.positionContextDetails?.recruitmentOpenPositionDuration?.toString() ??
        '',
      openPositionDurationUnit:
        positionBriefQuery.data?.positionContextDetails?.openPositionDurationUnit,
      difficulties: positionBriefQuery.data?.positionContextDetails?.difficulties ?? '',
    },
  });
  const positionMotiveZodController = useController({
    name: 'positionMotiveId',
    control: control,
  });
  const openPositionDurationUnitController = useController({
    name: 'openPositionDurationUnit',
    control: control,
  });

  const [isEditAfterSigningModalOpen, setIsEditAfterSigningModalOpen] = useState(false);

  const isBriefSigned = useMemo(() => {
    return positionBriefQuery.data?.signatures !== null;
  }, [positionBriefQuery.data]);

  const contextHandleSubmit = () =>
    handleSubmit(values => {
      const isTenurable = zodToDatabaseData(values.tenurable);
      updateMutation.mutate({
        id,
        modification: {
          changes: {
            ...values,
            positionContextDetails: {
              contractType: values.contractType
                ? {
                    id: values.contractType,
                  }
                : undefined,
              contractStatus: values.contractStatus
                ? {
                    id: values.contractStatus,
                  }
                : undefined,
              trialPeriodDuration:
                contractType === CDD_STATUS || contractType === 5
                  ? values.trialPeriodDuration
                  : undefined,
              cddDuration:
                contractType === CDD_STATUS ? parseInt(values.cddDuration ?? '') : undefined,
              tenurable: isTenurable,
              positionMotive: values.positionMotiveId
                ? {
                    id: values.positionMotiveId as number,
                  }
                : undefined,
              recruitmentReason: sanitizeString(values.recruitmentReason),
              recruitmentOpenPositionDuration: parseInt(
                values.recruitmentOpenPositionDuration ?? ''
              ),
              openPositionDurationUnit:
                OpenPositionDurationUnit[
                  openPositionDurationUnitController.field
                    .value as keyof typeof OpenPositionDurationUnit
                ],
              difficulties: sanitizeString(values.difficulties),
            },
          },
          _clear: {
            positionContextDetails: {
              contractType: !values.contractType,
              contractStatus: !values.contractStatus,
              trialPeriodDuration: !(
                values.contractType === CDD_STATUS || values.contractType === CDI_STATUS
              ),
              cddDuration: !(values.contractType === CDD_STATUS),
              // Onglet Contexte : Si le type de contrat n'est pas CDD le champ tenurable (titularisable) est fixé à null
              tenurable:
                values.contractType !== CDD_STATUS || values.tenurable === YesNoUnknownEnum.Unknown,
              positionMotive: values.positionMotiveId === 0,
            },
          },
        },
      });
    });

  const contractStatus = watch('contractStatus');
  const contractType = watch('contractType');
  const trialPeriodDuration = watch('trialPeriodDuration');
  const cddDuration = watch('cddDuration');
  const tenurable = watch('tenurable');
  const positionMotiveId = watch('positionMotiveId');
  const recruitmentReason = watch('recruitmentReason');
  const recruitmentOpenPositionDuration = watch('recruitmentOpenPositionDuration');
  const difficulties = watch('difficulties');

  useEffect(() => {
    if (isDirty || isSubmitted) {
      contextHandleSubmit()();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contractType,
    contractStatus,
    tenurable,
    positionMotiveZodController.field.value,
    openPositionDurationUnitController.field.value,
  ]);

  useEffect(() => {
    if (isBriefSigned && isDirty) {
      setIsEditAfterSigningModalOpen(true);
      setValue(
        'contractStatus',
        positionBriefQuery?.data?.positionContextDetails?.contractStatus?.id ?? undefined
      );
      setValue(
        'contractType',
        positionBriefQuery?.data?.positionContextDetails?.contractType?.id ?? undefined
      );
      setValue(
        'trialPeriodDuration',
        positionBriefQuery?.data?.positionContextDetails?.trialPeriodDuration ?? ''
      );
      setValue(
        'cddDuration',
        positionBriefQuery?.data?.positionContextDetails?.cddDuration?.toString() ?? ''
      );
      setValue(
        'tenurable',
        databaseToZodData(positionBriefQuery?.data?.positionContextDetails?.tenurable)
      );
      setValue(
        'positionMotiveId',
        positionBriefQuery?.data?.positionContextDetails?.positionMotive?.id
      );
      setValue(
        'recruitmentReason',
        positionBriefQuery.data?.positionContextDetails?.recruitmentReason ?? ''
      );
      setValue(
        'recruitmentOpenPositionDuration',
        positionBriefQuery.data?.positionContextDetails?.recruitmentOpenPositionDuration?.toString() ??
          ''
      );
      setValue('difficulties', positionBriefQuery.data?.positionContextDetails?.difficulties ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contractStatus,
    contractType,
    trialPeriodDuration,
    cddDuration,
    tenurable,
    positionMotiveId,
    recruitmentOpenPositionDuration,
    recruitmentReason,
    difficulties,
  ]);

  return (
    <div className={styles.container}>
      {globalFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.title}>votre choix</div>
          <div className={styles.contractContainer}>
            <ContractTypeSelection name="contractType" control={control} />
            {(contractType === CDD_STATUS || contractType === CDI_STATUS) && (
              <div className={styles.trialPeriodContainer}>
                <WithLightTitle title="durée de la période d'essai">
                  <div className={styles.inputNumberContainer}>
                    <TextInput
                      className={styles.inputNumber}
                      onBlurCapture={contextHandleSubmit()}
                      name="trialPeriodDuration"
                      control={control}
                      type="number"
                      placeholder="en mois"
                      min={0}
                      max={contractType === CDD_STATUS ? 1 : 8}
                    />
                  </div>
                </WithLightTitle>
              </div>
            )}
            {contractType === CDD_STATUS && (
              <div className={styles.cddDurationContainer}>
                <WithLightTitle title="durée du CDD">
                  <div className={styles.inputNumberContainer}>
                    <TextInput
                      className={styles.inputNumber}
                      onBlurCapture={contextHandleSubmit()}
                      name="cddDuration"
                      control={control}
                      type="number"
                      placeholder="en mois"
                      min={0}
                      max={72}
                    />
                  </div>
                </WithLightTitle>
              </div>
            )}
          </div>
          {contractType === CDD_STATUS && (
            <div className={styles.subsection}>
              <p className={styles.label}>ce poste est-il titularisable ?</p>
              <TripleChoiceButton name="tenurable" control={control} />
            </div>
          )}
          <div className={styles.statusContainer}>
            <StatusesSelection name="contractStatus" control={control} />
          </div>
          {contractType && [CDD_STATUS, CDI_STATUS].includes(contractType) && (
            <div className={styles.dropDownContainer}>
              <WithLightTitle title="motif d'ouverture de poste">
                <div className={styles.motiveDropDown}>
                  <DropDown
                    placeholder="choisir un motif"
                    items={fetchPositionMotives.data ?? []}
                    keyValueExtractor={(positionMotive: PositionMotive) => ({
                      key: positionMotive.id,
                      value: positionMotive.label,
                    })}
                    canBeReset
                    onSelectItem={(positionMotive: PositionMotive) => {
                      positionMotiveZodController.field.onChange(
                        positionMotive ? positionMotive.id : 0
                      );
                    }}
                    selectedItem={
                      fetchPositionMotives.data
                        ? fetchPositionMotives.data.find(
                            positionMotive =>
                              positionMotive.id === positionMotiveZodController.field.value
                          )
                        : undefined
                    }
                  />
                </div>
              </WithLightTitle>
            </div>
          )}

          <div className={styles.separator} />

          <TextAreaWithLightTitle
            title="pourquoi le poste est ouvert, que souhaite t-on recruter ?"
            name="recruitmentReason"
            counterClassName={styles.charCountLabel}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={recruitmentReason.length}
            control={control}
            onBlurCapture={contextHandleSubmit()}
          />

          <WithLightTitle title="depuis combien de temps le poste est ouvert ?">
            <div className={styles.openDurationContainer}>
              <div className={styles.inputNumberContainer}>
                <TextInput
                  className={styles.inputNumber}
                  onBlurCapture={contextHandleSubmit()}
                  name="recruitmentOpenPositionDuration"
                  control={control}
                  type="number"
                  min={0}
                  placeholder=""
                />
              </div>
              <div
                className={styles.unitDropDown}
                onMouseDown={() => {
                  if (isBriefSigned) {
                    setIsEditAfterSigningModalOpen(true);
                  }
                }}
              >
                <DropDown
                  placeholder={'unité'}
                  items={openPositionDurationValues}
                  canBeReset
                  keyValueExtractor={(openPositionDuration: OpenPositionDurationTypes) => ({
                    key: openPositionDuration.key,
                    value: openPositionDuration.label,
                  })}
                  onSelectItem={(openPositionDuration: OpenPositionDurationTypes) => {
                    openPositionDurationUnitController.field.onChange(
                      openPositionDuration ? openPositionDuration.key : 'unité'
                    );
                  }}
                  selectedItem={openPositionDurationValues.find(
                    openPositionDuration =>
                      openPositionDuration.key === openPositionDurationUnitController.field.value
                  )}
                />
              </div>
            </div>
          </WithLightTitle>

          <div className={styles.separator} />
          <TextAreaWithLightTitle
            title="quelles sont les difficultés identifiées ?"
            name="difficulties"
            counterClassName={styles.charCountLabel}
            textAreaClassName={styles.textArea}
            placeholder="en quelques mots..."
            maxLength={4000}
            valueLength={difficulties.length}
            control={control}
            onBlurCapture={contextHandleSubmit()}
          />
          <EditAfterSigningModal
            isOpen={isEditAfterSigningModalOpen}
            setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
          />
        </>
      )}
      {globalFetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="20rem" width="100%" uniqueKey="contextLoader">
          <rect x="0%" y="5" rx="4" ry="4" width="30%" height="15" />
          <rect x="0%" y="35" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="55" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="0%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="30%" y="85" rx="4" ry="4" width="5%" height="15" />
          <rect x="30%" y="105" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="135" rx="4" ry="4" width="20%" height="15" />
          <rect x="0%" y="155" rx="4" ry="4" width="20%" height="15" />
        </ContentLoader>
      )}
      {globalFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération du contexte du poste" />
      )}
    </div>
  );
};

export default Context;
