import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const DebugTools = ({ children }: { children: JSX.Element }) => {
  const history = useHistory();

  // Basic print of navigation
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      history.listen((location, action) => {
        console.groupCollapsed('History action:', action);
        console.log(JSON.stringify(location, null, 2));
        console.groupEnd();
      });
    }
  }, [history]);

  return <>{children}</>;
};

export default DebugTools;
