import { useQuery, UseQueryOptions } from 'react-query';

import { KeyPerson, KeyPersonsService } from 'src/Services/API';

import { QueryKeys } from '../types';

const useFetchKeyPersons = (
  positionBrief: number,
  extraOptions?: UseQueryOptions<unknown, unknown, KeyPerson[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchKeyPersons, positionBrief.toString()],
    () => {
      return KeyPersonsService.keyPersonsControllerFetchKeyPersons({ positionBrief });
    },
    {
      ...extraOptions,
    }
  );
};

export default useFetchKeyPersons;
