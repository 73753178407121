import { Button, FetchButton, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { IllusBDPWarning } from 'src/Assets';
import { useEmptySignatures } from 'src/Hooks/Signatures/useEmptySignatures';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './EditAfterSigningModal.types';

const EditAfterSigningModal = ({ isOpen, setIsEditAfterSigningModalOpen }: Props) => {
  const { positionBriefId } = useParams<{ positionBriefId: string }>();
  const emptySignaturesMutation = useEmptySignatures();
  const emptySignaturesFetchStatus = toFetchStatus(emptySignaturesMutation);

  const onClose = () => {
    setIsEditAfterSigningModalOpen(false);
  };

  return (
    <Modal
      title="attention"
      subtitle="Avant de modifier ce brief de poste, vous devez confirmer que vous souhaitez supprimer la signature."
      onClose={onClose}
      open={isOpen}
      icon={
        <div>
          <IllusBDPWarning />
        </div>
      }
      footerActionsRight={[
        <Button.Secondary key="close" onClick={onClose}>
          fermer
        </Button.Secondary>,
        <FetchButton
          title="valider"
          key="validate"
          onClick={() => {
            emptySignaturesMutation.mutate(parseInt(positionBriefId));
          }}
          fetchStatus={emptySignaturesFetchStatus}
          onSuccess={onClose}
        />,
      ]}
    >
      <></>
    </Modal>
  );
};

export default EditAfterSigningModal;
