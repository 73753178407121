import ReactGA from 'react-ga4';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrand,
} from 'src/Redux/Perimeter/Selectors';
import { CreatePositionBriefBody, PositionBrief, PositionBriefsService } from 'src/Services/API';

import { useFetchPerimeter } from '../Perimeter';
import { MutationKeys } from '../types';

export const useCreatePositionBrief = (
  extraOptions?: UseMutationOptions<
    PositionBrief,
    unknown,
    Omit<CreatePositionBriefBody, 'brandCode' | 'agencyId' | 'creatorUserId'>
  >
) => {
  const queryClient = useQueryClient();
  const { data: perimeter } = useFetchPerimeter({});
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);

  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.createPositionBrief],
    async (
      positionStudy: Omit<CreatePositionBriefBody, 'brandCode' | 'agencyId' | 'creatorUserId'>
    ) => {
      if (!currentBrand?.brandCodeApiHeader || !currentAgency) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const createdPositionStudy = await PositionBriefsService.positionBriefsControllerCreatePositionBrief(
        {
          body: {
            ...positionStudy,
            brandCode: currentBrand.brandCodeApiHeader,
            agencyId: currentAgency,
            creatorUserId: perimeter?.id ?? '',
          },
        }
      );

      return createdPositionStudy;
    },
    {
      onSuccess: (...args) => {
        ReactGA.event('Création de bdp', {
          ...userInfo,
          brandCode: currentBrand?.brandCodeApiHeader,
        });
        queryClient.invalidateQueries('fetchPositionBriefs');
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
