import { useMutation, useQueryClient } from 'react-query';

import { SignaturesService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUploadSignatures = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.uploadSignatures],
    async (params: {
      randstadFile?: File;
      clientFile?: File;
      positionBriefId: string;
      randstadSigningPersonName: string;
      randstadSigningPersonId: string;
      clientSigningPersonName: string;
      clientSigningPersonId: string;
    }) => {
      const signatures = await SignaturesService.signaturesControllerUploadSignatures({
        randstadFile: params.randstadFile,
        clientFile: params.clientFile,
        positionBriefId: params.positionBriefId,
        randstadSigningPersonName: params.randstadSigningPersonName,
        randstadSigningPersonId: params.randstadSigningPersonId,
        clientSigningPersonName: params.clientSigningPersonName,
        clientSigningPersonId: params.clientSigningPersonId,
      });
      return signatures;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.fetchPositionBriefDetails);
      },
    }
  );
};
