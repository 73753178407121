import moment from 'moment';
import React from 'react';

import styles from './GridCard.module.scss';
import { Props } from './GridCard.types';

export const GridCard = ({
  qualification,
  name,
  updatedAt,
  onClick,
  rightActions,
  service,
  serviceAddress,
}: Props) => (
  <div className={styles.container} onClick={onClick}>
    <div className={styles.upperPart}>
      <div className={styles.firstRow}>
        <p className={styles.date}>{`${moment(updatedAt).format('LL')} à ${moment(updatedAt).format(
          'LT'
        )}`}</p>
        {rightActions}
      </div>
      <p className={styles.service}>{service}</p>
      <p className={styles.serviceAddress}>{serviceAddress}</p>
    </div>

    <div className={styles.lowerPart}>
      <p className={styles.name}>nom du BDP : {name}</p>
      <p className={styles.qualification}>qualification : {qualification}</p>
    </div>
  </div>
);

export default GridCard;
