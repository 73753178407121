import { FileDropzone, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useEffect, useRef, useState } from 'react';

import { useDeleteDocument } from 'src/Hooks/Documents/useDeleteDocument';
import { useFetchDocumentById } from 'src/Hooks/Documents/useFetchDocumentById';
import { useUploadDocument } from 'src/Hooks/Documents/useUploadDocument';
import { bytesToSize } from 'src/Utils/bytesToSize';
import { dropzoneData } from 'src/Utils/dropZoneData';
import { onFileOpen } from 'src/Utils/onFileOpen';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './Documents.module.scss';
import { DropzoneItem, Props, UploadStatus } from './Documents.types';

const Documents = ({
  companyId,
  rawDocuments,
  setIsEditAfterSigningModalOpen,
  isBriefSigned,
}: Props) => {
  const fetchDocumentQuery = useFetchDocumentById();
  const uploadMutation = useUploadDocument();
  const deleteDocument = useDeleteDocument();

  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const documentsSetter = () => {
    return (
      rawDocuments?.data?.map(
        document =>
          ({
            id: document.id.toString(),
            subtitle: bytesToSize(document.size),
            file: {
              name: document.name,
              size: document.size,
              type: document.mimetype,
            } as File,
            status: UploadStatus.None,
          } as DropzoneItem)
      ) ?? []
    );
  };

  const [documents, setDocuments] = useState(documentsSetter());

  const [uploadSectionOpen, openOrCloseUploadSection] = useState(
    rawDocuments?.data?.length !== undefined && rawDocuments?.data?.length > 0
  );

  useEffect(() => {
    setDocuments(documentsSetter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawDocuments?.data]);

  const onDocumentDropped = (items: DropzoneItem[]) => {
    if (isFirstRender.current) {
      return;
    }
    if (isBriefSigned) {
      setIsEditAfterSigningModalOpen(true);
      return;
    }
    setDocuments(items);
    if (items.length > 0) {
      const promises = items
        .filter(item => item.status === UploadStatus.InProgress)
        .map(item => {
          return uploadMutation.mutateAsync({
            companyId,
            file: item.file,
          });
        });
      Promise.all(promises).then(dbFiles => {
        setDocuments(
          items.map(item => ({
            ...item,
            id: item.id ?? dbFiles.find(dbFile => dbFile.name === item.file.name)?.id,
            status: item.status === UploadStatus.InProgress ? UploadStatus.Done : item.status,
          }))
        );
      });
    } else {
      setDocuments([]);
    }
  };

  const onDocumentDeleted = async (item: DropzoneItem) => {
    const result = await deleteDocument.mutateAsync({
      id: item.id ?? '',
    });
    if (result)
      setDocuments(documents.filter(deletedItem => item.file.name !== deletedItem.file.name));
  };

  return (
    <div>
      <>
        {!uploadSectionOpen && (
          <div
            className={styles.openOrCloseUploadSection}
            onClick={() => {
              if (isBriefSigned) {
                setIsEditAfterSigningModalOpen(true);
              } else {
                openOrCloseUploadSection(true);
              }
            }}
          >
            <div>
              <PlusCircle className={styles.uploadSectionIcon} />
            </div>
            <div className={styles.addDocument}>ajouter un document</div>
          </div>
        )}
        {uploadSectionOpen && (
          <WithLightTitle
            title="ajouter tous documents utiles"
            className={styles.documentsContainer}
            rightTitleComponent={<span>{pluralFormat(documents.length, 'document')}</span>}
          >
            <FileDropzone
              {...dropzoneData}
              items={documents}
              onUpload={onDocumentDropped}
              onDelete={item => {
                if (!isBriefSigned) {
                  if (item.id) {
                    onDocumentDeleted(item);
                  }
                } else {
                  setIsEditAfterSigningModalOpen(true);
                }
              }}
              onClick={item => {
                if (item.id) {
                  fetchDocumentQuery.mutate(item.id, {
                    onSuccess: buffer => onFileOpen(buffer, item.file.type),
                  });
                } else {
                  window.open(URL.createObjectURL(item.file));
                }
              }}
            />
          </WithLightTitle>
        )}
        {uploadSectionOpen && !isBriefSigned && (
          <div className={styles.cancelUpload} onClick={() => openOrCloseUploadSection(false)}>
            masquer la zone d'ajout
          </div>
        )}
      </>
    </div>
  );
};

export default Documents;
