import {
  ExclusiveSelectionGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { EnumSalaryBasis } from 'src/Services/API';

import { Props } from './SalaryBasisSelection.types';

const salaryBasisEnumMap = {
  HOUR: 'horaire',
  MONTH: 'mensuelle',
  YEAR: 'annuelle',
};

const SalaryBasisSelection = ({ selected, setSelected }: Props) => (
  <WithLightTitle title="base salaire">
    <ExclusiveSelectionGroup<EnumSalaryBasis | null>
      values={Object.values(EnumSalaryBasis)}
      canBeReset={false}
      getStringValue={key => (key ? salaryBasisEnumMap[key] : '')}
      getKey={key => key ?? ''}
      setSelected={setSelected}
      selected={selected}
    />
  </WithLightTitle>
);

export default SalaryBasisSelection;
