import { useMutation, useQueryClient } from 'react-query';

import { RecruitmentStepsService } from 'src/Services/API/index';

import { MutationKeys, QueryKeys } from '../types';

export const useDeleteRecruitmentStep = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.deleteRecruitmentStep],
    async (id: number) => {
      return await RecruitmentStepsService.recruitmentStepsControllerDeleteRecruitmentStep({
        id,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.fetchRecruitmentSteps);
      },
    }
  );
};
