import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { BonusesService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

import useFetchBonuses from './useFetchBonuses';

const useDeleteBonus = (
  positionBrief: string,
  extraOptions?: UseMutationOptions<unknown, unknown, number>
) => {
  const queryClient = useQueryClient();
  const { ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.deleteBonus, positionBrief],
    async (id: number) => {
      return BonusesService.bonusesControllerDeleteBonus({ id });
    },
    {
      onMutate: id => {
        queryClient.setQueryData(
          [QueryKeys.fetchBonuses, positionBrief],
          (oldData?: ReturnType<typeof useFetchBonuses>['data']) => {
            return oldData?.filter(bonus => bonus.id !== id) ?? [];
          }
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.fetchBonuses);
      },
      ...rest,
    }
  );
};

export default useDeleteBonus;
