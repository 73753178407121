import { useAuth } from './useAuth';

export interface AuthenticatedUser {
  givenName?: string;
  familyName?: string;
  siid?: string;
  email?: string;
}

export const useAuthenticatedUser = () => {
  const { user } = useAuth();
  return {
    givenName: user?.given_name,
    familyName: user?.family_name,
    email: user?.email,
    siid: user?.nickname,
  } as AuthenticatedUser;
};
