import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  TextInput,
  TripleChoiceButton,
  DurationPicker,
  TextAreaWithLightTitle,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect, useContext, useMemo } from 'react';

import { YesNoUnknownEnum } from 'src/Utils/yesNoUnknownEnum';
import { databaseToZodData } from 'src/Utils/ZodDatabaseTransformer';

import { TimeContext } from './../../TimeContext';
import styles from './HourPackageTimeSelection.module.scss';
import { Props } from './HourPackageTimeSelection.types';
import { WorkingHours } from './WorkingHours';

const getMonthlyBase = (hoursPerWeek?: number | null, minutesPerWeek?: number | null) => {
  return hoursPerWeek ? Math.trunc(((hoursPerWeek + (minutesPerWeek ?? 0) / 60) * 52) / 12) : '--';
};

const HourPackageTimeSelection = ({ setIsEditAfterSigningModalOpen, positionBrief }: Props) => {
  const timeContext = useContext(TimeContext);
  const timeHoursPerWeekValue = timeContext.watch('timeHoursPerWeek');
  const timeOnCallDutyValue = timeContext.watch('timeOnCallDuty');
  const timeOnCallDutyDetails = timeContext.watch('timeOnCallDutyDetails');
  const timeRttCount = timeContext.watch('timeRttCount');

  useEffect(() => {
    if (timeContext.control._formState.isDirty) {
      timeContext.positionInformationHandleSubmit()();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeHoursPerWeekValue, timeOnCallDutyValue]);

  useEffect(() => {
    if (timeContext.control._formState.isDirty && !timeOnCallDutyValue) {
      timeContext.setValue('timeOnCallDutyDetails', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOnCallDutyValue]);

  useEffect(() => {
    if (isBriefSigned && timeContext.control._formState.isDirty) {
      setIsEditAfterSigningModalOpen(true);
      timeContext.setValue(
        'timeOnCallDutyDetails',
        positionBrief?.positionTime?.timeOnCallDutyDetails ?? ''
      );
      timeContext.setValue(
        'timeOnCallDuty',
        databaseToZodData(positionBrief?.positionTime?.timeOnCallDuty)
      );
      timeContext.setValue('timeRttCount', positionBrief?.positionTime?.timeRttCount?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOnCallDutyValue, timeOnCallDutyDetails, timeHoursPerWeekValue, timeRttCount]);

  const isBriefSigned = useMemo(() => {
    return positionBrief?.signatures !== null && positionBrief?.signatures !== undefined;
  }, [positionBrief]);

  return (
    <div className={styles.timeContainer}>
      <WithLightTitle title="base horaire hebdomadaire">
        <div className={styles.lineContainer}>
          {!isBriefSigned && (
            <DurationPicker
              name="timeHoursPerWeek"
              control={timeContext.control}
              defaultValue={null}
            />
          )}
          <span>
            {!isBriefSigned ? 'soit une' : ''} base mensuelle de{' '}
            {getMonthlyBase(timeHoursPerWeekValue?.hours, timeHoursPerWeekValue?.minutes)} heures
          </span>
        </div>
      </WithLightTitle>
      <WorkingHours
        isBriefSigned={isBriefSigned}
        setIsEditAfterSigningModalOpen={setIsEditAfterSigningModalOpen}
      />
      <WithLightTitle title="nombre de RTT par an" className={styles.timeHoursPerWeekContainer}>
        <TextInput
          key="timeRttCount"
          className={styles.timeHoursPerWeekInput}
          type="number"
          placeholder="jours"
          defaultValue={undefined}
          control={timeContext.control}
          name="timeRttCount"
          onBlurCapture={timeContext.positionInformationHandleSubmit()}
          min={0}
          max={50}
        />
      </WithLightTitle>
      <WithLightTitle title="poste avec astreinte">
        <TripleChoiceButton control={timeContext.control} name="timeOnCallDuty" />
      </WithLightTitle>
      {timeOnCallDutyValue === YesNoUnknownEnum.Yes && (
        <TextAreaWithLightTitle
          title="détails sur l'astreinte"
          name="timeOnCallDutyDetails"
          maxLength={4000}
          valueLength={timeOnCallDutyDetails.length}
          textAreaClassName={styles.timeOnCallDutyDetailsArea}
          control={timeContext.control}
          onBlurCapture={timeContext.positionInformationHandleSubmit()}
        />
      )}
    </div>
  );
};

export default HourPackageTimeSelection;
