import {
  Button,
  DropDown,
  FetchButton,
  Modal,
  RadioButtonGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  BDPHeaderBonuses,
  EuroSign,
  Percent,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  TextAreaWithLightTitle,
  TextInput,
  ToggleSwitch,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';

import useCreateBonus from 'src/Hooks/Bonuses/useCreateBonus';
import useUpdateBonus from 'src/Hooks/Bonuses/useUpdateBonus';
import { BonusFrequency, BonusReference, BonusType } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import {
  bonusFrequencyAmountValues,
  bonusFrequencyPercentageValues,
} from 'src/Utils/bonusFrequencyValues';

import styles from './BonusModal.module.scss';
import { BonusSchema } from './BonusModal.schema';
import {
  BONUS_TYPES,
  BonusFrequencyTypes,
  CreateMutationParams,
  Props,
  UpdateMutationParams,
} from './BonusModal.types';

const RadioButtons = [
  {
    id: BONUS_TYPES.AMOUNT,
    value: BONUS_TYPES.AMOUNT,
    label: 'montant fixe',
  },
  {
    id: BONUS_TYPES.PERCENTAGE,
    value: BONUS_TYPES.PERCENTAGE,
    label: 'montant en pourcentage',
  },
];

const BonusModal = ({ positionBrief, bonus, isOpen, setIsOpen }: Props) => {
  const createBonusMutation = useCreateBonus();
  const updateBonusMutation = useUpdateBonus();
  const bonusCreateStatus = toFetchStatus(createBonusMutation);
  const bonusUpdateStatus = toFetchStatus(updateBonusMutation);

  const { control, watch, handleSubmit, formState, reset } = useFormWithZodResolver({
    schema: BonusSchema,
    defaultValues: {
      name: bonus?.name ?? '',
      hasAmount: !!bonus?.amount?.toString().length,
      amount: bonus?.amount ? bonus.amount.toString() : '',
      description: bonus?.description ?? '',
      bonusType: bonus?.bonusType ?? BONUS_TYPES.AMOUNT,
      reference: bonus?.reference ?? undefined,
      frequency: bonus?.frequency ?? '',
    },
  });

  const name = watch('name');
  const amount = watch('amount');
  const description = watch('description');
  const hasAmount = watch('hasAmount');

  const bonusType = useController({
    name: 'bonusType',
    control: control,
  });

  const bonusFrequencyZodController = useController({
    name: 'frequency',
    control: control,
  });

  const bonusReferenceZodController = useController({
    name: 'reference',
    control: control,
  });

  const createBonus = ({ positionBrief, values }: CreateMutationParams) => {
    createBonusMutation.mutate({
      positionBrief: { id: positionBrief },
      bonus: {
        name: values.name,
        amount: hasAmount ? parseFloat(values.amount ?? '') : undefined,
        frequency: hasAmount
          ? BonusFrequency[values.frequency as keyof typeof BonusFrequency]
          : undefined,
        description: values.description,
        bonusType: hasAmount ? BonusType[values.bonusType as keyof typeof BonusType] : undefined,
        reference: hasAmount ? (values.reference as BonusReference) : undefined,
      },
    });
  };

  const updateBonus = ({ bonusId, values }: UpdateMutationParams) => {
    updateBonusMutation.mutate({
      id: bonusId,
      body: {
        name: values.name,
        amount: hasAmount ? parseFloat(values.amount ?? '') : undefined,
        frequency: hasAmount
          ? BonusFrequency[values.frequency as keyof typeof BonusFrequency]
          : undefined,
        description: values.description,
        bonusType: hasAmount ? BonusType[values.bonusType as keyof typeof BonusType] : undefined,
        reference:
          values.reference !== undefined ? (values.reference as BonusReference) : undefined,
      },
    });
  };

  const bonusModalHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        if (bonus !== undefined) {
          updateBonus({ bonusId: bonus.id, values });
        } else {
          createBonus({ positionBrief: positionBrief ?? 0, values });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSubmit, positionBrief, createBonusMutation, updateBonusMutation]
  );

  const onClose = () => {
    reset();
  };
  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      title={bonus ? 'modifier la prime' : 'nouvelle prime'}
      icon={<BDPHeaderBonuses />}
      iconClassName={styles.icon}
      nested
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            reset();
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={() => setIsOpen(false)}>annuler</Button.Secondary>,
        <FetchButton
          disabled={
            (hasAmount &&
              (name.length === 0 ||
                !amount ||
                !bonusFrequencyZodController.field.value ||
                bonusFrequencyZodController.field.value === 'fréquence' ||
                (bonusType.field.value === BonusType.PERCENTAGE &&
                  (!bonusReferenceZodController.field.value ||
                    bonusReferenceZodController.field.value === 'sur telle valeur')) ||
                formState.errors.amount !== undefined)) ||
            (!hasAmount && name.length === 0)
          }
          onClick={bonusModalHandleSubmit()}
          fetchStatus={bonus ? bonusUpdateStatus : bonusCreateStatus}
          title="valider"
          onSuccess={() => setIsOpen(false)}
        />,
      ]}
    >
      <div className={styles.bonusName}>
        <WithLightTitle title="nom de la prime">
          <TextInput name="name" control={control} />
        </WithLightTitle>
      </div>
      <div className={styles.toggleContainer}>
        <p>cette prime a-t-elle un montant défini ?</p>
        <ToggleSwitch control={control} name="hasAmount" />
      </div>
      {hasAmount && (
        <>
          <WithLightTitle className={styles.bonusName} title="type de déclaration">
            <RadioButtonGroup
              name={'percentOrAmount'}
              selected={bonusType.field.value ?? undefined}
              onChange={(index, value) => bonusType.field.onChange(value)}
              radioButtonsInfo={RadioButtons}
            />
          </WithLightTitle>
          <div className={styles.amountFrequencyContainer}>
            {hasAmount && (
              <div
                className={
                  bonusType.field.value === BONUS_TYPES.AMOUNT
                    ? styles.amount
                    : styles.percentageDropdownFrequency
                }
              >
                <WithLightTitle
                  title={
                    bonusType.field.value === BONUS_TYPES.AMOUNT
                      ? 'montant'
                      : 'valeur du pourcentage'
                  }
                >
                  <div
                    className={
                      bonusType.field.value === BONUS_TYPES.PERCENTAGE ? styles.inputPercentage : ''
                    }
                  >
                    <TextInput
                      name="amount"
                      control={control}
                      type="number"
                      min={0}
                      rightIcon={
                        bonusType.field.value === BONUS_TYPES.AMOUNT ? <EuroSign /> : <Percent />
                      }
                    />
                  </div>
                </WithLightTitle>
              </div>
            )}

            {bonusType.field.value === BONUS_TYPES.PERCENTAGE && (
              <div className={styles.percentageTwo}>
                <WithLightTitle title="type de pourcentage">
                  <DropDown
                    placeholder="sur telle valeur"
                    items={bonusFrequencyPercentageValues}
                    canBeReset
                    keyValueExtractor={(bonusReference: BonusFrequencyTypes) => ({
                      key: bonusReference.key,
                      value: bonusReference.label,
                    })}
                    onSelectItem={(bonusReference: BonusFrequencyTypes) => {
                      bonusReferenceZodController.field.onChange(
                        bonusReference ? bonusReference.key : 'sur telle valeur'
                      );
                    }}
                    selectedItem={bonusFrequencyPercentageValues.find(
                      bonusReference =>
                        bonusReference.key === bonusReferenceZodController.field.value
                    )}
                  />
                </WithLightTitle>
              </div>
            )}

            <WithLightTitle title="fréquence">
              <div
                className={
                  bonusType.field.value === BONUS_TYPES.AMOUNT
                    ? styles.amountDropdownFrequency
                    : styles.percentageDropdownFrequency
                }
              >
                <DropDown
                  placeholder={'fréquence'}
                  items={bonusFrequencyAmountValues}
                  canBeReset
                  keyValueExtractor={(bonusFrequency: BonusFrequencyTypes) => ({
                    key: bonusFrequency.key,
                    value: bonusFrequency.label,
                  })}
                  onSelectItem={(bonusFrequency: BonusFrequencyTypes) => {
                    bonusFrequencyZodController.field.onChange(
                      bonusFrequency ? bonusFrequency.key : 'fréquence'
                    );
                  }}
                  selectedItem={bonusFrequencyAmountValues.find(
                    bonusFrequency => bonusFrequency.key === bonusFrequencyZodController.field.value
                  )}
                />
              </div>
            </WithLightTitle>
          </div>
        </>
      )}
      <div className={styles.descriptionContainer}>
        <TextAreaWithLightTitle
          title="décrivez quelques détails concernant cette prime"
          name="description"
          counterClassName={styles.counter}
          textAreaClassName={styles.textArea}
          placeholder="en quelques mots..."
          maxLength={255}
          valueLength={description.length}
          control={control}
        />
      </div>
    </Modal>
  );
};

export default BonusModal;
