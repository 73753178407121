import { PerimeterState } from 'src/Redux/Perimeter/Types';

import { ConsultantPerimeter } from '../API';

export function isValidActivePerimeter({
  currentBrand,
  currentAgency,
  perimeter,
}: {
  currentBrand?: PerimeterState['currentBrand'];
  currentAgency?: string;
  perimeter: ConsultantPerimeter;
}): boolean {
  return (
    perimeter.companies?.some(
      company =>
        company?.brand?.name === currentBrand?.brand?.name &&
        company.agencies?.some(agency => agency === currentAgency)
    ) ?? false
  );
}
