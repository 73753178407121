import { useMutation, UseMutationOptions } from 'react-query';

import { DocumentsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchDocumentById = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  return useMutation(
    [MutationKeys.fetchDocumentById],
    async (fileId: string) => {
      const document = await DocumentsService.documentsControllerGetDocumentById(
        { id: fileId },
        { responseType: 'arraybuffer' }
      );
      return document;
    },
    {
      ...extraOptions,
    }
  );
};
