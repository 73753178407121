import React, { useContext, useState } from 'react';

import BadgePicker from 'src/Components/BadgePicker';
import { EnumStudyLevelLevel, EnumStudyLevelPriority } from 'src/Services/API';

import { FormationContext } from '../../FormationContext';
import { findStudyLevelFromLevel } from '../utils';

import styles from './StudyLevelSelectionBadge.module.scss';
import { Props } from './StudyLevelSelectionBadge.types';
import { STUDY_LEVEL_PRIORITY_ITEMS } from './utils';

const StudyLevelSelectionBadge = ({ currentLevel }: Props) => {
  const formationContext = useContext(FormationContext);

  const getSelectedPriority = () => {
    return (
      findStudyLevelFromLevel(currentLevel, selectedStudyLevels)?.priority ??
      EnumStudyLevelPriority.UNKNOWN
    );
  };
  const selectedStudyLevels = formationContext.watch('studyLevels');
  const [selectedPriority, setSelectedPriority] = useState<EnumStudyLevelPriority>(
    getSelectedPriority()
  );

  const updateSelectedPriority = (newKeyPriority: string | number) => {
    setSelectedPriority(newKeyPriority as EnumStudyLevelPriority);
    formationContext.setValue(
      `studyLevels`,
      Object.keys(EnumStudyLevelLevel).map(basicStudyLevel => {
        return {
          level: basicStudyLevel as EnumStudyLevelLevel,
          selected:
            findStudyLevelFromLevel(basicStudyLevel as EnumStudyLevelLevel, selectedStudyLevels)
              ?.selected ?? false,
          priority:
            basicStudyLevel === currentLevel
              ? (newKeyPriority as EnumStudyLevelPriority)
              : findStudyLevelFromLevel(basicStudyLevel as EnumStudyLevelLevel, selectedStudyLevels)
                  ?.priority ?? EnumStudyLevelPriority.UNKNOWN,
        };
      })
    );
    formationContext.curriculumHandleSubmit()();
  };

  return (
    <div className={styles.selectable}>
      <BadgePicker
        items={STUDY_LEVEL_PRIORITY_ITEMS}
        selectedItem={selectedPriority}
        onChange={updateSelectedPriority}
      />
    </div>
  );
};

export default StudyLevelSelectionBadge;
