import { Location } from 'history';
import React from 'react';

import { ModalName } from 'src/Hooks/Navigation';

export interface ModalRoute {
  route: ModalName;
  component: React.FC<{ modalParameters?: unknown }>;
  backgroundIsOptional?: boolean;
}

export interface CompanyResultRoute {
  route: string;
  component: React.FC<CompanyResultState>;
}
export interface LocationState<T = undefined> {
  modalParameters?: T;
  background: Location<unknown>;
}

export interface PositionBriefState {
  modalParameters?: { briefId: number };
  background: Location<unknown>;
}

export interface CompanyResultState {
  routeParameters?: {
    companyId?: string;
    companyName?: string;
  };
}

export interface PositionBriefRoute {
  route: string;
  component: React.FC<unknown>;
  routerParameters?: {
    companyId: string;
    positionBriefId: string;
    firstSection?: string;
    secondSection?: string;
  };
}

export function getEditionRoute({
  companyId,
  positionBriefId,
  section,
}: {
  companyId?: string;
  positionBriefId?: string;
  section: string;
}) {
  return `/company/${companyId}/brief/${positionBriefId}/${section}`;
}
