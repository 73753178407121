import { useMutation, UseMutationOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { PositionBriefsService, Sections } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

export const useGenerateBDPPdf = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency) ?? '';
  const user = useAuthenticatedUser();
  const positionBriefId = parseInt(useParams<{ positionBriefId: string }>().positionBriefId);
  return useMutation(
    [MutationKeys.generatePDF],
    async (sections?: Sections) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      if (sections !== undefined) {
        const positionBrief = await PositionBriefsService.positionBriefsControllerGetPositionBriefPdf(
          {
            id: positionBriefId,
            body: {
              user,
              brandCode: currentBrand.brandCodeApiHeader,
              agencyId: currentAgency,
              sections,
            },
          },
          { responseType: 'arraybuffer' }
        );
        return positionBrief;
      }
    },
    {
      ...extraOptions,
    }
  );
};
