import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useEffect, useState } from 'react';

import { MedicalSpecialization } from 'src/Services/API';

import { Props } from './MedicalSpecializationsPicker.types';

const MedicalSpecializationsPicker = ({
  allMedicalSpecializations,
  selectedMedicalSpecializationsIds,
  setSelectedMedicalSpecializationsIds,
}: Props) => {
  const getValueFromSelectedIds = () => {
    return selectedMedicalSpecializationsIds.map(id => {
      return {
        id,
        label: allMedicalSpecializations?.find(result => result.id === id)?.label,
      } as MedicalSpecialization;
    });
  };
  const [specializationKeyword, setSpecializationKeyword] = useState('');
  const [value, setValue] = useState(getValueFromSelectedIds());

  useEffect(() => {
    setValue(getValueFromSelectedIds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedicalSpecializationsIds]);

  return (
    <AutoSuggest
      id="medical-specializations-picker"
      value={value}
      searchResults={
        allMedicalSpecializations
          ?.filter(result =>
            result.label.toLowerCase().includes(specializationKeyword.toLowerCase())
          )
          ?.filter(result => !selectedMedicalSpecializationsIds.includes(result.id)) ?? []
      }
      onSearch={newKeyword => {
        setSpecializationKeyword(newKeyword);
      }}
      keyValueExtractor={medicalSpecialization => ({
        key: medicalSpecialization.id,
        value: medicalSpecialization.label,
      })}
      onChange={newMedicalSpecializations => {
        setSpecializationKeyword('');
        setSelectedMedicalSpecializationsIds(newMedicalSpecializations.map(value => value.id));
      }}
      minLengthToSearch={2}
    />
  );
};

export default MedicalSpecializationsPicker;
