import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './ListCardLoader.module.scss';

const ListCardLoader = () => (
  <div className={styles.container}>
    <ContentLoader height="6.75rem" width="100%" uniqueKey="homePositionStudyCard">
      <rect x="0.75rem" y="1rem" rx="4" ry="4" width="50%" height="1rem" />
      <rect x="0.75rem" y="3rem" rx="4" ry="4" width="90%" height="1rem" />
      <rect x="0.75rem" y="4.5rem" rx="4" ry="4" width="90%" height="1rem" />
      <rect x="0.75rem" y="7.75rem" rx="4" ry="4" width="90%" height="1rem" />
      <rect x="0.75rem" y="9.25rem" rx="4" ry="4" width="90%" height="1rem" />
    </ContentLoader>
  </div>
);

export default ListCardLoader;
