import { z } from 'zod';

import { RecruitmentStepWithoutPositionBrief } from 'src/Containers/Company/Organization/Organization.types';
import { RecruitmentStepModality } from 'src/Services/API';

export interface Props {
  positionBrief: number;
  creation: boolean;
  recruitmentStep?: RecruitmentStepWithoutPositionBrief;
  index?: number;
}

export const RecruitmentStepValidationSchema = z.object({
  name: z.string(),
  recruitmentStepModality: z.nativeEnum(RecruitmentStepModality),
  specificTest: z.boolean(),
  testDetails: z.string(),
  contactName: z.string(),
});
