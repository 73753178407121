import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';

const useInitializeAnalytics = () => {
  const perimeter = useSelector(getCurrentBrand);
  useEffect(() => {
    ReactGA.set({ user_properties: { brandCode: perimeter?.brandCodeApiHeader } });
  }, [perimeter?.brandCodeApiHeader]);
  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`);
  }, []);
};

export default useInitializeAnalytics;
