export const regions = [
  { id: 'Auverge-Rhône-Alpes', label: 'Auverge-Rhône-Alpes' },
  { id: 'Bourgogne-Franche-Comté', label: 'Bourgogne-Franche-Comté' },
  { id: 'Bretagne', label: 'Bretagne' },
  { id: 'Centre-Val de Loire', label: 'Centre-Val de Loire' },
  { id: 'Corse', label: 'Corse' },
  { id: 'Grand Est', label: 'Grand Est' },
  { id: 'Hauts-de-France', label: 'Hauts-de-France' },
  { id: 'Île-de-France', label: 'Île-de-France' },
  { id: 'Normandie', label: 'Normandie' },
  { id: 'Nouvelle-Aquitaine', label: 'Nouvelle-Aquitaine' },
  { id: 'Occitanie', label: 'Occitanie' },
  { id: 'Pays de la Loire', label: 'Pays de la Loire' },
  { id: "Provence-Alpes-Côte d'Azur", label: "Provence-Alpes-Côte d'Azur" },
  { id: 'Guadeloupe', label: 'Guadeloupe' },
  { id: 'Martinique', label: 'Martinique' },
  { id: 'Guyane', label: 'Guyane' },
  { id: 'La Réunion', label: 'La Réunion' },
  { id: 'Mayotte', label: 'Mayotte' },
];
