import { combineReducers } from '@reduxjs/toolkit';

import { perimeterReducer } from './Perimeter/Slice';
import { AppDispatch } from './store';
import { userPreferencesReducer } from './UserPreferences/Slice';

const rootReducer = combineReducers({
  perimeter: perimeterReducer,
  userPreferences: userPreferencesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  dispatch: AppDispatch;
  state: RootState;
  extra?: unknown;
  rejectValue?: unknown;
}

export default rootReducer;
