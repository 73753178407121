import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { TripleChoiceButton } from '@randstad-lean-mobile-factory/react-form-fields';
import { TextInput } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

import { EnumSalaryFormat } from 'src/Services/API';

import AmountSelection from './AmountSelection';
import SalaryBasisSelection from './SalaryBasisSelection';
import SalaryFormatSelection from './SalaryFormatSelection';
import styles from './SalarySelection.module.scss';
import { Props } from './SalarySelection.types';

const SalarySelection = ({ control, watch, onControlChange }: Props) => {
  const salaryBasisController = useController({ control, name: 'salaryBasis' });
  const salaryFormatController = useController({ control, name: 'salaryFormat' });
  const maxSalaryBasisController = useController({ control, name: 'maxSalaryBasis' });
  const salaryMonthCountController = useController({ control, name: 'salaryMonthCount' });

  const minSalaryBasis = watch('minSalaryBasis');
  const restaurantTickets = watch('restaurantTickets');

  useEffect(() => {
    onControlChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    salaryBasisController.field.value,
    salaryFormatController.field.value,
    minSalaryBasis,
    maxSalaryBasisController.field.value,
    salaryMonthCountController.field.value,
    restaurantTickets,
  ]);

  return (
    <div className={styles.salaryContainer}>
      <div className={styles.salaryFirstRowContainer}>
        <SalaryBasisSelection
          selected={salaryBasisController.field.value}
          setSelected={salaryBasisController.field.onChange}
        />
        <SalaryFormatSelection
          selected={salaryFormatController.field.value}
          setSelected={newSelection => {
            salaryFormatController.field.onChange(newSelection);
            if (newSelection === EnumSalaryFormat.FIX.toString()) {
              maxSalaryBasisController.field.onChange(null);
            }
          }}
        />
        <WithLightTitle title="nombre de mois applicables" className={styles.remoteDaysContainer}>
          <TextInput
            containerClassName={styles.countInput}
            key="monthCountSelection"
            control={control}
            type="number"
            name="salaryMonthCount"
            min={0}
            max={14}
            placeholder="mois"
          />
        </WithLightTitle>
      </div>
      {salaryFormatController.field.value && (
        <div className={styles.lineContainer}>
          <AmountSelection
            key="minSalaryBasisAmountSelection"
            title={
              salaryFormatController.field.value === EnumSalaryFormat.FIX
                ? 'montant'
                : 'fourchette entre'
            }
            control={control}
            name="minSalaryBasis"
          />
          {salaryFormatController.field.value === EnumSalaryFormat.RANGE && (
            <AmountSelection
              key="maxSalaryBasisAmountSelection"
              title="et"
              control={control}
              name="maxSalaryBasis"
            />
          )}
        </div>
      )}
      <WithLightTitle title="tickets restaurants">
        <TripleChoiceButton control={control} name="restaurantTickets" />
      </WithLightTitle>
    </div>
  );
};

export default SalarySelection;
