import ReactGA from 'react-ga4';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { getAnalyticsUserInfo, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { PositionBrief, PositionBriefsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useDuplicatePositionBrief = (
  extraOptions?: UseMutationOptions<PositionBrief, unknown, unknown>
) => {
  const queryClient = useQueryClient();
  const currentBrand = useSelector(getCurrentBrand);
  const { onSuccess, ...rest } = extraOptions ?? {};
  const userInfo = useSelector(getAnalyticsUserInfo);
  return useMutation(
    [MutationKeys.duplicatePositionBrief],
    async (positionBriefId: number) => {
      if (!currentBrand?.brandCodeApiHeader) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const newPositionBrief = await PositionBriefsService.positionBriefsControllerDuplicatePositionBrief(
        {
          id: positionBriefId,
        }
      );

      return newPositionBrief;
    },
    {
      onSuccess: (...args) => {
        ReactGA.event('Duplication de bdp', {
          ...userInfo,
          brandCode: currentBrand?.brandCodeApiHeader,
        });
        queryClient.invalidateQueries('fetchPositionBriefs');
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
