import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BriefActions from 'src/Components/BriefActions';
import DisplayModeSelector from 'src/Components/DisplayModeSelector';
import { DISPLAY_MODE } from 'src/Components/DisplayModeSelector/DisplayModeSelector.component';
import { useFetchPositionBriefs } from 'src/Hooks/PositionBriefs/useFetchPositionBriefs';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { getCardDisplayMode } from 'src/Redux/UserPreferences/Selectors';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { getEditionRoute } from 'src/Services/Routing';

import HomePositionStudyCard from './GridCard';
import styles from './LastPositionBriefs.module.scss';
import HomePositionStudyListCard from './ListCard';

export const LastPositionBriefs = () => {
  const history = useHistory();
  const unit = useSelector(getCurrentAgency);
  const dispatch = useDispatch();

  // Very big screen (31inch) is approximatively 3 rows of 8 cards
  const positionBriefsQuery = useFetchPositionBriefs({ limit: 24 });
  const positionBriefs = positionBriefsQuery.data;
  const displayMode = useSelector(getCardDisplayMode);
  const onDisplayModeChange = useCallback(
    (displayMode: DISPLAY_MODE) => {
      dispatch(userPreferencesActions.setCardDisplayMode(displayMode));
    },
    [dispatch]
  );
  const CardOrGrid =
    displayMode === DISPLAY_MODE.GRID ? HomePositionStudyCard : HomePositionStudyListCard;
  return (
    <div className={styles.cardsContainer}>
      <div className={styles.row}>
        <p className={styles.title}>
          {positionBriefs?.pages[0] !== undefined && positionBriefs?.pages[0]?.count > 0
            ? `derniers briefs de poste réalisés sur ${unit} :`
            : `aucun brief de poste réalisé sur ${unit}.`}
        </p>
        <DisplayModeSelector
          displayMode={displayMode}
          onDisplayModeIndexUpdate={onDisplayModeChange}
        />
      </div>
      <div
        className={classNames({
          [styles.studies]: true,
          [styles.grid]: displayMode === DISPLAY_MODE.GRID,
        })}
      >
        {positionBriefsQuery.isSuccess &&
          !positionBriefsQuery.isFetching &&
          positionBriefs?.pages.map(positionBriefGroup =>
            positionBriefGroup.positionBriefs.map(positionBrief => (
              <CardOrGrid
                key={positionBrief.id}
                name={positionBrief.name ?? ''}
                qualification={positionBrief.qualification?.label ?? ''}
                createdAt={positionBrief.createdAt}
                updatedAt={positionBrief.updatedAt}
                service={positionBrief.company?.companyName}
                serviceAddress={`${positionBrief.company?.companyAddress?.postalCode} - ${positionBrief.company?.companyAddress?.city}`}
                onClick={() => {
                  history.push(
                    getEditionRoute({
                      companyId: positionBrief.company?.companyId,
                      positionBriefId: positionBrief.id?.toString(),
                      section: 'company/information',
                    })
                  );
                }}
                rightActions={
                  <BriefActions
                    briefId={positionBrief.id}
                    briefName={positionBrief.name}
                    companyId={positionBrief.company?.companyId}
                  />
                }
              />
            ))
          )}
        {positionBriefsQuery.isFetching && (
          <>
            {new Array(24)
              .fill(undefined)
              .map((_, index) =>
                displayMode === DISPLAY_MODE.GRID ? (
                  <HomePositionStudyCard.Loading key={`homepositionstudycard-${index}`} />
                ) : (
                  <HomePositionStudyListCard.Loading key={`homepositionstudylist-${index}`} />
                )
              )}
          </>
        )}
        {positionBriefsQuery.isError && (
          <div>Une erreur est survenue dans la récupération des BDP</div>
        )}
      </div>
    </div>
  );
};

export default LastPositionBriefs;
