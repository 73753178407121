import {
  Modal,
  Button,
  PopupActions,
  OrganizationChart,
} from '@randstad-lean-mobile-factory/react-components-core';
import { OrganizationChartItem } from '@randstad-lean-mobile-factory/react-components-core/dist/OrganizationChart/OrganizationChart.types';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useRef } from 'react';

import { organisationSchema } from 'src/Containers/Company/Organization/Organization.schema';
import { CompanyHierarchy, EnumCompanyHierarchySize } from 'src/Services/API';

import { Props } from './OrganizationChartModal.types';

const OrganizationChartModal = ({ data, onValidate, trigger }: Props) => {
  const { watch, setValue } = useFormWithZodResolver({
    schema: organisationSchema,
    defaultValues: {
      hierarchy: [...data],
    },
  });
  const hierarchy = watch('hierarchy');
  const ref = useRef<PopupActions>(null);

  return (
    <Modal
      ref={ref}
      onOpen={() => {
        if (data.length === 0) {
          setValue('hierarchy', [
            {
              id: 1,
              title: 'nom et prénom',
              subtitle: 'rôle fonction de la personne',
              size: EnumCompanyHierarchySize.BIG,
              children: [],
            },
          ]);
        } else {
          setValue('hierarchy', [...data]);
        }
      }}
      title="création d'un organigramme"
      subtitle="compléter, modifier, ajouter des éléments de l'organigramme à partir de la première brique"
      contentStyle={{ maxWidth: '80%', backgroundColor: '#f7f5f0' }}
      trigger={trigger}
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            ref.current?.close();
          }}
        >
          annuler
        </Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onValidate([...hierarchy]);
            ref.current?.close();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <div>
        <OrganizationChart
          data={[...hierarchy] as OrganizationChartItem[]}
          titlePlaceholder="nom et prénom"
          subtitlePlaceholder="rôle fonction de la personne"
          updateData={newData => {
            setValue('hierarchy', [...newData] as CompanyHierarchy[]);
          }}
        />
      </div>
    </Modal>
  );
};

export default OrganizationChartModal;
