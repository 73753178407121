import * as z from 'zod';

export const missionSearchModalSchema = z.object({
  selectedCompany: z
    .object({
      companyId: z.string(),
      companyName: z.string(),
      siret: z.string().optional(),
      companyAddress: z
        .object({
          address1: z.string().optional(),
          address2: z.string().optional(),
          address3: z.string().optional(),
          postalCode: z.string().optional(),
          city: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
  selectedMissionIds: z.array(z.string()),
});
