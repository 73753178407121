export const dropzoneData = {
  maxFiles: 10,
  maxSize: 1200000,
  accept: {
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'image/*': ['.jpg', '.png', '.gif'],
  },
  advice: 'ajouter tous document utiles',
};
