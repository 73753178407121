import { useQuery, UseQueryOptions } from 'react-query';

import { QualificationsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchQualificationById = (
  qualificationIds: string[],
  extraOptions?: UseQueryOptions<unknown, unknown, { id: string; label: string }[], string[]>
) => {
  const societyCode = '001';
  return useQuery(
    [QueryKeys.fetchQualificationById, ...qualificationIds],
    async () => {
      const qualifications = await QualificationsService.qualificationsControllerGetQualifications({
        qualificationIds,
        societyCode,
      });
      return qualifications.map(qualification => {
        return { id: qualification.id, label: qualification.label };
      });
    },
    {
      ...extraOptions,
      enabled: qualificationIds.filter(qualification => qualification !== '')?.length > 0,
    }
  );
};
