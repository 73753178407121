import {
  Button,
  Checkbox,
  ComboBox,
  FetchButton,
  Modal,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { CutFinger } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import throttle from 'lodash.throttle';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { useFetchCompanies } from 'src/Hooks/Companies';
import { useFetchRecruitingMissions } from 'src/Hooks/Companies/useFetchRecruitingMissions';
import { useLinkPositionBriefToMissions } from 'src/Hooks/PositionBriefs/useLinkPositionBriefToMissions';
import { CompanySearchResult } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { PositionBriefState } from 'src/Services/Routing';

import styles from './MissionSearchModal.module.scss';
import { missionSearchModalSchema } from './MissionSearchModal.types';

const MissionSearchModal = () => {
  const innerRef = React.useRef<PopupActions>(null);
  const history = useHistory();
  const positionBriefId = useLocation<PositionBriefState>().state.modalParameters?.briefId;
  const { control, handleSubmit } = useFormWithZodResolver({
    schema: missionSearchModalSchema,
    defaultValues: {
      selectedCompany: undefined,
      selectedMissionIds: [],
    },
  });
  const companyController = useController({ control, name: 'selectedCompany' });
  const selectedMissionsController = useController({ control, name: 'selectedMissionIds' });

  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchCompanies(keyword);
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);

  const fetchMissionsQuery = useFetchRecruitingMissions(
    companyController.field.value?.companyId ?? ''
  );
  const recruitingMissions = fetchMissionsQuery.data ?? [];
  const linkMissionsToBDPMutation = useLinkPositionBriefToMissions(positionBriefId);
  const fetchStatus = toFetchStatus(linkMissionsToBDPMutation);

  return (
    <Modal
      ref={innerRef}
      icon={<CutFinger />}
      open
      onClose={() => {
        companyController.field.onChange(undefined);
        selectedMissionsController.field.onChange([]);
        history.goBack();
      }}
      title="sélection de commande"
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            companyController.field.onChange(undefined);
            selectedMissionsController.field.onChange([]);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            companyController.field.onChange(undefined);
            selectedMissionsController.field.onChange([]);
            history.goBack();
          }}
        >
          annuler
        </Button.Secondary>,
        <FetchButton
          disabled={
            !companyController.field.value || selectedMissionsController.field.value.length === 0
          }
          onClick={handleSubmit(values => {
            linkMissionsToBDPMutation.mutate({ missionIds: values.selectedMissionIds });
          })}
          fetchStatus={fetchStatus}
          title="valider"
          onSuccess={() => {
            companyController.field.onChange(undefined);
            selectedMissionsController.field.onChange([]);
            history.goBack();
          }}
        />,
      ]}
    >
      <WithLightTitle title="choix du compte" className={styles.combobox}>
        <ComboBox
          id="compte"
          value={companyController.field.value ?? null}
          minLengthToSearch={3}
          withSubLabel
          keyValueExtractor={(company: CompanySearchResult) => ({
            key: company.companyId ?? '',
            value: company.companyName
              ? `${company.companyName} (${company.siret ?? 'siret inconnu'})`
              : '',
            subValue: `${company.companyAddress?.postalCode} - ${company.companyAddress?.city}`,
          })}
          onSearch={searchText => throttledSetKeyword(searchText)}
          fetchStatus={toFetchStatus({ isSuccess, isError, isLoading })}
          placeholder="1 caractère minimum"
          searchResults={
            data?.map(company => {
              return {
                companyId: company.companyId,
                companyName: company.companyName,
                siret: company.siret,
                companyAddress: company.companyAddress,
              };
            }) ?? []
          }
          onChange={company => companyController.field.onChange(company ?? undefined)}
        />
      </WithLightTitle>
      {companyController.field.value !== undefined && (
        <>
          {fetchMissionsQuery.isLoading && (
            <ContentLoader height="5rem" width="100%" uniqueKey="mission-search">
              <rect x="2%" y="10" rx="4" ry="4" width="90%" height="16" />
              <rect x="2%" y="30" rx="4" ry="4" width="90%" height="16" />
              <rect x="2%" y="50" rx="4" ry="4" width="90%" height="16" />
            </ContentLoader>
          )}
          {fetchMissionsQuery.isSuccess && recruitingMissions.length > 0 && (
            <>
              <div className={styles.selectAllCheckbox}>
                <Checkbox
                  label="tout sélectionner"
                  className={styles.checkbox}
                  halfChecked={selectedMissionsController.field.value.length > 0}
                  checked={
                    selectedMissionsController.field.value.length === recruitingMissions.length
                  }
                  onChange={() =>
                    selectedMissionsController.field.value.length > 0
                      ? selectedMissionsController.field.onChange([])
                      : selectedMissionsController.field.onChange(
                          recruitingMissions.map(item => item.missionId ?? '') ?? []
                        )
                  }
                />
                <p
                  className={styles.missionCount}
                >{`${selectedMissionsController.field.value.length} commande(s) ajoutée(s)`}</p>
              </div>
              <div className={styles.checkboxBorderTop}>
                {recruitingMissions.map(mission => {
                  const isChecked = selectedMissionsController.field.value?.includes(
                    mission.missionId ?? ''
                  );
                  return (
                    <div key={mission.missionId} className={styles.checkbox}>
                      <Checkbox
                        key={mission.missionId}
                        children={
                          <div className={styles.checkboxChildren}>
                            <div className={styles.missionNumber}>{mission.missionId}</div>
                            <div className={styles.missionInfo}>
                              {`${mission.qualification?.label} | commande créée le ${moment(
                                mission.commandDate
                              ).format('L')}${
                                mission.startDate !== undefined
                                  ? ` | début le ${moment(mission.startDate).format('L')}`
                                  : ' | pas de date de début'
                              }`}
                            </div>
                          </div>
                        }
                        checked={isChecked}
                        onChange={() =>
                          isChecked
                            ? selectedMissionsController.field.onChange(
                                selectedMissionsController.field.value?.filter(
                                  missionItem => missionItem !== mission.missionId
                                )
                              )
                            : selectedMissionsController.field.onChange([
                                ...selectedMissionsController.field.value,
                                mission.missionId ?? '',
                              ])
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {fetchMissionsQuery.isSuccess && recruitingMissions.length === 0 && (
            <div>Nous n'avons pas trouvé de commande pour le compte sélectionné</div>
          )}
        </>
      )}
    </Modal>
  );
};

export default MissionSearchModal;
