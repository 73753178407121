import { useQuery } from 'react-query';

import { RecruitmentStepsService } from 'src/Services/API/index';

import { QueryKeys } from '../types';

export const useFetchRecruitmentSteps = (positionBriefId: number) => {
  return useQuery(
    [QueryKeys.fetchRecruitmentSteps, positionBriefId],
    async () => {
      const RecruitmentSteps = await RecruitmentStepsService.recruitmentStepsControllerFetchRecruitmentSteps(
        {
          positionBriefId,
        }
      );
      return RecruitmentSteps;
    },
    {
      staleTime: Infinity,
    }
  );
};
