import { z } from 'zod';

export const ExportModalSchema = z.object({
  sectionsToExport: z.object({
    company: z.object({
      all: z.boolean(),
      information: z.boolean(),
      assets: z.boolean(),
      organization: z.boolean(),
      strategy: z.boolean(),
    }),
    position: z.object({
      all: z.boolean(),
      information: z.boolean(),
      context: z.boolean(),
      environment: z.boolean(),
    }),
    profile: z.object({
      all: z.boolean(),
      curriculum: z.boolean(),
      skills: z.boolean(),
    }),
    conclusion: z.object({
      all: z.boolean(),
      signatures: z.boolean(),
    }),
  }),
});
