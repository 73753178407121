import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { FieldPath, FieldValues } from 'react-hook-form';

import { useFetchContractTypes } from 'src/Hooks/Repositories/useFetchContractTypes';

import { Props } from './ContractTypeSelection.types';

const ContractTypeSelection = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
}: Props<TFieldValues, TName>) => {
  const contractTypesQuery = useFetchContractTypes();

  return (
    <div>
      {contractTypesQuery.isSuccess && (
        <WithLightTitle title="type de contrat">
          <ExclusiveSelectionGroup
            values={contractTypesQuery?.data}
            name={name}
            canBeReset={false}
            control={control}
            getKey={({ key }) => key}
            getSelectedValue={value => {
              return contractTypesQuery?.data.find(({ id }) => id === value);
            }}
            getStringValue={({ label }) => label}
            getSelectedFormValue={element => element?.id}
          />
        </WithLightTitle>
      )}
      {contractTypesQuery.isLoading && (
        <ContentLoader height="5rem" width="100%" uniqueKey="contractTypeSelectionLoader">
          <rect x="0%" y="5" rx="4" ry="4" width="80%" height="48" />
        </ContentLoader>
      )}
    </div>
  );
};

export default ContractTypeSelection;
