import {
  Button,
  ComboBox,
  FetchButton,
  Modal,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusInCircle, Wrong } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import throttle from 'lodash.throttle';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { BDPCreationIllus } from 'src/Assets';
import { useCreatePositionBrief } from 'src/Hooks/PositionBriefs/useCreatePositionBrief';
import { useFetchQualifications } from 'src/Hooks/Qualifications';
import { toFetchStatus } from 'src/Services/Async';
import { getEditionRoute } from 'src/Services/Routing';

import styles from './BriefCreationModal.module.scss';
import { briefCreationSchema, Props } from './BriefCreationModal.types';

const BriefCreationModal = React.forwardRef<PopupActions, Props>(
  ({ companyName, companyId }, ref) => {
    const history = useHistory();
    const localRef = React.useRef<PopupActions>(null);
    const positionBriefCreationMutation = useCreatePositionBrief();
    const mutation = useFetchQualifications();
    const throttledMethod = useMemo(() => throttle(mutation.mutate, 500), [mutation.mutate]);
    const { control, handleSubmit, reset, setValue, watch } = useFormWithZodResolver({
      schema: briefCreationSchema,
      defaultValues: {
        company: { companyId, companyName },
        name: `nom d'une mission ${moment(new Date()).format('L')}`,
        qualification: { id: undefined, label: undefined },
      },
    });
    const companyController = useController({ control, name: 'company' });
    const briefName = watch('name');
    const qualificationController = useController({ control, name: 'qualification' });
    return (
      <Modal
        ref={ref ?? localRef}
        title="nouveau brief de poste"
        icon={<BDPCreationIllus className={styles.illus} />}
        onClose={() => reset()}
        onOpen={() => {
          setValue('company', { companyId: companyId ?? '', companyName: companyName ?? '' });
          setValue('name', `nom d'une mission ${moment(new Date()).format('L')}`);
          setValue('qualification', { id: undefined, label: undefined });
        }}
        trigger={
          <Button.Primary
            leftIcon={<PlusInCircle />}
            text="nouveau brief de poste"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}}
            disabled={companyName === undefined}
          />
        }
        footerActionsLeft={[
          <Button.Tertiary
            onClick={() => {
              reset({
                company: { companyId, companyName },
                name: `nom d'une mission ${moment(new Date()).format('L')}`,
              });
            }}
          >
            tout réinitialiser
          </Button.Tertiary>,
        ]}
        footerActionsRight={[
          <Button.Secondary
            onClick={() => {
              localRef.current?.close();
            }}
          >
            annuler
          </Button.Secondary>,
          <FetchButton
            disabled={
              briefName === undefined ||
              briefName.split('').filter((character: string) => character !== ' ').length === 0 ||
              briefName === '' ||
              qualificationController.field.value?.id === undefined
            }
            title="valider"
            onClick={handleSubmit(values => {
              positionBriefCreationMutation.mutate({
                name: values.name,
                companyId: values.company.companyId,
                qualificationId: values.qualification.id ?? '',
              });
            })}
            onSuccess={() =>
              history.push(
                getEditionRoute({
                  companyId: positionBriefCreationMutation.data?.companyId,
                  positionBriefId: positionBriefCreationMutation.data?.id?.toString(),
                  section: 'company/information',
                })
              )
            }
            fetchStatus={toFetchStatus(positionBriefCreationMutation)}
          />,
        ]}
      >
        <WithLightTitle title="nom du client concerné">
          {companyController.field.value?.companyName}
        </WithLightTitle>
        <WithLightTitle className={styles.beforeTitle} title="rechercher la qualification">
          <ComboBox
            id="qualifications"
            value={qualificationController.field.value}
            minLengthToSearch={0}
            keyValueExtractor={(searchResult: { id?: string; label?: string }) => ({
              key: searchResult.id ?? '',
              value: searchResult.label ?? '',
            })}
            onSearch={(word: string) => {
              throttledMethod(word);
            }}
            onChange={qualificationController.field.onChange}
            searchResults={mutation.data ?? []}
            placeholder="1 caractère minimum"
            fetchStatus={toFetchStatus(mutation)}
          />
        </WithLightTitle>
        <WithLightTitle title="libellé du poste chez le client" className={styles.beforeTitle}>
          <TextInput
            control={control}
            name="name"
            rightIcon={
              <Button.Tertiary onClick={() => setValue('name', '')} className={styles.crossButton}>
                <Wrong />
              </Button.Tertiary>
            }
          />
        </WithLightTitle>
      </Modal>
    );
  }
);

export default BriefCreationModal;
