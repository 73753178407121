import { UseMutationOptions, useMutation } from 'react-query';

import { PositionBrief, PositionBriefsService } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

export const useLinkPositionBriefToMissions = (
  id?: number,
  extraOptions?: UseMutationOptions<PositionBrief, unknown, { missionIds: string[] }>
) => {
  const user = useAuthenticatedUser();

  return useMutation(
    [MutationKeys.linkMissionsToBDP],
    async (params: { missionIds: string[] }) => {
      if (id === undefined) {
        return Promise.reject('This BDP does not have an id');
      }
      const updatedPositionBrief = await PositionBriefsService.positionBriefsControllerLinkPositionBriefAndMission(
        {
          id: id,
          body: {
            missionIds: params.missionIds,
            user,
          },
        }
      );

      return updatedPositionBrief;
    },
    { ...extraOptions }
  );
};
