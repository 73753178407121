import { useMutation, useQueryClient } from 'react-query';

import { RecruitmentStepsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useUpdateRecruitmentSteps = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateRecruitmentSteps],
    async (recruitmentSteps: { id: number; stepRank: number }[]) => {
      const rankedRecruitmentSteps = recruitmentSteps.map((mission, index) => {
        return { id: mission.id, stepRank: index };
      });
      const updatedRecruitmentSteps = await RecruitmentStepsService.recruitmentStepsControllerUpdateRecruitmentSteps(
        {
          body: rankedRecruitmentSteps,
        }
      );

      return updatedRecruitmentSteps;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchRecruitmentSteps');
      },
    }
  );
};
